import React, { Component, PropTypes } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DropdownList from '../common/DropDownList';
import MultiSelect from '../common/MultiSelect';
import MultiFiltersList from '../common/DropdownListSearchableMultiOptions';
import { Years, Months, Days, getDay, getMonth, getYear } from '../../utils/dateTimeHelpers';
import { toNorwegianDatetime, AddOnMonthToDatetime } from '../../utils/datepickerHelper';
import { validateInput } from '../../validations/RapportValidator';
import * as ExcelHandler from '../../utils/ExcelHandler';
import * as cookieHandler from '../../utils/cookieHandler';
import '../../css/rapport.css';
import _ from 'lodash';
import Spinner from 'react-spinkit';
import Checkbox from '../common/Checkbox';

class RapportModal extends Component {
    state = {
        showModal: false,
        selectedMonth: getMonth(),
        selectedYear: getYear(),
        selectedDay: getDay(),
        selectedSelskap: '0',
        selectedAvtale: '0',
        selectedKontrakt: '0',
        selectedProsesskoder: [],
        selectedMeldingstyper: [],
        inkluderForbrukUtenMeldingstype: true,
        showInkluderForbrukUtenMeldingstype: false,
        defualtDay: 1, //converting moment timezone to iso reducers day by one, so day become firstday.
        errors: {},
        generererRapport: false,
    };
    static propTypes = {
        createRapport: PropTypes.func.isRequired,
        buttonText: PropTypes.string.isRequired,
        headerText: PropTypes.string.isRequired,
        useOld: PropTypes.bool.isRequired,
        filename: PropTypes.string,
        fileFormat: PropTypes.string,
        kontraktId: PropTypes.string,
        selskapId: PropTypes.string,
        selskaper: PropTypes.array,
        kontrakter: PropTypes.array,
        avtaler: PropTypes.array,
        loadCompanySpecificContracts: PropTypes.func,
        prosesskoder: PropTypes.array,
        showModal: PropTypes.bool,
        showDays: PropTypes.bool,
        meldingstyper: PropTypes.array.isRequired,
        inkluderForbrukUtenMeldingstype: PropTypes.bool.isRequired,
        showInkluderForbrukUtenMeldingstype: PropTypes.bool.isRequired,
    };

    cleanUpState = () => {
        this.setState({
            selectedMonth: getMonth(),
            selectedYear: getYear(),
            selectedAvtale: '0',
            selectedSelskap: '0',
            selectedKontrakt: '0',
            errors: {},
            selectedProsesskoder: [],
            selectedMeldingstyper: [],
            showModal: false,
            generererRapport: false,
            inkluderForbrukUtenMeldingstype: true,
        });
    };
    openModal = () => {
        this.setState({ showModal: true });
        this.selectAllMeldingstyper();

        //Velg første kontrakt hvis kun én i lista
        if (this.props.kontrakter?.length === 1) {
            this.setState({
                selectedKontrakt: this.props.kontrakter[0].id,
            });
        }
    };
    closeModal = () => {
        this.setState({ showModal: false });
        this.cleanUpState();
    };
    removeError = (felt, value) => {
        const errors = this.state.errors;
        if (value && value !== '0') {
            delete errors[felt];
            this.setState({ errors });
        }
    };
    onSelectMonth = e => {
        const target = e.target;
        const value = target.value;
        this.setState({ selectedMonth: value });
        this.removeError('selectedMonth', value);
    };
    onSelectYear = e => {
        const target = e.target;
        const value = target.value;
        this.setState({ selectedYear: value });
        this.removeError('selectedYear', value);
    };
    onSelectSelskap = e => {
        const target = e.target;
        const value = target.value;
        this.setState({ selectedSelskap: value });
        if (value !== '0') this.props.loadCompanySpecificContracts(value);
        this.removeError('selectedSelskap', value);
    };
    onSelectKontrakt = e => {
        const target = e.target;
        const value = target.value;
        this.setState({ selectedKontrakt: value });
        this.removeError('selectedKontrakt', value);
    };
    onSelectAvtale = e => {
        const target = e.target;
        const value = target.value;
        this.setState({ selectedAvtale: value });
        this.removeError('selectedAvtale', value);
    };
    onSelectProsesscodes = e => {
        this.setState({ selectedProsesskoder: e });
        this.removeError('selectedProsesskoder', e);
    };
    onSelectMeldingstyper = (e, v, name) => {
        const { target } = e;
        const { checked } = target;
        let updateArray = [];
        if (checked) {
            updateArray = [...this.state[name], v.toString()];
        } else {
            updateArray = this.state[name].filter(val => val !== v.toString());
        }
        this.setState({ [name]: updateArray });
    };
    clearDropdownFilters = e => {
        this.setState({ selectedProsesskoder: [] });
        this.setState({ selectedMeldingstyper: [] });
    };
    selectAllMeldingstyper = e => {
        this.setState({
            selectedMeldingstyper: this.props.meldingstyper?.map(meldingstype => {
                return meldingstype.id;
            }),
        });
    };
    onChangeInkluderForbrukUtenMeldingstypeCheckbox = e => {
        const target = e.target;
        const checked = target.checked;
        if (checked) {
            this.setState({ inkluderForbrukUtenMeldingstype: true });
        } else {
            this.setState({ inkluderForbrukUtenMeldingstype: false });
        }
    };

    IsValid = () => {
        if (!this.props.selskaper || this.props.selskaper.length === 0) delete this.state.selectedSelskap;
        if (!this.props.kontrakter) delete this.state.selectedKontrakt;
        if (!this.props.avtaler) delete this.state.selectedAvtale;
        if (!this.props.prosesskoder) delete this.state.selectedProsesskoder;
        if (!this.props.meldingstyper) delete this.state.selectedMeldingstyper;
        const { isValid, errors } = validateInput(this.state);

        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    };

    generateDateFra = state => {
        const year = parseInt(state.selectedYear, 10);
        const month = parseInt(state.selectedMonth, 10) - 1;
        const day = parseInt(state.defualtDay, 10);
        return toNorwegianDatetime(year, month, day);
    };
    generateDateTo = state => {
        const year = parseInt(state.selectedYear, 10);
        const month = parseInt(state.selectedMonth, 10) - 1;
        const day = parseInt(state.defualtDay, 10);
        const nextMonth = AddOnMonthToDatetime(year, month, day);
        return nextMonth;
    };
    generateProsesskoder = () => {
        const prosesskoder = _.map(this.state.selectedProsesskoder, i => {
            return i.id;
        });

        return prosesskoder;
    };
    onsubimtRapport = () => {
        if (this.IsValid()) {
            const selectedMonth = this.state.selectedMonth;
            const selectedYear = this.state.selectedYear;
            const avtaleId = this.state.selectedAvtale;
            const selectedMeldingstyper = this.state.selectedMeldingstyper;
            const fromDate = this.generateDateFra(this.state);
            const toDate = this.generateDateTo(this.state);
            const payload = Object.assign(
                {},
                {
                    FraDato: fromDate.toISOString(),
                    TilDato: toDate.toISOString(),
                    month: selectedMonth,
                    year: selectedYear,
                    avtaleId: avtaleId,
                    meldingstyper: selectedMeldingstyper,
                }
            );

            this.generateRapport(payload);
        }
    };
    generateRapport = payload => {
        const { fileFormat, createRapport, selskapId, filename, useOld } = this.props;
        const {
            selectedKontrakt,
            selectedProsesskoder,
            selectedAvtale,
            selectedMeldingstyper,
            inkluderForbrukUtenMeldingstype,
        } = this.state;
        const extension = fileFormat ? fileFormat : 'csv';
        if (selectedKontrakt !== '0') {
            payload['kontraktId'] = selectedKontrakt;
        }

        if (selskapId) payload['selskapId'] = selskapId;

        if (selectedAvtale !== '0') {
            payload['avtaleId'] = selectedAvtale;
        }

        if (selectedProsesskoder && selectedProsesskoder.length > 0) {
            payload['prosesskode'] = this.generateProsesskoder();
        }

        if (selectedMeldingstyper && selectedMeldingstyper.length > 0) {
            payload['meldingstyper'] = selectedMeldingstyper;
        }

        payload['inkluderForbrukUtenMeldingstype'] = inkluderForbrukUtenMeldingstype;

        const filnavnForRapport = ExcelHandler.createFileName(filename, payload.month, payload.year, extension);
        this.setState({ generererRapport: true });
        createRapport(payload, useOld).then(res => {
            const motattFilnavn = ExcelHandler.filnavnFraResponse(res);
            extension === 'csv'
                ? ExcelHandler.downloadCSVRapport(res, filnavnForRapport)
                : ExcelHandler.downloadExcelRapport(res, motattFilnavn ? motattFilnavn : filnavnForRapport);
            if (res) this.cleanUpState();
            else this.setState({ generererRapport: false });
        });
    };
    render() {
        const {
            showModal,
            errors,
            selectedMonth,
            selectedYear,
            selectedDay,
            selectedSelskap,
            selectedKontrakt,
            selectedAvtale,
            selectedProsesskoder,
            generererRapport,
            selectedMeldingstyper,
        } = this.state;
        const {
            buttonText,
            headerText,
            selskaper,
            kontrakter,
            avtaler,
            prosesskoder,
            showDays,
            meldingstyper,
            showInkluderForbrukUtenMeldingstype,
        } = this.props;

        const monthList = Months();
        const yearsList = Years();
        const days = Days(selectedYear, selectedMonth);

        return (
            <div>
                {
                    <button className="btn btn-primary" id="rapport-modal-button" onClick={this.openModal}>
                        {buttonText}
                    </button>
                }

                <Modal show={showModal && generererRapport} onHide={this.closeModal}>
                    <Modal.Body>
                        <Modal.Title> {'Lager rapport...'} </Modal.Title>
                        <center>
                            <Spinner spinnerName="circle" noFadeIn={true} style={{ width: '75px', height: '75px' }} />
                        </center>
                    </Modal.Body>
                </Modal>

                <Modal show={showModal && !generererRapport} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title> {headerText} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="rapportForm">
                            <div className="form-group">
                                <span>Velg hvilken måned og år du skal lage rapport for</span>
                            </div>

                            <div className="col-xs-3">
                                <DropdownList
                                    label="År"
                                    Items={yearsList}
                                    selected={selectedYear}
                                    title="Velg år"
                                    name="selectedYear"
                                    onChange={this.onSelectYear}
                                    id="Dropdownlist"
                                    error={errors.selectedYear}
                                />
                            </div>
                            <div className="col-xs-3">
                                <DropdownList
                                    label="Måned"
                                    Items={monthList}
                                    selected={selectedMonth}
                                    title="Velg måned"
                                    name="selectedMonth"
                                    onChange={this.onSelectMonth}
                                    id="Dropdownlist"
                                    error={errors.selectedMonth}
                                />
                            </div>
                            {showDays && (
                                <div className="col-xs-3">
                                    <DropdownList
                                        label="Day"
                                        Items={days}
                                        selected={selectedDay}
                                        title="Velg day"
                                        name="selectedDay"
                                        onChange={this.onSelectDay}
                                        id="Dropdownlist"
                                        error={errors.selectedDay}
                                    />
                                </div>
                            )}
                            <div className="clearfix"></div>
                            {avtaler && avtaler.length > 0 && (
                                <div className="col-xs-5">
                                    <DropdownList
                                        label="Avtale"
                                        Items={avtaler}
                                        selected={selectedAvtale}
                                        title="Velg avtale"
                                        name="selectedAvtale"
                                        onChange={this.onSelectAvtale}
                                        id="Dropdownlist avtaler"
                                        error={errors.selectedAvtale}
                                    />
                                </div>
                            )}
                            {cookieHandler.UserIsBymAdmin() && selskaper && selskaper.length > 0 && (
                                <div className="col-xs-5">
                                    <DropdownList
                                        label="Entreprenører"
                                        Items={selskaper}
                                        selected={selectedSelskap}
                                        title="Velg entreprenør"
                                        name="selectedSelskap"
                                        onChange={this.onSelectSelskap}
                                        id="Dropdownlist entrepreneur"
                                        error={errors.selectedSelskap}
                                    />
                                </div>
                            )}
                            {kontrakter && kontrakter.length > 0 && (
                                <div className="col-xs-11">
                                    <DropdownList
                                        label="Kontrakter"
                                        Items={kontrakter}
                                        selected={selectedKontrakt}
                                        title="Velg kontrakt"
                                        name="selectedKontrakt"
                                        onChange={this.onSelectKontrakt}
                                        id="Dropdownlist kontrakter"
                                        error={errors.selectedKontrakt}
                                    />
                                </div>
                            )}
                            {prosesskoder && prosesskoder.length > 0 && (
                                <div className="col-xs-5">
                                    <MultiSelect
                                        label="Prosesskoder"
                                        title="Velg prosesskode"
                                        Items={prosesskoder}
                                        selectedValues={selectedProsesskoder}
                                        name="selectedProsesskoder"
                                        onChange={this.onSelectProsesscodes}
                                        id="Dropdownlist prosesskoder"
                                        error={errors.selectedProsesskoder}
                                    />
                                </div>
                            )}
                            <div className="clearfix"></div>
                            {meldingstyper && meldingstyper.length > 0 && (
                                <div className="col-xs-11">
                                    <MultiFiltersList
                                        label="Meldingstyper"
                                        shortTitle=" "
                                        title="Velg meldingstype"
                                        Items={meldingstyper}
                                        selectedItems={selectedMeldingstyper}
                                        name="selectedMeldingstyper"
                                        onChange={this.onSelectMeldingstyper}
                                        id="Dropdownlist meldingstyper"
                                        error={errors.selectedMeldingstyper}
                                        clearDropdownFilters={this.clearDropdownFilters}
                                        selectAll={this.selectAllMeldingstyper}
                                    />
                                </div>
                            )}
                            {showInkluderForbrukUtenMeldingstype && <div className="clearfix"></div>}
                            {showInkluderForbrukUtenMeldingstype && (
                                <div className="col-xs-11">
                                    <Checkbox
                                        className="col-xs-11"
                                        checked={this.state.inkluderForbrukUtenMeldingstype}
                                        title="Inkluder globalt ressursforbruk"
                                        onChange={this.onChangeInkluderForbrukUtenMeldingstypeCheckbox}
                                    />
                                </div>
                            )}
                            <div className="clearfix"></div>
                            <br />
                            <br />
                        </div>

                        <div>
                            <Modal.Footer>
                                <Button className="pull-left" bsStyle="link" onClick={this.closeModal} id="buttonLink">
                                    Avbryt
                                </Button>
                                <Button
                                    className="pull-left"
                                    bsStyle="primary"
                                    bsSize="large"
                                    onClick={this.onsubimtRapport}
                                >
                                    Lag rapport
                                </Button>
                            </Modal.Footer>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export default RapportModal;
