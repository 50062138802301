import { PropTypes } from 'react';
import { connect } from 'react-redux';
import {
    createServiceavdelingBruker, createUserSuccess, createUserFailure,
    createUser, createUserReset, createEntreprenorBruker
} from '../../actions/userActions';
import { addFlashMessage } from '../../actions/FlashMessagesAction';
import * as messagesToUser from '../../constants/ResponseMessages';
import NewUserForm from '../../components/user/create/NewUserForm';
import { selskapsTypeEntreprenorerId } from '../../constants/Globals'



const MapStateToProps = (state, ownProps) => {
    return {
        loading: state.users.newUser.loading,
        error: state.users.newUser.error,
        avtaler: ownProps.selskapsType === selskapsTypeEntreprenorerId ? ownProps.avtaler : []
    }
};

function handleCreateUserError(error) {
    if (error.response) {
        if (error.response.status === 404)
            return messagesToUser.CreateUserNotAvailable;

        if (error.response.status === 500)
            return messagesToUser.BymeldingServerError;

        if (error.response.data && error.response.data.errorMessage) {
            return error.response.data.errorMessage;
        }
    }

    return error.message;
}

function MapDispatchToProps(dispatch, ownProps) {
    const whenDone = () => {
        dispatch(createUserReset());
        ownProps.whenDone();
    };

    return {
        onSubmit: (user) => {
            if (user === null) { //canceled by user
                whenDone();
                return;
            }

            dispatch(createUser(user));
            const promise = ownProps.selskapsType === selskapsTypeEntreprenorerId ? 
                createEntreprenorBruker(ownProps.selskapId, user) : 
                createServiceavdelingBruker(ownProps.selskapId, user);

            promise.then((response) => {
                dispatch(addFlashMessage({ type: "success", text: "La til bruker med epost " + user.epost }));
                whenDone();
                dispatch(createUserSuccess({ ...user, id: response.data.result.id }));
            }).catch(error => {
                const message = handleCreateUserError(error);
                dispatch(createUserFailure(message));
            });
        },

    };
}

const NewUserFormContainer = connect(MapStateToProps, MapDispatchToProps)(NewUserForm);

NewUserFormContainer.propTypes = {
    selskapId: PropTypes.string.isRequired,
    selskapsType: PropTypes.number.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    whenDone: PropTypes.func,
    avtaler:PropTypes.array,
};

export default NewUserFormContainer;