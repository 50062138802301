import * as types from './ActionTypes';
import userApi from '../Api_MockData/mockUsersApi';
import axios from 'axios';
import * as api from '../constants/api';
import { useMock } from "../constants/settings";
import * as messages from '../constants/ResponseMessages';
import * as globals from "../constants/Globals"
import { addFlashMessage } from "./FlashMessagesAction"
import { UserIsRemoved } from "../constants/ResponseMessages"

export function loadUsersStart() {
    return { type: types.LOAD_USERS };
}
export function loadUsersSuccess(users) {
    return { type: types.LOAD_USERS_SUCCESS, users };
}
export function loadUsersFailure(error) {
    return { type: types.LOAD_USERS_FAILURE, error };
}

export function createUserSuccess(user) {
    return { type: types.CREATE_USER_SUCCESS, user };
}

export function createUser(user) {
    return { type: types.CREATE_USER, user };
}

export function createUserFailure(error) {
    return { type: types.CREATE_USER_FAILURE, error }
}

export function createUserReset() {
    return { type: types.CREATE_USER_RESET }
}

export function updateUserSuccess(user) {
    return { type: types.UPDATE_USER_SUCCESS, user };
}

export function deleteUserSuccess(user) {
    return { type: types.DELETE_USER_SUCCESS, user };
}

export function deleteUserFailure(user) {
    return { type: types.DELETE_USER_FAILURE, user };
}

function handleLoadUsersError(error) {
    if (error.response) {
        if (error.response.status === 404)
            return messages.UsersNotAvailable;

        if (error.response.status === 500)
            return messages.BymeldingServerError;
    }

    return error.message;
}

export function loadUsers(selskapsType, selskapsId) {
    const url = (selskapsType === globals.selskapsTypeEntreprenorerId) ?
        api.entreprenorBrukere(selskapsId) :
        api.selskapsBrukere

    return function (dispatch) {
        dispatch(loadUsersStart());
        if (useMock === false) {
            //const url = api.selskapsBrukere;
            axios.get(url)
                .then((response) => {
                    dispatch(loadUsersSuccess(response.data.result));
                }
                ).catch((error) => {
                    dispatch(loadUsersFailure(handleLoadUsersError(error)));
                });
        }
        else {
            return userApi.getAllUsers().then(users => {
                dispatch(loadUsersSuccess(users));
            }).catch(error => {
                throw (error);
            });
        }
    }
        ;
}


export function createServiceavdelingBruker(selskapsId, user) {
    const newuser = { selskapsRolleId: user.selskapsRolle.rolleId, epost: user.epost, navn: user.navn }
    return axios.post(api.selskapsBrukere, newuser);
}

export function createEntreprenorBruker(selskapsId, user) {
    const contractZone = user.avtaler.map(sone => { return { avtaleId: sone.id } })
    const newuser = { selskapsRolleId: user.selskapsRolle.rolleId, epost: user.epost, navn: user.navn, avtaler: contractZone }
    return axios.post(api.entreprenorBrukere(selskapsId), newuser);
}

// export function updateUserRequest( user) {
//     return axios.put(api.selskapsBrukere + '/' + user.id, {selskapsRolleId: user.selskapsRolle.rolleId})
// }

export function updateUserRequest(selskapsType, selskapsId, user) {
    if (selskapsType === globals.selskapsTypeEntreprenorerId) {
        const contractZone = user.avtaler.map(sone => { return { avtaleId: sone.id } })
        return axios.put(api.entreprenorBrukere(selskapsId) + "/" + user.id, { selskapsRolleId: user.selskapsRolle.rolleId, avtaler: contractZone })
    }
    else {
        let url = api.selskapsBrukere + "/" + user.id
        return axios.put(url, { selskapsRolleId: user.selskapsRolle.rolleId })
    }
}

export function saveUser(selskapsType, selskapsId, user) {
    return function (dispatch) {
        if (user && user.id && user.id !== "") {
            updateUserRequest(selskapsType, selskapsId, user)
                .then(() => dispatch(updateUserSuccess(user)));
        }
        else {
            createServiceavdelingBruker(selskapsId, user, dispatch)
                .then(() => dispatch(createUser(user)));
        }
    }
}


export function removeUser(selskapsType, selskapsId, user, onSuccess, onError) {
    const url = selskapsType === globals.selskapsTypeEntreprenorerId ?
        api.entreprenorBrukere(selskapsId) :
        api.selskapsBrukere;


    return function (dispatch) {
        if (!useMock) {
            axios.delete(url + "/" + user.id)
                .then(function (response) {
                    if (response.data) {
                        dispatch(deleteUserSuccess(user))
                        dispatch(addFlashMessage({ type: 'success', text: UserIsRemoved }));
                    }

                })
                .catch((error) => {
                    dispatch(deleteUserFailure(error))
                })
        } else {
            return userApi.deleteUser(user).then(() => {
                dispatch(deleteUserSuccess(user))
                dispatch(addFlashMessage({ type: 'success', text: UserIsRemoved }));
            });
        }
    }
}

  /*  return function (dispatch) {
        if (!useMock) {
            axios.delete(url + "/" + user.id)
                .then(() => {
                    dispatch(deleteUserSuccess(user))
                })
                .catch((error) => {
                    dispatch(deleteUserFailure(error))
                })
        } else {
            return userApi.deleteUser(user).then(() => {
                dispatch(deleteUserSuccess(user))
            });
        }
    }
}
*/
