import React, { PropTypes } from 'react';
import classnames from "classnames";

const TextFieldGroupDisabled = ({ name, value, valueFront, label, id }) =>
{
    return (
        <div className={classnames('form-group', { 'has-error': "" })}>
            <label className="control-label" htmlFor={name}>{label}</label>
            <div className={valueFront? "input-group":"control-label"}>
                <label htmlFor={value}  name={name} id={id}>{value} </label>
                {valueFront && <div className="input-group-btn"><button id="valueFront" className="btn btn-link">{valueFront}</button></div>}
            </div>
        </div>
    );
}

TextFieldGroupDisabled.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string.isRequired,
    valueFront: PropTypes.string,
    label: PropTypes.string,
}

export default TextFieldGroupDisabled;