import React, { PropTypes } from 'react';
import { Table, Button } from 'react-bootstrap';
import Loading from '../common/Loading';
import UserNoEditRow from './UserNoEditRow';
import EditableUserRow from './edit/EditableUserRow';
import * as messageToUser from '../../constants/ResponseMessages';
import { getRollerForSelskapsType } from '../../utils/globalsHelper';

import '../../css/user/UserList.css';
import { sortBy } from 'lodash';

class UserList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.onOpen = this.onOpen.bind(this);
    this.renderUserTableBody = this.renderUserTableBody.bind(this);
    this.setActiveFilter = this.setActiveFilter.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    UserList.renderLoadingSpinner = UserList.renderLoadingSpinner.bind(this);
    UserList.renderNoUsersMessage = UserList.renderNoUsersMessage.bind(this);
    UserList.renderErrorMessage = UserList.renderErrorMessage.bind(this);

    this.state = {
      idOfOpenedRow: null,
      activeFilter: 'Alle',
    };
  }

  componentWillMount() {
    // this.props.loadAvtaler();
    this.props.loadUsers();
  }

  // componentWillReceiveProps(nextProps) {
  //     if (nextProps.error && nextProps.error !== this.props.error) {
  //         this.props.addFlashMessage({type: "error", text: nextProps.error});
  //     }
  // }

  onOpen(id) {
    this.setState({ idOfOpenedRow: id });
  }

  getRoller() {
    return getRollerForSelskapsType(this.props.selskapsType);
  }

  setActiveFilter(filternavn) {
    this.setState({ activeFilter: filternavn });
  }

  renderFilters() {
    return (
      <div className="filters">
        <Button
          onClick={() => this.setActiveFilter('Alle')}
          bsStyle="link"
          className={this.state.activeFilter === 'Alle' ? 'active' : ''}
        >
          Alle områder
        </Button>
        {this.props.avtaler.map(sone => {
          return (
            <Button
              key={sone.id}
              onClick={() => this.setActiveFilter(sone.navn)}
              className={this.state.activeFilter === sone.navn ? 'active' : ''}
              bsStyle="link"
            >
              {sone.navn}
            </Button>
          );
        })}
      </div>
    );
  }

  renderUserTableBody() {
    const {
 users, editable, onDelete, onEdit, selskapsType 
} = this.props;
    const filteredUsers =
      this.state.activeFilter === 'Alle'
        ? users
        : this.props.users.filter(user =>
            user.avtaler.some(a => a.navn === this.state.activeFilter));
    const { avtaler } = this.props;
    return sortBy(filteredUsers, [u => u.navn.toLowerCase().trim()]).map(
      user => {
        if (editable) {
          return (
            <EditableUserRow
              key={user.id}
              avtaler={avtaler}
              user={user}
              roles={this.getRoller()}
              onDelete={onDelete}
              onOpen={this.onOpen}
              onSave={onEdit}
              isOpen={user.id === this.state.idOfOpenedRow}
              selskapsType={selskapsType}
            />
          );
        }

        return (
          <UserNoEditRow
            key={user.id}
            epost={user.epost}
            onDelete={onDelete}
            roles={this.getRoller()}
          />
        );
      }
    );
  }

  static renderLoadingSpinner() {
    return (
      <div className="center">
        <Loading />
      </div>
    );
  }

  static renderNoUsersMessage() {
    return (
      <div className="">
        <p>{messageToUser.NoUsersMessage}</p>
      </div>
    );
  }

  static renderErrorMessage() {
    return (
      <div className="error">
        <p>{messageToUser.LoadUsersError}</p>
      </div>
    );
  }

  render() {
    const { users, loading, error } = this.props;
    const gotUsers = users && users.length > 0;
    const showError = !loading && error;
    const showNoUsersMessage = !loading && !error && !gotUsers;
    // const hasavtaler = gotUsers && users.some(user => user.avtaler && user.avtaler.length > 0);

    return (
      <div className="userList">
        {/* {hasavtaler && this.renderFilters()} */}
        <Table>
          <thead>
            <tr>
              <th>Navn</th>
              <th>Rolle</th>
              <th>E-post</th>
              <th />
            </tr>
          </thead>
          <tbody>{gotUsers && this.renderUserTableBody()}</tbody>
        </Table>
        {showError && UserList.renderErrorMessage()}
        {loading && UserList.renderLoadingSpinner()}
        {showNoUsersMessage && UserList.renderNoUsersMessage()}
      </div>
    );
  }
}

UserList.defaultProps = {
  editable: false,
};

UserList.propTypes = {
  loadUsers: PropTypes.func.isRequired,
  selskapsId: PropTypes.string,
  selskapsType: PropTypes.number,
  users: PropTypes.array.isRequired,
  avtaler: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  editable: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  addFlashMessage: PropTypes.func,
};

export default UserList;
