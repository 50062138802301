import cookie from 'react-cookie';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import map from 'lodash/map';
import union from 'lodash/union';
import includes from 'lodash/includes';
import * as globals from '../constants/Globals';

const bymcookie = 'bymtoken';
const prosessrolleId = 'ProsessrolleId';
const prosessrolleRolleId = 'ProsessrolleRolleId';

export function loadBymCookie() {
  return cookie.load(bymcookie);
}

export function saveBymCookie(tk) {
  const expiration = new Date(tk.exp * 1000);
  cookie.save(bymcookie, tk.access_token, { path: '/', expires: expiration });
}
export function removeBymCookie() {
  if (bymCookieExists()) {
    cookie.remove(bymcookie, { path: '/' });
  }
}

export function decodeToken() {
  const token = loadBymCookie();
  if (token != null) {
    return jwtDecode(token);
  }
}
export function Username() {
  const token = decodeToken();
  if (token) {
    return token['sub'];
  }
  return '';
}
export function UserId() {
  const token = decodeToken();
  if (token) {
    return token['brukerId'];
  }
  return '';
}
export function RoleId() {
  const token = decodeToken();
  if (token) {
    return token.ProsessrolleId;
  }
  return '';
}
export function UsersRoleAndProcessroleRoles() {
  const token = decodeToken();
  if (token) {
    const role = token.ProsessrolleId;
    const processroleRoles = token.ProsessrolleRolleId;
    if (role || processroleRoles) {
      const arr = union([role], processroleRoles);
      return arr;
    }
  }
  return [];
}

export function CheckValueExistsInToken(value) {
  const token = decodeToken();
  if (token) {
    const pr_id_exists = token[prosessrolleId]
      ? token[prosessrolleId] === value
      : false;
    const prr = token[prosessrolleRolleId];
    const prr_id_exists = includes(prr, value);
    return pr_id_exists || prr_id_exists;
  }
  return false;
}

export function CheckVRoleInToken(value) {
  const token = decodeToken();
  if (token) {
    const pr_id_exists = token[prosessrolleId]
      ? token[prosessrolleId] === value
      : false;
    return pr_id_exists;
  }
  return false;
}

export function UserHasEnkAccess() {
  const enkAdmin = CheckValueExistsInToken(globals.EntreprenorkontrollAdmin);
  const enkEntreprenor = CheckValueExistsInToken(globals.EnkEntreprenor);
  const enkForvalter = CheckValueExistsInToken(globals.EnkForvalter);
  const enkKontrollor = CheckValueExistsInToken(globals.EnkKontrollor);
  return enkAdmin || enkEntreprenor || enkForvalter || enkKontrollor;
}

export function UserIsBymAnsatt() {
  const enkAdmin = CheckValueExistsInToken(globals.EntreprenorkontrollAdmin);
  const enkForvalter = CheckValueExistsInToken(globals.EnkForvalter);
  const enkKontrollor = CheckValueExistsInToken(globals.EnkKontrollor);

  const bymAdmin = CheckValueExistsInToken(globals.bymeldingAdmin);
  const serviceAvdAdmin = CheckValueExistsInToken(globals.serviceAvdelingAdmin);
  const serviceAvd = CheckValueExistsInToken(globals.serviceavdeling);

  return (
    enkAdmin ||
    enkForvalter ||
    enkKontrollor ||
    bymAdmin ||
    serviceAvdAdmin ||
    serviceAvd
  );
}

export function UserIsBymAdmin() {
  const exists = CheckValueExistsInToken(globals.bymeldingAdmin);
  return exists;
}
export function UserIsEntrepreneurAdmin() {
  const exists = CheckValueExistsInToken(globals.entrepreneurAdmin);
  return exists;
}
export function UserIsServicedeskAdmin() {
  const exists = CheckValueExistsInToken(globals.serviceAvdelingAdmin);
  return exists;
}

export function UserIsServicedesk() {
  const exists = CheckValueExistsInToken(globals.serviceavdeling);
  return exists;
}
export function UserIsEntrepreneur() {
  const exists = CheckValueExistsInToken(globals.entrepreneurs);
  return exists;
}

export function UserIsPublikum() {
  const exists = CheckVRoleInToken(globals.publikum);
  return exists;
}

export function AdminForWhichApplication() {
  const decodedToken = decodeToken();
  if (decodedToken) {
    const app = findKeyInObj(decodedToken, '1');
    return app;
  }
}

export function findKeyInObj(obj, val) {
  let res = '';
  map(obj, function(k, v) {
    if (k === val) {
      res = v;
    }
  });
  return res;
}
export function BearerBymToken() {
  const token = loadBymCookie();
  return `Bearer ${token}`;
}

export function bymCookieExists() {
  const token = loadBymCookie();
  return token != null ? true : false;
}

export function validateToken() {
  if (bymCookieExists()) {
    const token = decodeToken();
    const currentTime = Date.now();
    const notBeforeTime = new Date(token.nbf * 1000);
    const notAfterTime = new Date(token.exp * 1000);
    const expired = notBeforeTime > currentTime || currentTime > notAfterTime;
    if (expired) {
      console.log('Token expired');
      return false;
    }
    return true;
  }
  return false;
}

export function SetAuthorizationToken() {
  if (bymCookieExists()) {
    const bearer = BearerBymToken();
    axios.defaults.headers.common['Authorization'] = bearer;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}
