import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadProcesscodeschemes } from '../../actions/processcodeschemeActions';
import ProcessCodeSchemeListRow from './ProcessCodeSchemeListRow';
import { Panel, Row, Col, Button, Glyphicon} from 'react-bootstrap';
import NewProcessCodeSchemeModalContainer from './NewProcessCodeSchemeModalContainer';
import * as responseMessages from "../../constants/ResponseMessages"
import '../../css/Entrepreneur.css';
import _ from "lodash"


class ProcessCodeSchemesPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    this.props.loadProcesscodeschemes();
    }

  processcodeRow(processcode, index) {
    return <div key={index}>{processcode.name}</div>;
  }

 handleClick() {
  this.newprocesscodeschememodel.getWrappedInstance().openModal();
}



  render() {
    let prosesskoder=this.props.processcodeschemes
    let size=_.size(prosesskoder)
     return (
      <div className="entrepreneur_center_div">
      <Panel>
        <div>
          <Button onClick={this.handleClick} className="pull-right">
              <Glyphicon glyph="plus" />
              Opprett prosesskodeskjema
          </Button>
        </div>
        <Row>
          <Col md={12}>
            <span aria-hidden="true" /> &nbsp;
          </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th>Prosesskodeskjema</th>
            </tr>
          </thead>
          <tbody>
            {size>0 && _.map(prosesskoder,(processcodescheme, index) =>
              <ProcessCodeSchemeListRow key={index} processcodescheme={processcodescheme} />
            )}
            {
              size===0 && <tr><td>{responseMessages.NoProcessSchema}</td></tr>
            }
          </tbody>
        </table>
        </Panel>
        <NewProcessCodeSchemeModalContainer initialChecked={false} callbackParent={()=>{}} ref={m => this.newprocesscodeschememodel = m} />
      </div>
    );
  }
}

ProcessCodeSchemesPage.defaultProps = {
  processcodeschemes: []
}

ProcessCodeSchemesPage.propTypes = {
  processcodeschemes: PropTypes.array.isRequired,
  loadProcesscodeschemes: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    processcodeschemes: state.processcodeschemes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadProcesscodeschemes: bindActionCreators(loadProcesscodeschemes, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessCodeSchemesPage);

