import _ from 'lodash'
import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserListContainer from '../User/UserListContainer'
import NewUserModal from '../../components/user/create/NewUserModal'
import { getSelskapsIdForLoggedUser } from "../../actions/MeldingerActions"
import { selskapsTypeEntreprenorerId } from '../../constants/Globals'
import { RoleId } from '../../utils/cookieHandler'
import { getSelskapsTypeFromRolle } from '../../utils/globalsHelper'
import { loadAvtaler, loadAvtalerForEntrepreneurAdmin } from '../../actions/avtalerAction'
import { loadSaksbehandlere } from "../../actions/selskaperActions"
import '../../css/Saksbehandler.css'

export class SaksbehandlerContainer extends React.Component
{

    componentDidMount()
    {
        this.loadAvtalerForLoggedUser()
    }
    loadAvtalerForLoggedUser()
    {
        if (this.props.selskapsType === selskapsTypeEntreprenorerId)
        {
            this.props.getSelskapsIdForLoggedUser().then(selskapId =>
            {
                this.props.loadAvtalerForEntrepreneurAdmin(selskapId)
            })
        } else
        {
            // saksbehandler is loaded in index.js
            if (!this.props.saksbehandler)
            {
                this.props.loadSaksbehandlere()
            }
            this.props.loadAvtaler();
        }
    }

    filterAvtalerForSelskap(selskapsType, selskapsId, avtaler)
    {
        if (selskapsType === selskapsTypeEntreprenorerId)
        {
            return _.sortBy(_.map(_.filter(avtaler, { 'selskapId': selskapsId }), i =>
            {
                return Object.assign({}, { id: i.avtaleId, navn: i.navn, beskrivelse: i.beskrivelse })
            }), 'navn')
        }

        return [];
    }

    render()
    {
        const { selskapsType, entreprenørSelskapsId, saksbehandler, avtaler } = this.props;
        if (
            (selskapsType === selskapsTypeEntreprenorerId && !entreprenørSelskapsId) ||
            (selskapsType !== selskapsTypeEntreprenorerId && !saksbehandler))
        {
            return null
        }
        const avtalerList = this.filterAvtalerForSelskap(selskapsType, entreprenørSelskapsId, avtaler)
        const selskapsId = selskapsType === selskapsTypeEntreprenorerId ? entreprenørSelskapsId : saksbehandler.id;

        return (
            <div className="mainContainer">
                <div className="serviceDiv col-xs-12">
                    <NewUserModal
                        confirmText="Opprett ny bruker"
                        buttonText="Opprett bruker"
                        modalTitle="Opprett ny bruker"
                        selskapsType={selskapsType}
                        selskapId={selskapsId}
                        avtaler={avtalerList}
                    />
                    <UserListContainer editable={true} selskapsType={selskapsType} selskapsId={selskapsId} avtaler={avtalerList} />
                </div>
            </div>
        );
    }
}

SaksbehandlerContainer.propTypes = {
    saksbehandler: PropTypes.object,
    entreprenørSelskapsId: PropTypes.string,
    getSelskapsIdForLoggedUser: PropTypes.func.isRequired,
    avtaler: PropTypes.array,
    loadAvtaler: PropTypes.func,
    selskapsType: PropTypes.number.isRequired,
    loadSaksbehandlere: PropTypes.func.isRequired,
    loadAvtalerForEntrepreneurAdmin: PropTypes.func.isRequired,
};

function mapStateToProps(state)
{
    return {
        saksbehandler: state.saksbehandlere.length > 0 ? state.saksbehandlere[0] : null,
        entreprenørSelskapsId: state.meldinger.selskapId,
        avtaler: state.contractZones,
        selskapsType: getSelskapsTypeFromRolle(RoleId()),
    };
}
function mapDispatchToProps(dispatch)
{
    return bindActionCreators({ getSelskapsIdForLoggedUser, loadAvtaler, loadSaksbehandlere, loadAvtalerForEntrepreneurAdmin }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(SaksbehandlerContainer);