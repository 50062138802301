import React, { PropTypes } from 'react'
import { Button } from 'react-bootstrap'
import CollapsibleUser from './CollapsibleUser'
import Confirm from "react-confirm-bootstrap"
import * as ResponseMessages from "../../../constants/ResponseMessages"

const EditableUserRow = ({ user, onDelete, isOpen, onOpen, onSave, roles, avtaler, selskapsType }) =>
{
    const deleteUser = () =>
    {
        onDelete(user)
    };

    return (
        <tr key={user.id}>
            <td>
                <CollapsibleUser user={user} roles={roles} open={isOpen} onOpen={onOpen} onSave={onSave}
                    avtaler={avtaler}
                    selskapsType={selskapsType} />
            </td>
            <td>{!isOpen && user.selskapsRolle.navn}</td>
            <td>
                {user.epost}
            </td>
            <td>
                <Confirm
                    confirmBSStyle="primary"
                    cancelText="Avbryt"
                    onConfirm={deleteUser}
                    body={`${ResponseMessages.serviceavdelingRemoveUserQuestionText}"${user.navn}" ?`}
                    confirmText="Fjern"
                    title={ResponseMessages.serviceavdelingRemoveUser}>
                    <Button className="danger pull-right" bsStyle="link">Fjern bruker</Button>
                </Confirm>
            </td>
        </tr>
    );
}

EditableUserRow.defaultTypes = {
    isOpen: false
}

EditableUserRow.propTypes = {
    user: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    avtaler: PropTypes.array,
    onDelete: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    selskapsType: PropTypes.number.isRequired
};

export default EditableUserRow;