/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as cookieHandler from '../../utils/cookieHandler';
import { signOutAndRemoveCookie } from '../../actions/LoginActions';

class Logout extends React.Component {
    componentDidMount() {
        const bymcookie = cookieHandler.loadBymCookie();
        this.props.signOutAndRemoveCookie(bymcookie);
        browserHistory.push('/');
    }

    render() {
        return <div />;
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ signOutAndRemoveCookie }, dispatch);
};

Logout.propTypes = {
    signOutAndRemoveCookie: PropTypes.func.isRequired,
};

export default connect(
    null,
    mapDispatchToProps
)(Logout);
