import React, { Component, PropTypes } from 'react';
import MeldingerFilter from '../../components/Meldinger/MeldingerFilter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import MeldingList from '../../components/Meldinger/MeldingList';
import MeldingerPageHeader from '../../components/Meldinger/MeldingerPageHeader';
import LeafletmapWithFilters from '../../components/Kart/LeafletMapWithFilters';
import Pagination from '../../components/common/Pagination';
import queryString from 'query-string';
import dateFormat from 'dateformat';
import * as routes from '../../constants/clientRoutes';
import * as cookieHandler from '../../utils/cookieHandler';
import {
    loadEntrepreneurMeldinger,
    loadEntrepreneurs,
    loadMeldingstyper,
    loadMeldingsstatuser,
    loadkatagoriGroups,
    getSelskapsIdForLoggedUser,
    PostNewMelding,
} from '../../actions/MeldingerActions';
import { loadAvtalerForEntrepreneur, loadAvtalerForEntrepreneurAdmin } from '../../actions/avtalerAction';
import { loadDelbydeler } from '../../actions/delbydelerActions';
import {
    GetSavedMeldingSearchByBrukerId,
    AddToSavedMeldingSearch,
    DeleteFromSavedMeldingSearch,
} from '../../actions/SavedMeldingSearchAction';
import '../../css/Meldinger/Meldinger.css';
import { genererPdf } from '../../actions/pdfgeneratorAction';
import { GetMeldingsstatistikkRapport } from '../../actions/RapporterAction';
import Spinner from 'react-spinkit';
import MeldingSortering from './../../components/Meldinger/MeldingSortering';
import { loadVarsel } from '../../actions/VarselActions';
import featureIsEnabled, { FEATURE_VIS_VARSEL } from '../../utils/FeatureManager';

class EntrepreneurMeldinger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            allchecked: false,
            checkboxes: [],
            printOut: false,
            disabled: 'disabled',
            filter: '',
            fritekstSok: '',
            activePage: 1,
            margins: 100,
            markerId: '',
            spinnerLoad: false,
            loadingMessages: true,
            filterChanged: false,
            updatedMapBounds: [],
        };
        this.Expandable = this.Expandable.bind(this);
        this.Search = this.Search.bind(this);
        //this.onChangeChecboxes = this.onChangeChecboxes.bind(this)
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.handleSelectPagination = this.handleSelectPagination.bind(this);
        this.forceUpdate = this.forceUpdate.bind(this);
        this.filterFunction = this.filterFunction.bind(this);
        this.getFilters = this.getFilters.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.meldingerLoaded = this.meldingerLoaded.bind(this);
        this.removeCheckedOnFilterChange = this.removeCheckedOnFilterChange.bind(this);
    }

    getSelskapsId() {
        this.props.getSelskapsIdForLoggedUser();
        const selskapId = this.props.selskapId;
        return selskapId;
    }

    loadAvtalerForLoggedUser(selskapId) {
        let userId = cookieHandler.UserId();
        if (selskapId && userId) {
            if (cookieHandler.UserIsEntrepreneur()) {
                this.props.loadAvtalerForEntrepreneur(selskapId, userId);
            } else if (cookieHandler.UserIsEntrepreneurAdmin()) {
                this.props.loadAvtalerForEntrepreneurAdmin(selskapId);
            }
        }
    }

    componentDidMount() {
        let page = this.getQuery('page');

        if (page) this.setState({ activePage: parseInt(page, 10) });
        this.props.loadEntrepreneurs();
        this.props.loadMeldingstyper();
        this.props.loadkatagoriGroups();
        this.props.loadMeldingsstatuser();
        this.props.loadDelbydeler();
        this.getMeldinger();

        if (featureIsEnabled(FEATURE_VIS_VARSEL)) {
            this.props.loadVarsel();
        }
    }

    componentDidUpdate() {
        const page = parseInt(this.getQuery('page') || 1);
        const counter = this.props.meldingercounter;
        const pagescount = this.pages(counter);

        if (page > pagescount) {
            this.setActivePage(pagescount);
            this.getMeldinger();
        }
    }

    getMeldinger() {
        this.props.getSelskapsIdForLoggedUser().then(selskapId => {
            this.props.GetSavedMeldingSearchByBrukerId(cookieHandler.UserId()).then(filters => {
                const defaultFilter = this.getDefaultSavedFiltersForUser(filters);
                const currentFilter = this.getFilters();
                const filter = defaultFilter ? defaultFilter : currentFilter;
                this.props.loadEntrepreneurMeldinger(selskapId, filter, this.meldingerLoaded);
            });
            this.loadAvtalerForLoggedUser(selskapId);
        });
    }

    getDefaultSavedFiltersForUser() {
        const defaultsavedsearch = _.first(_.filter(this.props.lagredsok, { standard: true }));
        const filter = defaultsavedsearch ? defaultsavedsearch.filter : '';
        if (filter.length === 0) return;
        const pathname = this.context.router.location.pathname;
        const uri = pathname + '?' + filter;
        this.context.router.push(uri);
        return filter;
    }

    getQuery(q) {
        let query = this.context.router.location.query;
        if (query[q]) {
            let value = query[q];
            return value;
        }
    }

    getFilters() {
        let location = this.context.router.location;
        let search = location.search;
        let filter = search.substring(1, search.length);
        return filter;
    }

    queryBuilder(key, value) {
        const pathname = this.context.router.location.pathname;
        const params = this.context.router.location.query;
        if (value && value !== '0') params[key] = value;
        else delete params[key];
        const qu = queryString.stringify(params);
        const uri = pathname + '?' + qu;
        this.context.router.push(uri);
    }

    onMarkerClick(id) {
        this.setState({ markerId: id });
    }

    Expandable(e) {
        this.setState({ toggle: !this.state.toggle });
    }

    getCurrentPageMeldingIds = () => {
        let ids = _.map(this.props.meldinger, 'meldingId');
        return _.map(ids, i => {
            return i.toString();
        });
    };

    onChangeCheckBox(e) {
        const target = e.target;
        const checked = target.checked;
        const value = target.value;
        if (checked) {
            this.setState({
                checkboxes: [...this.state.checkboxes, value.toString()],
                disabled: '',
            });
        } else {
            const filter = this.state.checkboxes.filter(val => val !== value.toString());
            this.setState({ checkboxes: filter });
            if (_.size(filter) === 0) {
                this.setState({ disabled: 'disabled' });
            }
        }
    }

    forceUpdate() {
        let selskapsId = this.getSelskapsId();
        this.props.loadEntrepreneurMeldinger(selskapsId, this.getFilters(), this.meldingerLoaded);
        this.setState({ loadingMessages: true });
    }

    meldingerLoaded() {
        const { meldinger } = this.props;
        if (this.state.filterChanged) {
            // Calculate bounds og the recieved markers and post it to map component
            this.setState({ filterChanged: false, updatedMapBounds: meldinger });
        }
        this.setState({ loadingMessages: false });
        this.setState({ checkboxes: this.removeCheckedOnFilterChange() });
    }

    filterFunction(page = 1) {
        this.setState({ filterChanged: true });
        this.setActivePage(page);
        this.forceUpdate();
    }

    removeCheckedOnFilterChange() {
        //fjerner de meldingene som filteres bort fra print-liste
        let shownMessages = [];
        if (this.state.checkboxes.length > 0) {
            var allMessages = this.getCurrentPageMeldingIds();
            this.state.checkboxes.map(item => {
                if (allMessages.indexOf(item) !== -1) shownMessages.push(item);
            });
        }
        return shownMessages;
    }

    Search(text) {
        this.setState({ fritekstSok: text });
        this.filterFunction();
    }

    handleSelectPagination(number) {
        this.filterFunction(number);
    }

    setActivePage(number) {
        this.setState({ activePage: number });
        this.queryBuilder('page', number);
        window.scrollTo(0, 0);
    }

    pages(counter) {
        const numberOfPages = counter / this.state.margins;
        if (counter > this.state.margins) return Math.ceil(numberOfPages);
        return 1;
    }

    onPrint() {
        this.setState({ spinnerLoad: true });
        const meldingIds = this.state.checkboxes.length > 0 ? this.state.checkboxes : this.getCurrentPageMeldingIds();
        const format = _.map(meldingIds, i => {
            return 'MeldingId=' + i;
        });
        const join = _.join(format, '&');

        this.props
            .genererPdf(join, 'Bymelding_' + dateFormat(Date.now(), 'yyyymmdd_HHMMss') + '.pdf')
            .then(res => {})
            .finally(() => {
                this.setState({
                    spinnerLoad: false,
                    allchecked: false,
                    checkboxes: [],
                    errors: {},
                    disabled: 'disabled',
                });
            });
    }

    render() {
        const {
            toggle,
            fritekstSok,
            checkboxes,
            disabled,
            activePage,
            allchecked,
            spinnerLoad,
            loadingMessages,
            updatedMapBounds,
        } = this.state;
        const { meldinger, GetMeldingsstatistikkRapport } = this.props;
        const counter = this.props.meldingercounter;
        const size = _.size(meldinger);
        const pagescount = this.pages(counter);
        const routeToDetails = routes.entrepreneurMeldinger;
        const varsel = this.props.varsel;

        return (
            <div>
                <MeldingerPageHeader
                    className="page-header-fixed"
                    title={`${'Meldinger (' + counter + ')'}`}
                    toggleHandler={this.Expandable}
                    showSearchField={!toggle}
                    OnSearch={this.Search}
                    loading={loadingMessages}
                    isServiceavdeling={false}
                    varsel={varsel}
                />
                <div className="mainContainer">
                    <MeldingerFilter
                        {...this.props}
                        filterFunction={this.filterFunction}
                        IsServiceavdeling={false}
                        SendNewMelding={this.props.PostNewMelding}
                        reloadMeldinger={this.forceUpdate}
                        freetext={fritekstSok}
                        onSelectAllChecks={this.onChangeChecboxes}
                        disabled={disabled}
                        allchecked={allchecked}
                        onPrint={this.onPrint}
                        onPrintLoading={spinnerLoad}
                        savedsearches={this.props.lagredsok}
                        addSavedSearch={this.props.AddToSavedMeldingSearch}
                        deleteSavedSearch={this.props.DeleteFromSavedMeldingSearch}
                        meldingsstatistikkRapport={GetMeldingsstatistikkRapport}
                    />
                    <div className="meldingerAndkartContainer">
                        <div className="col-xs-6">
                            {meldinger.length === 0 && loadingMessages && (
                                <center style={{ marginTop: '100px' }}>
                                    <Spinner
                                        spinnerName="circle"
                                        className="spinner-circle-div"
                                        style={{ width: '75px', height: '75px' }}
                                    />
                                </center>
                            )}
                            {meldinger.length > 1 && <MeldingSortering filterFunction={this.filterFunction} />}
                            <MeldingList
                                items={meldinger}
                                changeCheckboxFromList={this.onChangeCheckBox}
                                checkboxes={checkboxes}
                                meldingkatagori={this.props.kategorigrupper}
                                routeTo={routeToDetails}
                                meldingstyper={this.props.meldingstyper}
                            />
                            <div className="text-center">
                                {pagescount > 1 && (
                                    <div className="pagination-container-fixed">
                                        <Pagination
                                            pages={pagescount}
                                            activePage={activePage}
                                            onSelect={this.handleSelectPagination}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            id="leafletDiv"
                            // className={classNames({ 'search-active': toggle })}
                        >
                            <LeafletmapWithFilters
                                markercluster={meldinger}
                                meldingsstatuser={this.props.meldingsstatuser}
                                count={size}
                                onMarkerClick={this.onMarkerClick}
                                meldingkatagori={this.props.kategorigrupper}
                                routeTo={routeToDetails}
                                updatedMapBounds={updatedMapBounds}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EntrepreneurMeldinger.contextTypes = {
    router: PropTypes.object,
};

EntrepreneurMeldinger.defaultProps = {
    meldingercounter: 0,
    meldinger: [],
    meldingstyper: [],
    meldingsavtaler: [],
    meldingsstatuser: [],
    entrepreneurs: [],
    kategorigrupper: [],
    selskapId: '',
    varsel: [],
};

EntrepreneurMeldinger.propTypes = {
    meldingercounter: PropTypes.number,
    meldinger: PropTypes.array.isRequired,
    meldingstyper: PropTypes.array.isRequired,
    kategorigrupper: PropTypes.array.isRequired,
    meldingsavtaler: PropTypes.array.isRequired,
    meldingsstatuser: PropTypes.array.isRequired,
    entrepreneurs: PropTypes.array.isRequired,
    loadEntrepreneurMeldinger: PropTypes.func.isRequired,
    loadEntrepreneurs: PropTypes.func.isRequired,
    loadMeldingstyper: PropTypes.func.isRequired,
    loadkatagoriGroups: PropTypes.func.isRequired,
    loadMeldingsstatuser: PropTypes.func.isRequired,
    loadAvtalerForEntrepreneur: PropTypes.func,
    loadAvtalerForEntrepreneurAdmin: PropTypes.func,
    loadDelbydeler: PropTypes.func.isRequired,
    selskapId: PropTypes.string,
    getSelskapsIdForLoggedUser: PropTypes.func,
    genererPdf: PropTypes.func.isRequired,
    PostNewMelding: PropTypes.func.isRequired,
    GetSavedMeldingSearchByBrukerId: PropTypes.func.isRequired,
    AddToSavedMeldingSearch: PropTypes.func.isRequired,
    DeleteFromSavedMeldingSearch: PropTypes.func.isRequired,
    lagredsok: PropTypes.array.isRequired,
    GetmeldingsstatistikkRapport: PropTypes.func,
    delbydeler: PropTypes.array.isRequired,
    varsel: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
    return {
        meldingercounter: state.meldinger.meldingcounter,
        meldinger: state.meldinger.meldingerList,
        meldingstyper: state.meldinger.meldingstyper,
        kategorigrupper: state.meldinger.kategorigrupper,
        meldingsavtaler: state.contractZones,
        meldingsstatuser: state.meldinger.meldingsstatuser,
        entrepreneurs: state.meldinger.entrepreneurs,
        selskapId: state.meldinger.selskapId,
        lagredsok: state.lagredSok,
        delbydeler: state.delbydeler,
        varsel: state.varsel.varsel,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            loadEntrepreneurMeldinger,
            loadEntrepreneurs,
            loadMeldingstyper,
            PostNewMelding,
            loadMeldingsstatuser,
            loadAvtalerForEntrepreneurAdmin,
            loadAvtalerForEntrepreneur,
            loadkatagoriGroups,
            loadDelbydeler,
            getSelskapsIdForLoggedUser,
            genererPdf,
            GetSavedMeldingSearchByBrukerId,
            DeleteFromSavedMeldingSearch,
            AddToSavedMeldingSearch,
            GetMeldingsstatistikkRapport,
            loadVarsel,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EntrepreneurMeldinger);
