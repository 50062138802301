import React, {Component, PropTypes} from 'react';
import {Modal, Button, Glyphicon} from 'react-bootstrap';
import NewUserFormContainer from '../../../containers/User/NewUserContainer';

import '../../../css/common/ButtonModal.css';

class NewUserModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.state = this.initialState();
    }

    initialState() {
        return {
            open: false
        };
    }

    openModal() {
        this.setState({open: true});
    }

    closeModal() {
        this.setState(this.initialState());
    }

    render() {
        //console.log("....",this.props.selskap)
 
        return (
            <div>
                <Button className="bym-add-btn" onClick={this.openModal}>
                    <Glyphicon glyph="plus" />
                    {this.props.buttonText}
                </Button>
                <Modal show={this.state.open} onHide={this.closeModal} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title><strong>{this.props.modalTitle}</strong></Modal.Title>
                    </Modal.Header>
                    <NewUserFormContainer selskapsType={this.props.selskapsType} 
                                          selskapId={this.props.selskapId}
                                          avtaler={this.props.avtaler}
                                          submitButtonText={this.props.buttonText}
                                          whenDone={() => {this.closeModal();}}/>
                </Modal>
            </div>
        );
    }
}

NewUserModal.propTypes = {
    confirmText: PropTypes.string.isRequired,
    avtaler:PropTypes.array,
    buttonText: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired,
    selskapsType: PropTypes.number,
    selskapId: PropTypes.string,
    feilrettingskoder: PropTypes.array,
};

export default NewUserModal;