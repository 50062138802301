import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import {putMelidngstatusMal} from '../../constants/api'
import {
  loadMeldingstyper,
  loadMeldingsstatuser,
  loadkatagoriGroups,
} from '../../actions/MeldingerActions';
import EditableField from '../common/EditableField';
import TextArea from '../common/TextArea';
import { Tabs, Tab, Button, Row, Col, Glyphicon } from 'react-bootstrap';

class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meldingstyper: [],
      meldingsstatuser: [],
      kategorigrupper: [],
    };

    this.handleMeldingsstatusChange = this.handleMeldingsstatusChange.bind(this);
    this.handleMeldingsstatusSubmit = this.handleMeldingsstatusSubmit.bind(this);
    this.handleMeldingskategoriChange = this.handleMeldingskategoriChange.bind(this);
    this.handleMeldingstypeChange = this.handleMeldingstypeChange.bind(this);
  }

  componentDidMount() {
    this.props.loadMeldingsstatuser();
    this.props.loadMeldingstyper();
    this.props.loadkatagoriGroups();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({meldingstyper: nextProps.meldingstyper});
    this.setState({meldingsstatuser: nextProps.meldingsstatuser});
    this.setState({kategorigrupper: nextProps.kategorigrupper});
  }

  erSkjultMeldingsstatus(status) {
    const skjulte = [1, 2, 3, 5, 32, 50];

    return skjulte.includes(status.id); 
  }

  handleMeldingsstatusChange(e) {
    let index = e.target.id;
    index = index.replace('meldingsstatus-', '')
    let statuser = [...this.state.meldingsstatuser];
    let status = {
      ...statuser[index],
      tekstmal: e.target.value
  }
  statuser[index] = status;
    
    this.setState({ meldingsstatuser: statuser });
  }

  handleMeldingsstatusSubmit(e) {
    let index = e.target.id;
    index = index.replace('meldingsstatussubmit-', '')
    let statuser = [...this.state.meldingsstatuser];
    let status = {
      ...statuser[index]
    }
    const data = {
      tekstmal: status.tekstmal
    };
    
    axios.put(putMelidngstatusMal(status.id), JSON.stringify(data),
    {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json;charset=UTF-8'
      }});
  }



  handleMeldingskategoriChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleMeldingstypeChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <div className="mainContainer container-padding">
        <div className="col-xs-12">
          <Tabs defaultActiveKey={1} id="entrepreneur-tabs">
            <br />
            <Tab eventKey={1} title="Meldingsstatuser">
              {this.state.meldingsstatuser.map((status, index) => {
                if (this.erSkjultMeldingsstatus(status)) return;
                return (
                  <div className="meldingListRowDiv">
                    <TextArea
                      rows="5"
                      label={status.navn}
                      id={"meldingsstatus-"+index}
                      onChange={this.handleMeldingsstatusChange}
                      value={status.tekstmal}
                      name={status.id.toString()}
                    />
                    <Button className="knapp-new" id={"meldingsstatussubmit-"+index} onClick={this.handleMeldingsstatusSubmit}>Oppdater</Button>
                    <hr/>
                    <br/>
                  </div>
                );
              })}
            </Tab>
            <Tab eventKey={2} title="Meldingskategorier">
              {this.state.kategorigrupper.map(gruppe => {
                return (
                  <div>
                    <h3>{gruppe.meldingstype.beskrivelse}</h3>
                    <div>
                      {gruppe.meldingskategorier.map(kategori => {
                        return <p>{kategori.navn}</p>;
                      })}
                    </div>
                  </div>
                );
              })}
            </Tab>
            <Tab eventKey={3} title="Meldingstyper">
              {this.state.meldingstyper.map(type => {
                return (
                  <div>
                    <p>{type.beskrivelse}</p>
                  </div>
                );
              })}
            </Tab>
            <Tab eventKey={4} title="Diverse">
              <button label="test" className="knapp-new">Reset miljø.</button>
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

AdminPage.defaultProps = {
  meldingstyper: [],
  meldingsstatuser: [],
  kategorigrupper: [],
};
AdminPage.propTypes = {
  meldingstyper: PropTypes.array.isRequired,
  kategorigrupper: PropTypes.array.isRequired,
  meldingsstatuser: PropTypes.array.isRequired,
  loadMeldingstyper: PropTypes.func.isRequired,
  loadkatagoriGroups: PropTypes.func.isRequired,
  loadMeldingsstatuser: PropTypes.func.isRequired,
};
const mapStateToProps = state => {
  return {
    meldingstyper: state.meldinger.meldingstyper,
    kategorigrupper: state.meldinger.kategorigrupper,
    meldingsstatuser: state.meldinger.meldingsstatuser,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loadMeldingstyper,
      loadMeldingsstatuser,
      loadkatagoriGroups,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
