import React, { PropTypes } from 'react';
import { Link } from 'react-router';

const AnchorLink = ({ to, pageTitle, pageLink, children, linkLabel, className }) =>
{
    return (
        <Link className={className} to={{ pathname: to, state: { subTitle: children, pageTitle: pageTitle, pageLink: pageLink } }}>{linkLabel}</Link>
    );
};

AnchorLink.propTypes = {
    to: PropTypes.string.isRequired,
    pageLink: PropTypes.string,
    children: PropTypes.node,
    pageTitle: PropTypes.string,
    linkLabel: PropTypes.string,
    className: PropTypes.string,
};

export default AnchorLink;