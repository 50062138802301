import axios from 'axios';
import * as types from './ActionTypes';
import * as api from '../constants/api';

// eslint-disable-next-line import/prefer-default-export
export const loadDelbydeler = () => {
  const url = api.delbydelerUrl;
  return dispatch => {
      return axios.get(url).then(res => {
          const delbydeler = res.data.result;
          dispatch({ type: types.LOAD_DELBYDELER_LIST_SUCCESS, delbydeler });
          return delbydeler;
      });
  };
};
