import React, { PropTypes, Component } from 'react';
import { Tabs, Tab, Button, Row, Col, Glyphicon } from 'react-bootstrap';
import PriceSchemeList from './PriceSchemeList';
import DateModal from '../../components/common/DateModal';
import ConfirmModal from '../../components/common/ConfirmModal';
import ReactDOM from 'react-dom';

import '../../css/Prisskjema.css';

class PrisSkjema extends Component {

  constructor(props) {
    super(props);
    this.handleCancelNyttSkjema = this.handleCancelNyttSkjema.bind(this);
    this.handleNyttskjema = this.handleNyttskjema.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.state = {
      key: 1,             //tab no
      open: false,        //modal open , ie ny dato modal
      openConfirm: false,  //confirm modal is open
    };
  }

  handleSelect(key) {
    this.setState({ key });
  }

  handleNyttskjema(e) {
    this.setState({ open: true });
  }

  handleCancelNyttSkjema(e) {
    this.setState({ open: false });
    //Timeout to let the link button loose focus
    setTimeout(function () {
      ReactDOM.findDOMNode(this.refs.newbutton).blur();
    }.bind(this), 200);
  }

  handleOKNyttSkjema(validFromDate) {
    this.setState({ open: false });
    this.props.onNewScheme(validFromDate);
  }

  handleRemove(e, tabno) {
    this.setState({ openConfirm: true });
  }

  handleCancelRemove(e) {
    this.setState({ openConfirm: false });
  }

  handleConfirmRemove(e, tabno) {
    this.setState({ openConfirm: false });
    this.props.onRemoveBulk();
    //Timeout to let the tab 2 , Fremtidig, button loose focus
    setTimeout(function () {
      this.setState({ key: 1 });
    }.bind(this), 200);
  }




  format(input, tabno) {
    let ret = "";
    if (tabno === 1) ret = <div><span className="text-16px"><strong>{input}</strong></span></div>;
    if (tabno === 2) ret = <div><span className="text-16px"><strong>{input}</strong></span>
      <button id="close-button" onClick={(e) => this.handleRemove(e, tabno)} ref="removebutton"><Glyphicon glyph="remove-sign" /></button></div>  //glyph: remove, remove-circle, remove-sign
    return (ret);
  }


  render() {
    // let spinnerLoad =this.props.spinnerLoad
    const OneTabContent = (
      <div>
        <div className="gap-50"></div>

        <Row>
          <Col md={2}>
            <h2 className="prisskjema-header">Prisskjema</h2>
          </Col>
        </Row>

        <div className="gap-20"></div>
        {!this.state.isEntrepreneurAdmin && <Row>

          <Col md={1}>
            <div className="div-center">
              <span className="text-16px"><strong>Nåværende</strong></span>
            </div>

          </Col>

          <Col md={1}>
            <div className="div-center">
              <Button id="nyttskjema-button" bsStyle="link" onClick={(e) => this.handleNyttskjema(e)} ref="newbutton"><span className="text-16px"><strong>+ Nytt skjema</strong></span></Button>
            </div>
          </Col>
        </Row>
        }

        <Row>
          <Col md={12}>
            <PriceSchemeList pricescheme={this.props.schemes[0].scheme} lastChanged={this.props.schemes[0].validfrom} prosesscodes={this.props.prosesscodes}
              deAktiver={(rad) => this.props.deAktiver(rad, this.state.key)} onClick={(e) => this.props.onClick(e, this.state.key)} onChange={(e) => this.props.onChange(e)}
              onSelect={(prosesscode) => this.props.onSelect(prosesscode)} onSubmit={(e, rad) => this.props.onSubmit(e, rad, this.state.key)}
              tabNo={this.state.key} spinnerLoad={this.props.spinnerLoad} addFlashMessage={this.props.addFlashMessage} />
          </Col>
        </Row>
        {this.state.open && <DateModal onConfirm={(e) => this.handleOKNyttSkjema(e)} onCancel={(e) => this.handleCancelNyttSkjema(e)} confirmText="Angi Gyldig Fra Dato" buttonText="OK" modalTitle="Angi Gyldig Fra Dato" />}
      </div>
    );

    const TwoTabContent = (
      
      <div>
        <div className="gap-20"></div>
        <Row>
          <Col md={2}>
            <h2 className="prisskjema-header">Prisskjema</h2>
          </Col>
        </Row>
      
        <div className="gap-20"></div>
        <Tabs activeKey={this.state.key} onSelect={this.handleSelect} id="controlled-tab-example" animation={false}>
          {this.props.schemes.map(t =>
            <Tab key={t.tab} eventKey={t.tab} title={this.format(t.title, t.tab)}>
              <PriceSchemeList
                pricescheme={t.scheme}
                lastChanged={t.validfrom}
                prosesscodes={this.props.prosesscodes}
                enheter={this.props.enheter}
                deAktiver={(rad) => this.props.deAktiver(rad, this.state.key)} 
                onClick={(e) => this.props.onClick(e, this.state.key)} 
                onChange={(e) => this.props.onChange(e)}
                onSelect={(prosesscode) => this.props.onSelect(prosesscode)} 
                onSubmit={(e, rad) => this.props.onSubmit(e, rad, this.state.key)}
                tabNo={this.state.key} 
                spinnerLoad={this.props.spinnerLoad} 
                addFlashMessage={this.props.addFlashMessage} 
              />
            </Tab>
          )}
        </Tabs>

        {this.state.openConfirm && <ConfirmModal onConfirm={(e) => this.handleConfirmRemove(e)} onCancel={(e) => this.handleCancelRemove(e)} confirmText="Bekreft" bodyText="Bekreft sletting av 'Fremtidig' skjema." modalTitle="Bekreft sletting av skjema" />}
      </div>
    );


    return (
      <div>
        {this.props.schemes.length < 2 && OneTabContent}
        {this.props.schemes.length === 2 && TwoTabContent}
      </div>
    );
  }
}

PrisSkjema.propTypes = {
  schemes: PropTypes.array.isRequired,
  prosesscodes: PropTypes.array.isRequired,
  enheter: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  deAktiver: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onNewScheme: PropTypes.func.isRequired,
  onRemoveBulk: PropTypes.func.isRequired,
  spinnerLoad: PropTypes.bool,
  addFlashMessage: PropTypes.func.isRequired
};

export default PrisSkjema;

