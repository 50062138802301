import * as types from './ActionTypes';
import { beginAjaxCall/*, ajaxCallError */ } from '../Api_MockData/ajaxStatusActions';
import axios from 'axios';
import { avtalerUrl, updateAvtaleUrl, getAvtalerForEntrepreneurAdminUrl, getAvtalerForEntrepreneurUrl } from "../constants/api";
import { successData } from "../utils/utils"
import { addFlashMessage } from './FlashMessagesAction'
import { avtaleUpdated, avtaleIsNotUpdated } from "../constants/ResponseMessages"

export function loadAvtalerSuccess(contractZones)
{
    return { type: types.LOAD_CONTRACTZONES_SUCCESS, contractZones };
}

// function updateAvtaleSuccess(avtale)
// {
//     return { type: types.UPDATE_AVTALE_SUCCESS, payload: avtale };
// }

function updateAvtaleFailure(error)
{
    return { type: types.UPDATE_AVTALE_FAILURE, payload: error, error: true };
}

export function UpdateAvtale(payload)
{
    return function (dispatch)
    {
        dispatch(beginAjaxCall());
        const url = updateAvtaleUrl(payload.avtaleId)
        return axios.put(url, payload)
            .then(() =>
            {
                dispatch(loadAvtaler());
                dispatch(addFlashMessage({ type: 'success', text: avtaleUpdated }));
            })
            .catch(error =>
            {
                dispatch(updateAvtaleFailure(error));
                dispatch(addFlashMessage({ type: 'error', text: avtaleIsNotUpdated }));
                Promise.reject(avtaleIsNotUpdated);
            });
    }
}

export function loadAvtaler()
{
    return function (dispatch)
    {
        dispatch(beginAjaxCall());
        return axios.get(avtalerUrl)
            .then(response => dispatch(loadAvtalerSuccess(successData(response))))
            .catch(error => { throw (error) });
    }
}


export function loadAvtalerForEntrepreneurAdmin(selskapId)
{
    let url = getAvtalerForEntrepreneurAdminUrl(selskapId)
    return dispatch =>
    {
        return axios.get(url).then(res =>
        {
            let meldingsavtaler = successData(res)
            dispatch(loadAvtalerSuccess(meldingsavtaler))
        })
    }
}

export function loadAvtalerForEntrepreneur(selskapId, brukerId)
{
    let url = getAvtalerForEntrepreneurUrl(selskapId, brukerId)
    return dispatch =>
    {
        return axios.get(url).then(res =>
        {
            let meldingsavtaler = successData(res)
            dispatch(loadAvtalerSuccess(meldingsavtaler))
        })
    }
}