import _ from 'lodash'
import * as cookieHandler from "./cookieHandler"
import * as routes from "../constants/clientRoutes"

export function checkToken(nextState, replaceState)
{
    const jwt = cookieHandler.validateToken();
    const pathname = nextState.location.pathname
    const noneRequireAuth = [routes.home, routes.login, routes.glemtpass, routes.UpdatePasswordConfirmed, routes.forgotpasswordEpostrecieved]
    if (!jwt)
    {
        if (!_.includes(noneRequireAuth, pathname))
        {
            replaceState(pathname)
            return false;
        }
    }
    else
    {
        cookieHandler.SetAuthorizationToken();
        if (cookieHandler.UserIsServicedeskAdmin() || cookieHandler.UserIsServicedesk())
        {
            replaceState(routes.saksbehandlerMeldinger)
            return false;
        }
        else if (cookieHandler.UserIsBymAdmin())
        {
            replaceState(routes.serviceAvdelingen)
            return false;
        }
        else if (cookieHandler.UserIsEntrepreneurAdmin() || cookieHandler.UserIsEntrepreneur())
        {
            replaceState(routes.entrepreneurMeldinger)
            return false;
        }
        else
        {
            replaceState(routes.login)
            return false;
        }
        //window.history.back();
    }

}

