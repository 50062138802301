import * as types from './ActionTypes';
import processcodeApi from '../Api_MockData/mockProcesscodesApi';
import { beginAjaxCall, ajaxCallError } from '../Api_MockData/ajaxStatusActions';
import axios from 'axios';
import { useMock } from "../constants/settings";
import { prosesskoderUrl, prosessKodeSkjemaerUrl } from "../constants/api";
import { successData, errorData } from "../utils/utils"
import { ProcessCodeIsCreated, ProcessCodeIsRemoved } from "../constants/ResponseMessages"
import { addFlashMessage } from "./FlashMessagesAction"


export function loadProcesscodesSuccess(processcodes)
{
    return { type: types.LOAD_PROCESSCODES_SUCCESS, processcodes };
}

export function createProcesscodeSuccess(processcode)
{
    return { type: types.CREATE_PROCESSCODE_SUCCESS, processcode };
}

export function updateProcesscodeSuccess(processcode)
{
    return { type: types.UPDATE_PROCESSCODE_SUCCESS, processcode };
}

export function deleteProcesscodeSuccess(processcode)
{
    return { type: types.DELETE_PROCESSCODE_SUCCESS, processcode };
}

export function loadProcessCodesList()
{
    let url = prosesskoderUrl
    return dispatch =>
    {
        return axios.get(url).then(res =>
        {
            let processcodes = successData(res)
            dispatch(loadProcesscodesSuccess(processcodes))
        })
    }
}

export function loadProcesscodes(schemeId)
{
    return function (dispatch)
    {
        dispatch(beginAjaxCall());

        let url = prosessKodeSkjemaerUrl + "/" + schemeId + "/prosesskoder";

        if (useMock === false)
        {
            //axios.defaults.withCredentials = false;
            axios.get(url)
                .then(function (response)
                {
                    const pc = response.data.result.map(function (x)
                    {
                        return {
                            id: x.id,
                            navn: x.navn,
                            beskrivelse: x.beskrivelse,
                            prosesskodeEnhetId: x.prosesskodeEnhet.id,
                            prosesskodeEnhetNavn: x.prosesskodeEnhet.navn,
                            prosesskodeSkjemaId: x.prosesskodeSkjema.id
                        }
                    });
                    dispatch(loadProcesscodesSuccess(pc));
                })
                .catch((error) => { throw (error) });
        }
        else
        {
            return processcodeApi.getAllProcesscodes()
                .then(processcodes =>
                {
                    dispatch(loadProcesscodesSuccess(processcodes));
                })
                .catch(error => { throw (error); });
        }

    };
}


export function saveProcesscode(processcode)
{
    return function (dispatch)
    {
        dispatch(beginAjaxCall());

        if (useMock === false)
        {
            // const p = {
            //     prosesskodeSkjemaId: processcode.schemeid,
            //     navn: processcode.name,
            //     beskrivelse: processcode.beskrivelse,
            //     enhet: processcode.enhet,
            //     prosesskodeEnhetNavn: processcode.prosesskodeEnhetNavn
            // };
            //axios.defaults.withCredentials = false;
            axios.post(prosesskoderUrl, processcode)
                .then(function (response)
                {
                    if (response.data)
                    {
                        processcode.id = response.data.result.id;
                        processcode.deactivated = "false";
                        processcode.prosesskodeEnhetNavn = processcode.prosesskodeEnhetNavn
                        dispatch(createProcesscodeSuccess(processcode));
                        //loadProcesscodes(processcode.prosesskodeSkjemaId)
                        dispatch(addFlashMessage({ type: 'success', text: ProcessCodeIsCreated }));

                    }
                })
                .catch(function (error)
                {
                    const err = errorData(error)
                    dispatch(addFlashMessage({ type: 'error', text: err }));
                });
        }
        else
        {
            return processcodeApi.saveProcesscode(processcode).then(processcode =>
            {
                processcode.id ? dispatch(updateProcesscodeSuccess(processcode)) :
                    dispatch(createProcesscodeSuccess(processcode));
                dispatch(addFlashMessage({ type: 'success', text: ProcessCodeIsCreated }));


            }).catch(error =>
            {
                dispatch(ajaxCallError(error));
                const err = errorData(error)
                dispatch(addFlashMessage({ type: 'error', text: err }));
            });
        }

    };
}


export function deaktiverProcesscode(pcode)
{
    return function (dispatch)
    {
        dispatch(beginAjaxCall());

        const url_delete = prosesskoderUrl + '/' + pcode.id;

        if (useMock === false)
        {
            // axios.defaults.withCredentials = false;
            axios.delete(url_delete)
                .then(function (response)
                {
                    if (response.data)
                    {
                        dispatch(deleteProcesscodeSuccess(pcode));
                        dispatch(addFlashMessage({ type: 'success', text: ProcessCodeIsRemoved }));

                    }
                })
                .catch(function (error)
                {
                    const err = errorData(error)
                    dispatch(addFlashMessage({ type: 'error', text: err }));
                });
        }
        else
        {
            return processcodeApi.saveProcesscode(pcode).then(processcode =>
            {
                processcode.id ? dispatch(updateProcesscodeSuccess(processcode)) :
                    dispatch(createProcesscodeSuccess(processcode));
                dispatch(addFlashMessage({ type: 'success', text: ProcessCodeIsRemoved }));

            }).catch(error =>
            {
                dispatch(ajaxCallError(error));
                const err = errorData(error)
                dispatch(addFlashMessage({ type: 'error', text: err }));
            });
        }

    };
}


export function changeName(values)
{
    return function (dispatch)
    {
        dispatch(beginAjaxCall());

        let url_processcode = prosesskoderUrl + "/" + values.id;
        if (useMock === false)
        {
            //axios.defaults.withCredentials = false;
            axios.put(url_processcode, values)
                .then(function (response)
                {
                    if (response.data)
                    {
                        dispatch(updateProcesscodeSuccess(values));
                    }
                })
                .catch(function (error)
                {
                    const err = errorData(error)
                    dispatch(addFlashMessage({ type: 'error', text: err }));
                });
        }

    };
}