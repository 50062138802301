import React, { Component } from 'react';
import { Button, Glyphicon } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RootCloseWrapper } from 'react-overlays';
import Datepicker from 'react-bootstrap-date-picker';
import { daylabels, monthlabels } from '../../constants/settings';
import './EditableRessursbruk.css';

class EditableRessursbrukDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newValue: null,
            editActive: false,
        };
    }

    cancel = () => {
        this.setState({ editActive: false, newValue: null });
    };

    save = () => {
        const { onSubmitEditableDate, ressursbruk, value } = this.props;
        const { newValue } = this.state;
        const newDateTime = moment(newValue);
        const oldDateTime = moment(value);
        newDateTime.set({
            hour: oldDateTime.hour(),
            minute: oldDateTime.minute(),
        }); 
        onSubmitEditableDate(newDateTime.format(), ressursbruk);
        this.setState({ editActive: false, newValue: null });
    };

    activate = () => {
        const { value } = this.props;
        this.setState({ editActive: true, newValue: value });
    };

    change = e => {
        this.setState({ newValue: e });
    };

    render() {
        const { value, isEditable } = this.props;
        const { editActive, newValue } = this.state;

        return !editActive ? (
            <Button
                className="editable-ressursbruk-activate-button editable-ressursbruk-activate-button-date"
                disabled={!isEditable}
                onClick={this.activate}
            >
                {`${moment(value).format('DD.MM.YYYY')}`}
            </Button>
        ) : (
            <RootCloseWrapper onRootClose={this.cancel}>
                <form className="editable-ressursbruk editable-ressursbruk-date">
                    <Datepicker
                        value={newValue}
                        onChange={this.change}
                        maxDate={new Date()}
                        weekStartsOnMonday
                        dayLabels={daylabels}
                        monthLabels={monthlabels}
                        autoFocus
                        showClearButton={false}
                        dateFormat="DD.MM.YYYY"
                    />
                    <Button
                        id="save"
                        type="submit"
                        bsSize="small"
                        onClick={this.save}
                    >
                        <Glyphicon glyph="ok" className="button-save" />
                    </Button>
                    <Button id="save" bsSize="small" onClick={this.cancel}>
                        <Glyphicon glyph="remove" className="button-cancel" />
                    </Button>
                </form>
            </RootCloseWrapper>
        );
    }
}

EditableRessursbrukDate.propTypes = {
    value: PropTypes.string,
    isEditable: PropTypes.bool,
    onSubmitEditableDate: PropTypes.func.isRequired,
    ressursbruk: PropTypes.shape({}),
};

EditableRessursbrukDate.defaultProps = {
    value: '',
    isEditable: false,
    ressursbruk: {},
};

export default EditableRessursbrukDate;
