import React, { Component, PropTypes } from 'react';
import { FormGroup, InputGroup, Button, Glyphicon } from 'react-bootstrap';
import classnames from 'classnames';
import '../../css/common/DatePickerEditable.css';
import DatePicker from '../common/NorwegianDatepicker';
import { formatDateByCommaSepratedDayMonthYear } from '../../utils/utils';

class DatePickerEditable extends Component {
    constructor(props) {
        super(props);
        this.click = this.click.bind(this);
        this.cancel = this.cancel.bind(this);
        this.submit = this.submit.bind(this);
        this.onChangeDateFrom = this.onChangeDateFrom.bind(this);
        this.state = {
            editing: false,
            date: props.value,
            error: '',
            showClearButton: false,
        };
    }

    click() {
        if (!this.state.editing) {
            this.setState({ editing: true, error: '' });
        }
    }

    cancel() {
        this.setState({ editing: false, date: this.props.value, error: '' });
    }

    submit() {
        let dateValue = this.state.date;
        this.props.onSubmit(dateValue);
        this.setState({ error: '', editing: false });
    }

    onChangeDateFrom(e) {
        this.setState({ date: e });
    }
    renderEditing() {
        const {
            label,
            name,
            style,
            id,
            placeholder,
            showClearButton,
            useBody,
        } = this.props;
        const { date, error } = this.state;
        const extraProps = {};
        if (useBody) {
            extraProps.calendarContainer = document.body;
        }
        return (
            <FormGroup
                id={this.props.id}
                className={classnames('editable-field-form', {
                    'has-error': error,
                })}
            >
                {label && (
                    <label className="control-label" htmlFor="date">
                        {label}
                    </label>
                )}
                <div
                    className="input-group"
                    id="dotted"
                    ref={node => (this.node = node)}
                    /* onBlur={this.onBlur}*/ onSubmit={this.Submit}
                >
                    <DatePicker
                        id={id}
                        dateFormat="DD.MM.YYYY"
                        value={date}
                        style={style}
                        className="form-control editable-field-textarea datepicker-css-override"
                        name={name}
                        placeholder={placeholder}
                        onChange={this.onChangeDateFrom}
                        showClearButton={showClearButton}
                        autoFocus={true}
                        {...extraProps}
                    />
                    <span className="input-group-btn editable-field-buttons">
                        <Button bsSize="small" onClick={this.cancel}>
                            <Glyphicon
                                glyph="remove"
                                className="editable-field-button-cancel"
                            />
                        </Button>
                        <Button
                            bsSize="small"
                            onClick={this.submit}
                            type="submit"
                        >
                            <Glyphicon
                                glyph="ok"
                                className="editable-field-button-save"
                            />
                        </Button>
                    </span>
                </div>
                {error.length > 0 && (
                    <span className="help-block">{error}</span>
                )}
            </FormGroup>
        );
    }

    renderNormal() {
        const { date, error } = this.state;
        let dato = formatDateByCommaSepratedDayMonthYear(date);
        return (
            <FormGroup
                id={this.props.id}
                className={classnames('editable-field-form', {
                    'has-error': error,
                })}
                onClick={this.click}
            >
                <InputGroup className="editable-field">
                    <input
                        autoFocus={false}
                        className="form-control editable-field-textarea"
                        value={dato}
                        readOnly
                    />
                    <InputGroup.Addon className="editable-field-span">
                        <Glyphicon glyph="pencil" />
                    </InputGroup.Addon>
                </InputGroup>
                {error.length > 0 && (
                    <span className="help-block">{error}</span>
                )}
            </FormGroup>
        );
    }

    render() {
        return this.state.editing ? this.renderEditing() : this.renderNormal();
    }
}

DatePickerEditable.propTypes = {
    isNumber: PropTypes.bool,
    inputLength: PropTypes.number,
    maxLength: PropTypes.number,
    text: PropTypes.string,
    id: PropTypes.string,
    onSubmit: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    showClearButton: PropTypes.bool,
    useBody: PropTypes.bool,
};

export default DatePickerEditable;
