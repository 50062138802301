import React, { Component, PropTypes } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSelskapsIdForLoggedUser } from "../../actions/MeldingerActions"
import { loadEntrepreneurContracts, saveContract } from '../../actions/contractActions';
import { loadEntrepreneurById, updateSelskap } from '../../actions/selskaperActions';
import EntrepreneurDetailHeader from '../../components/entrepreneur/EntrepreneurDetailHeader';
import ContractList from '../../components/entrepreneur/ContractList';
import { loadUsers } from '../../actions/userActions';
import { loadProcesscodeschemes } from '../../actions/processcodeschemeActions';
import { loadAvtalerForEntrepreneurAdmin, UpdateAvtale } from '../../actions/avtalerAction'
import _ from "lodash"
import * as cookieHandler from "../../utils/cookieHandler"
import '../../css/Entrepreneur.css';


class EntrepreneurAdminContracts extends Component
{
    constructor(props, context)
    {
        super(props, context);
        this.state = {
            selskap: {}
        }
        this.handleSaveName = this.handleSaveName.bind(this);
        this.handleSaveOrgnr = this.handleSaveOrgnr.bind(this);
        this.addContract = this.addContract.bind(this);
        this.submitAvtaler = this.submitAvtaler.bind(this)

    }

    setSelskapState(selskapId)
    {
        this.props.loadEntrepreneurById(selskapId).then(res =>
        {
            this.setState({ selskap: res })
        })
    }
    loadAvtalerForLoggedUser(selskapId)
    {
        let userId = cookieHandler.UserId()
        if (selskapId && userId)
        {
            if (cookieHandler.UserIsEntrepreneurAdmin())
            {
                this.props.loadAvtalerForEntrepreneurAdmin(selskapId)
            }
        }
    }

    componentWillMount()
    {
        this.props.getSelskapsIdForLoggedUser().then(selskapId =>
        {
            this.props.loadAvtalerForEntrepreneurAdmin(selskapId)
            this.setSelskapState(selskapId)
            this.props.loadEntrepreneurContracts(selskapId);
        })
    }
    componentDidMount()
    {
        if (this.props.selskapId)
        {
            this.props.loadProcesscodeschemes();
            this.props.loadAvtalerForEntrepreneurAdmin(this.props.selskapId)
        }
    }
    handleSaveName(input)
    {
        const selskap = this.state.selskap
        const payload = Object.assign({ id: selskap.id, navn: input, orgnr: selskap.orgnr, epost: selskap.epost, telefon: selskap.telefon, type: selskap.selskapsType })
        this.props.updateSelskap(payload).then(() =>
        {
            this.setSelskapState(selskap.id)
        });
    }

    handleSaveOrgnr(input)
    {
        const selskap = this.state.selskap
        const payload = Object.assign({ id: selskap.id, navn: selskap.navn, orgnr: input, epost: selskap.epost, telefon: selskap.telefon, type: selskap.selskapsType })
        this.props.updateSelskap(payload).then(() =>
        {
            this.setSelskapState(selskap.id)
        });
    }
    submitAvtaler(values)
    {
        const selskapsId = this.props.selskapId
        _.forEach(values, item =>
        {
            const avtale = _.find(this.props.avtaler, avtale => avtale.avtaleId === item.id);
            const payload = {
                avtaleId: avtale.avtaleId,
                selskapId: selskapsId,
                navn: avtale.navn,
                avtaleKobling: avtale.avtaleKobling,
                beskrivelse: avtale.beskrivelse
            };
            this.props.UpdateAvtale(payload);
        })
    }
    addContract(contract, avtale, prosesskodeSkjema)
    {
        this.props.saveContract(contract, avtale, prosesskodeSkjema);
    }
    getAllAvtaler()
    {
        return _.sortBy(_.map(this.props.avtaler, i =>
        {
            return Object.assign({}, { id: i.avtaleId, navn: i.beskrivelse })
        }), 'navn')
    }
    getRemainedAvtalerToAdd()
    {
        const allAvtaler = this.getAllAvtaler()
        const selskapsAvtaler = this.filterAvtalerForSelskap()
        if (allAvtaler && selskapsAvtaler)
        {
            return _.differenceBy(allAvtaler, selskapsAvtaler, "id")
        }
    }
    filterAvtalerForSelskap()
    {
        const selskapsId = this.props.selskapId
        const avtaler = this.props.avtaler
        return _.sortBy(_.map(_.filter(avtaler, { 'selskapId': selskapsId }), i =>
        {
            return Object.assign({}, { id: i.avtaleId, navn: i.navn, beskrivelse: i.beskrivelse })
        }), 'navn')
    }

    render()
    {
        const { selskap } = this.state
        if (!this.props.selskapId || !selskap.navn) return false;
        const userIsBymadmin = cookieHandler.UserIsBymAdmin();
        return (
            <div className="mainContainer col-xs-12">
                {/*<Row>
                        <Col md={12}>
                            <div>
                                <NewUserModal onConfirm={this.saveUser} confirmText="Legg til bruker"
                                    buttonText="Legg til bruker" modalTitle="Legg til bruker"
                                    selskapsType={this.props.selskap.type.id} avtaler={filteredAvtaler}
                                    selskapId={this.props.selskap.id} />
                                <NewContractModal buttonText="Legg til kontrakt"
                                    processcodeschemes={this.props.processcodeschemes}
                                    avtaler={filteredAvtaler} selskapsId={this.props.params.id}
                                    onSaveContract={this.addContract} />
                                <AddAvtalerModal avtaler={remainedAvtaler} submitAvtaler={this.submitAvtaler} />
                            </div>
                        </Col>
                    </Row>*/}
                <br />
                <br />
                <EntrepreneurDetailHeader selskapName={selskap.navn} orgNumber={selskap.orgnr}
                    onSubmitName={this.handleSaveName}
                    onSubmitOrgNr={this.handleSaveOrgnr} 
                    userIsBymadmin = {userIsBymadmin} />
                {/*<Tabs defaultActiveKey={1} id="entrepreneur-tabs">
                    <Tab eventKey={1} title="Brukere">
                        <UserListContainer selskapsType={selskapsTypeEntreprenorerId} selskapsId={this.props.params.id} editable={true} />
                    </Tab>
                    <Tab eventKey={2} title="Kontrakter">
                        <ContractList contracts={this.props.contracts} company={this.props.selskap} />
                    </Tab>
                    <Tab eventKey={3} title={`Avtaler ( ${size} )`}>
                        <AvtalerList avtaler={filteredAvtaler} selskapsId={this.props.params.id} />
                    </Tab>
                </Tabs>*/}
                <ContractList contracts={this.props.contracts} company={this.state.selskap} isEntrepreneurAdmin={true} />
            </div>
        );
    }
}

EntrepreneurAdminContracts.defaultProps = {
    processcodeschemes: [],
    avtaler: [],
    selected: {},
    avtalerList: []
};

EntrepreneurAdminContracts.propTypes = {
    contracts: PropTypes.array.isRequired,
    loadEntrepreneurContracts: PropTypes.func.isRequired,
    updateSelskap: PropTypes.func.isRequired,
    processcodeschemes: PropTypes.array.isRequired,
    loadProcesscodeschemes: PropTypes.func.isRequired,
    avtaler: PropTypes.array.isRequired,
    loadAvtalerForEntrepreneurAdmin: PropTypes.func,
    avtalerList: PropTypes.array,
    UpdateAvtale: PropTypes.func.isRequired,
    selected: PropTypes.object.isRequired,
    saveContract: PropTypes.func.isRequired,
    getSelskapsIdForLoggedUser: PropTypes.func,
    loadEntrepreneurById: PropTypes.func,
    selskapId: PropTypes.string,

};

function mapStateToProps(state)
{
    return {
        contracts: state.contracts,
        loading: state.entreprenorer.length === 0,
        processcodeschemes: state.processcodeschemes,
        avtaler: state.contractZones,
        avtalerList: state.avtaler,
        selskapId: state.meldinger ? state.meldinger.selskapId : "",
    };
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        loadUsers, loadEntrepreneurContracts, updateSelskap, UpdateAvtale, getSelskapsIdForLoggedUser,
        loadProcesscodeschemes, saveContract, loadAvtalerForEntrepreneurAdmin, loadEntrepreneurById

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EntrepreneurAdminContracts);

