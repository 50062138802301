import React, { PropTypes } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

const NewMeldingDropdownList = ({ label, title, id, Items, onChange, error, selected }) => {
    const deactivatedMeldingsTyper = [
        '179a7593-0d5a-4d11-b6bf-682578a5e664', // betalingsautomater
        '29382bdf-971e-41c0-91f6-b867283820e0', // betalingsautomater
        'bb54d45b-3534-4583-9ad3-4d24960a7a5a', // betalingsautomater
        '1990e04c-a194-4c04-b920-0c22ab921c2f', // betalingsautomater
        '62d2d550-beee-4818-bd9b-5c8d24f95830', // betalingsautomater
        '2d5f72d8-bf1d-433a-9aaf-4cb17a1af5ce', // betalingsautomater
        '9ba9e22e-90da-4c95-bd14-746109af9c91', // toaletter
        '7fc95d70-43c7-458a-977e-cbdba1e0cb3b', // toaletter
        '149b2f17-cf71-421b-95dc-4b353304c162', // toaletter
        'ff8c7673-d2a8-4257-bfd8-0f2607b0a2da', // Ladestolper
        'e3818f25-0510-4a6e-9826-2588af842d7f', // Ladestolper
        '8686b8c0-b3ae-45da-b2a5-5d4a2f5634bb', // Ladestolper
        'fb948410-b878-45c4-86f9-42ba0d44916e', // Ladestolper
        '93b42d6c-d2cd-48c2-a53e-22ba08c3f0c3', // Ladestolper.
    ];
    const renderOption = (item, index) => {
        const isTypeDeactivated = _.includes(deactivatedMeldingsTyper, item.id);
        if (!isTypeDeactivated) {
            return (
                <option key={index} value={item.id}>
                    {item.navn}
                </option>
            );
        }
        return (
            <option
                disabled
                title="Deaktivert - Må meldes inn via publikumsportal"
                key={index}
                value={item.id}
                style={{ color: '#CCCCCC' }}
            >
                {item.navn}
            </option>
        );
    };
    return (
        <div className={classnames('form-group', { 'has-error': error })}>
            {label && (
                <label className="control-label" htmlFor={id}>
                    {label}
                </label>
            )}
            <select value={selected ? selected.toString() : ''} onChange={onChange} className="form-control" id={id}>
                {title && (
                    <option key="0" value="0">
                        {title}
                    </option>
                )}
                {_.map(Items, renderOption)}
            </select>
            {error && <span className="help-block">{error}</span>}
        </div>
    );
};
NewMeldingDropdownList.defaultProps = {
    id: '',
};

NewMeldingDropdownList.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    Items: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    selected: PropTypes.string,
};
export default NewMeldingDropdownList;
