import validator from "validator";
import isEmpty from "lodash/isEmpty";
import * as validationErrors from "../constants/ValidationErrors"

export default function validateInput(data){
	let errors = {};
	if(validator.isEmpty(data.epost)){
		errors.epost=validationErrors.EmailIsRequired;
	}
	else if(!validator.isEmail(data.epost))
	{
		errors.epost=validationErrors.EmailIsNotValid;
	}	
	if(validator.isEmpty(data.passord)){
		errors.passord=validationErrors.PasswordIsRequired;
	}
	return{
		errors,
		isValid: isEmpty(errors)
	}
}