import React, { PropTypes } from 'react';
import { Row, Col } from 'react-bootstrap';
import EditableField from '../../components/common/EditableField';
import '../../css/Entrepreneur.css';

const EntrepreneurDetailHeader = ({ selskapName, orgNumber, onSubmitName, onSubmitOrgNr, orgnrError, userIsBymadmin }) =>
{
    return (
        <div>
            <div className="detail-header">
                <Row>
                    <Col md={2} id="fixlabelsize">
                        <strong><p>Navn på entreprenør</p></strong>
                    </Col>
                    <Col md={4}>
                        <div>
                            {
                                userIsBymadmin
                                    ?<EditableField inputLength={3} text={selskapName} onSubmit={onSubmitName} id="editablewidth" /> 
                                    :<div>{selskapName}</div>
                            }
                        </div>
                        <br />
                    </Col>
                </Row>
                <div className="gap-4"></div>
                <Row>
                    <Col md={2} id="fixlabelsize">
                        <strong><p>Organisasjonsnr.</p></strong>
                    </Col>
                    <Col md={4}>
                        <div>
                            {
                                userIsBymadmin ? 
                                    <EditableField inputLength={9} maxLength={9} isNumber={true} error={orgnrError}
                                        text={orgNumber}
                                        onSubmit={onSubmitOrgNr}
                                        id="editablewidth" />
                                    : <div> {orgNumber} </div>
                            }
                        </div>
                        <br />
                    </Col>
                </Row>
            </div>
            <div className="gap-50"></div>
        </div>
    );
};
EntrepreneurDetailHeader.propTypes = {
    //selskap: PropTypes.object.isRequired,
    selskapName: PropTypes.string.isRequired,
    orgNumber: PropTypes.string.isRequired,
    onSubmitName: PropTypes.func.isRequired,
    onSubmitOrgNr: PropTypes.func.isRequired,
    orgnrError: PropTypes.string,
    userIsBymadmin: PropTypes.bool
};

export default EntrepreneurDetailHeader;

