import React, {PropTypes} from 'react';
import Spinner from 'react-spinkit';

const Loading = ({noFadeIn}) => {
    return (
        <Spinner className="three-bounce" noFadeIn={noFadeIn}/>
    );
};

Loading.defaultProps  = {
    noFadeIn: false
};

Loading.propTypes = {
    noFadeIn: PropTypes.bool
};

export default Loading;