import React, { PropTypes } from 'react';
import { FormGroup } from 'react-bootstrap';
import Select from 'react-select';
import classnames from "classnames"
import 'react-select/dist/react-select.css';

const GetItems = (items) =>
{
    return items.map(avtale =>
    {
        return {
            value: avtale.id,
            label: avtale.navn
        }
    })
};

const MultiSelect = ({ selectedValues, onChange, Items, error, label, title }) =>
{
    const handleChange = (options) =>
    {
        const selectedOptions = options.map(option => { return { id: option.value, navn: option.label } });
        onChange(selectedOptions);
    };

    return (<FormGroup id="AvtaleSelectDropdownList" className={classnames('AvtaleSelectDropdownList', { 'has-error': error })}>
        <label htmlFor="MultiSelect">{label}</label>
        <Select
            name="MultiSelect"
            placeholder={title}
            multi={true}
            value={GetItems(selectedValues)}
            options={GetItems(Items)}
            onChange={handleChange}
        />
        {error && error.length > 0 && <span className="help-block">{error}</span>}
    </FormGroup>);
};

MultiSelect.propTypes = {
    selectedValues: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    Items: PropTypes.array.isRequired,
    error: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string
};

export default MultiSelect