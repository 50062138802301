import React, { PureComponent, PropTypes } from "react";
import moment from "moment";
import DatePickerEditable from "../../components/common/DatePickerEditable";

class FromToDate extends PureComponent {
  static propTypes = {
    from: PropTypes.string,
    to: PropTypes.string,
    editable: PropTypes.bool,
    id: PropTypes.string,
    onSubmitEditableDateTo: PropTypes.func
  };

  render() {
    const { from, to, editable, id, onSubmitEditableDateTo } = this.props;
    const fromDate = moment(from);
    const toDate = moment(to);
    if (editable) {
      return (
        <span>
          {fromDate.format("DD.MM.YYYY")}-
          <DatePickerEditable
            value={to.toString()}
            showClearButton={false}
            onSubmit={onSubmitEditableDateTo}
            id={id}
          />
        </span>
      );
    } else
      return (
        <span>
          {fromDate.format("DD.MM.YYYY")}-{toDate.format("DD.MM.YYYY")}
        </span>
      );
  }
}

export default FromToDate;
