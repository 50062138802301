import React, {Component, PropTypes} from 'react';
import * as processcodeschemeActions from '../../actions/processcodeschemeActions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Modal, Button, FormGroup, ControlLabel, FormControl} from 'react-bootstrap';

class NewProcessCodeSchemeModalContainer extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            open: this.props.initialChecked,
            name: "",
            initialName: true
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.validateAndSave = this.validateAndSave.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({open: nextProps.initialChecked})
    }

    openModal() {
        this.setState({open: true});
        this.props.callbackParent(true);
    }

    closeModal() {
        this.setState({open: false});
        this.props.callbackParent(false);
    }

    validateAndSave() {
        this.closeModal();
        let values = {
            "name": this.state.name
        };
        this.props.actions.saveProcesscodescheme(values);
    }

    onChange(e) {
        if (e.target.id === "name") {
            return this.setState({name: e.target.value, initialName: false});
        }
    }

    render() {
        return (
            <Modal show={this.state.open} onHide={this.closeModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Opprett nytt prosesskodeskjema</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: "30px 20px 20px 20px"}}>
                    <FormGroup controlId="name" className="required"
                               validationState={(this.state.name === '' && !this.state.initialName) ? "error" : null}>
                        <ControlLabel>Navn</ControlLabel>
                        <FormControl type="text" value={this.state.name} onChange={this.onChange}/>
                        <FormControl.Feedback />
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <div className="pull-left">
                        <Button bsStyle="link" onClick={this.closeModal}>Avbryt</Button>
                        <Button bsStyle="primary" onClick={this.validateAndSave} disabled={!this.state.name}>Opprett
                            nytt prosesskodeskjema</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

NewProcessCodeSchemeModalContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    initialChecked: PropTypes.bool.isRequired,
    callbackParent: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(processcodeschemeActions, dispatch),
    };
}

function mapStateToProps(state) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {withRef: true})(NewProcessCodeSchemeModalContainer);