import React, { Component, PropTypes } from 'react';
import Control from 'react-leaflet-control';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Collapse, Popover } from 'react-bootstrap';
import L from 'leaflet';
import { Map, ZoomControl } from 'react-leaflet';
import _ from 'lodash';
import '../../css/Kart/kart.css';
import $ from 'jquery';
import 'leaflet/dist/leaflet.css';
import * as kartglobals from '../../constants/kartGlobals';
import { formatDateByCommaSepratedDayMonthYear } from '../../utils/utils';

import KartlagControl from './KartlagControl';
import KartlagVelger from './KartlagVelger';
import GrunnkartVelger from './GrunnkartVelger';

import { renderToString } from 'react-dom/server';

import StatusIkon from '../common/StatusIcon';

const ikkeBehandletPin = require('../../images/mapPinIkkeBehandlet.png');
const avvistPin = require('../../images/mapPinAvvist.png');
const underBehandlingPin = require('../../images/mapPinUnderBehandling.png');
const ikkeTildeltPin = require('../../images/mapPinIkkeRutet.png');
const ferdigBehandletPin = require('../../images/mapPinFerdigBehandlet.png');

// Polyfill in order to support IE (react-leaflet-markercluster dependency)
if (!Array.find) {
  require('core-js/fn/string/includes');
}

class LeafletMapWithFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 59.911491,
      lon: 10.757933,
      addressSearch: '',
      collapseFilters: false,
      checkboxes: [],
      grunnkart: kartglobals.basemapOptions.grunnkart,
      statkartAvailable: true,
      bounds: [
        [59.693064, 10.463104],
        [60.135370, 10.987701],
      ],
    };
    this.onChange = this.onChange.bind(this);
    this.onSelectCheckBox = this.onSelectCheckBox.bind(this);
    this.ListDisplay = this.ListDisplay.bind(this);
    this.PopuphandleClick = this.PopuphandleClick.bind(this);
    this.setStatkartAvailable = this.setStatkartAvailable.bind(this);
  }

  componentWillMount() {
    const kartlagInfo = kartglobals.layerInfoUrl;
    $.getJSON(kartlagInfo, res => {
      this.setState({ symboler: res.layers });
    });

    const userPreferences = this.getUserPreferencesForMap();
    if (userPreferences) {
      this.setState({
        grunnkart: userPreferences.basemap,
        checkboxes: userPreferences.layers,
        bounds: userPreferences.bounds,
      });
    } else {
      this.setUserPreferencesForMap();
    }
  }

  componentDidUpdate = prevProps => {
    const { updatedMapBounds } = this.props;
    if(prevProps.updatedMapBounds !== updatedMapBounds && updatedMapBounds.length > 0) {
      this.onBoundsChanged(this.extractMarkers(updatedMapBounds));
    }
  }

  setUserPreferencesForMap = (
    basemap,
    layers,
    updatedBounds
  ) => {
    const { grunnkart, checkboxes, bounds } = this.state;
    localStorage.setItem(
      'userPreferencesForBymFilterMap',
      JSON.stringify({
        basemap: basemap ? basemap : grunnkart,
        layers: layers ? layers : checkboxes,
        bounds: updatedBounds ? updatedBounds : bounds,
      })
    );
  };

  setStatkartAvailable = available => {
    this.setState({statkartAvailable: available});
  }

  onBoundsChanged = bounds => {
    this.setUserPreferencesForMap(null, null, bounds);
    this.setState({
      bounds: bounds,
    });
  };

  getUserPreferencesForMap = () => {
    return JSON.parse(localStorage.getItem('userPreferencesForBymFilterMap'));
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSelectCoord(data) {
    this.setState({ addressSearch: data.display_name });
  }

  ListDisplay(e) {
    this.setState({ collapseFilters: !this.state.collapseFilters });
  }

  PopuphandleClick(id) {
    const url = `${this.props.routeTo}/${id.toString()}`;
    this.context.router.push(url);
  }

  onClickTableRow(meldingId) {
    const routeToDetails = `${this.props.routeTo}/${meldingId}`;
    this.context.router.push(routeToDetails);
  }

  onSelectCheckBox(e) {
    const { target } = e;
    const { checked } = target;
    const { value } = target;
    if (checked) {
      const filter = [...this.state.checkboxes, value];
      this.setState({ checkboxes: filter });
      this.setUserPreferencesForMap(null, filter, null, null);
    } else {
      const filter = this.state.checkboxes.filter(val => val !== value);
      this.setState({ checkboxes: filter });
      this.setUserPreferencesForMap(null, filter, null, null);
    }
  }

  getMeldingStatus(statusId) {
    return renderToString(<StatusIkon statusId={statusId} />);
  }

  getKategori(typeId) {
    let meldingkategori = '';
    const kg = this.props.meldingkatagori;
    _.map(kg, i => {
      if (i.meldingstype.meldingstypeId === typeId) {
        const arr = [];
        _.map(i.meldingskategorier, item => {
          arr.push(item.navn);
        });
        meldingkategori = _.join(arr, ', ');
      }
    });
    return meldingkategori;
  }

  generateMapPinBystatusId = status => {
    if (status === 1) return ikkeTildeltPin;
    if (status === 2) return ikkeBehandletPin;
    if (status === 3) return avvistPin;
    if (status === 4) return underBehandlingPin;
    return ferdigBehandletPin;
  };

  getMarkerPopup(melding) {
    const meldingKatagori = this.getKategori(melding.meldingstypeId);
    const address = melding.adresse;
    const date = formatDateByCommaSepratedDayMonthYear(melding.opprettetTid);
    const id = melding.meldingId;
    const status = this.getMeldingStatus(melding.meldingsStatusId);
    const buttoncontainer = $('<div />');
    buttoncontainer.on('click', '#custom-link', () =>
      this.PopuphandleClick(id)
    );

    buttoncontainer.html(`
            <div class="customPopup">
                <div role="button" id="custom-link">
                    <span><b>${meldingKatagori}</b></span><br/>
                    ${address}<span class="glyphicon glyphicon-menu-right pull-right" id="popupRightPil"></span>               
                    <br/>
                </div>
                <span>${date}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${status}
            </div>
        `);

    return buttoncontainer[0];
  }

  extractMarkers(meldinger) {
    if (_.size(meldinger) > 0) {
      const markers = [];
      _.map(meldinger, m => {
        const status = m.meldingsStatusId ? m.meldingsStatusId : '';
        const customIcon = this.generateMapPinBystatusId(status);
        const markerIcon = L.icon({
          iconUrl: customIcon,
          iconSize: [24, 30],
          iconAnchor: [12, 30],
          popupAnchor: [0, -36],
        });
        const popupBox = this.getMarkerPopup(m);
        markers.push({
          lat: parseFloat(m.latitude),
          lng: parseFloat(m.longitude),
          options: { icon: markerIcon, id: m.meldingId },
          popup: popupBox,
        });
      });
      return markers;
    }
    return [{ lat: -89.0, lng: 89.0, tooltip: 'Fake GPS position' }];
  }

  iconCreateFunction = cluster => {
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: 'marker-cluster-custom',
      iconSize: L.point(40, 40, true),
    });
  };

  renderMarkerCluster() {
    const markerClusterOptions = {
      enableDefaultStyle: true,
      disableDefaultAnimation: true,
      removeDuplicates: true,
      spiderfyDistanceMultiplier: 2,
      iconCreateFunction: cluster => this.iconCreateFunction(cluster),
    };
    const meldinger = this.props.markercluster;
    const markercluster = this.extractMarkers(meldinger);
    return (
      <MarkerClusterGroup
        markers={markercluster}
        options={markerClusterOptions}
        wrapperOptions={{ enableDefaultStyle: true }}
      />
    );
  }

  setGrunnkart = grunnkart => {
    this.setState({
      grunnkart: grunnkart,
    });
    this.setUserPreferencesForMap(grunnkart, null, null, null);
  };

  render() {
    const { updatedMapBounds, markercluster } = this.props;
    const { lat, lon, checkboxes, grunnkart, zoom, bounds, statkartAvailable } = this.state;
    const defaultPosition = [lat, lon];
    return (
      <Map
        center={defaultPosition}
        zoom={zoom || kartglobals.minZoom}
        maxZoom={kartglobals.maxZoom}
        zoomControl={false}
        scrollWheelZoom={false}
        onmoveend={e => {
          const b = e.target.getBounds();
          const box = [
            [b._southWest.lat, b._southWest.lng],
            [b._northEast.lat, b._northEast.lng],
          ];
          this.onBoundsChanged(box);
        }}
        bounds={bounds}
      >
        <KartlagVelger
          position="topright"
          onSelectKartlag={this.onSelectCheckBox}
          ListDisplay={this.ListDisplay}
          collapseFilters={this.state.collapseFilters}
          valgteKartlag={checkboxes}
        />

        <ZoomControl position="topleft" className="zoomControl" />

        <KartlagControl
          position="topleft"
          checkboxes={checkboxes}
          grunnkart={grunnkart}
          setStatkartAvailable={this.setStatkartAvailable}
        />

        <GrunnkartVelger
          position="topleft"
          grunnkartOptions={kartglobals.basemapOptions}
          grunnkart={grunnkart}
          setGrunnkart={this.setGrunnkart}
          statkartAvailable={statkartAvailable}
        />

        {this.renderMarkerCluster()}
      </Map>
    );
  }
}
LeafletMapWithFilters.contextTypes = {
  router: PropTypes.object,
};
LeafletMapWithFilters.propTypes = {
  onSelectCoord: PropTypes.func,
  markercluster: PropTypes.array,
  meldingsstatuser: PropTypes.array,
  meldingkatagori: PropTypes.array,
  routeTo: PropTypes.string,
  updatedMapBounds: PropTypes.array,
};
export default LeafletMapWithFilters;
