import React, { Component, PropTypes } from 'react'
import { Link } from "react-router"
import { Panel, Row, Col } from 'react-bootstrap'
import TextFieldGroup from "../../components/common/TextFieldGroup"
import validateInput from "../../validations/UpdateForgotPasswordValidator"
import { UpdatePasswordFailure } from "../../constants/ResponseMessages"
import validator from "validator";
import { home, UpdatePasswordConfirmed } from "../../constants/clientRoutes"

class ResetPassword extends Component {

    static propTypes = {
        updateForgotPasswordRequest: PropTypes.func.isRequired,
        addFlashMessage: PropTypes.func.isRequired
    }

    static contextTypes = {
        router: PropTypes.object.isRequired
    }

    state = {
        key: "",
        nyttPassord: '',
        gjentanyttPassord: '',
        isLoading: false,
        errors: {},
        invalid: false,
    }

    componentWillMount() {
        const guidQuery = this.context.router.params
        const guid = guidQuery.id;
        if (guid && validator.isUUID(guid, 4)) {
            this.setState({ key: guid });
        }
        else {
            return this.context.router.push(home);
        }
    }

    isValid() {
        const { errors, isValid } = validateInput(this.state);
        if (!isValid) {
            this.setState({ errors });
        }
        return isValid;
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (this.isValid()) {
            this.setState({ errors: {} });
            this.props.updateForgotPasswordRequest(this.state)
                .then(() => {
                    this.context.router.push(UpdatePasswordConfirmed);
                })
                .catch(() => this.props.addFlashMessage({ type: 'error', text: UpdatePasswordFailure }));
        }
    }

    render() {
        const { errors, nyttPassord, gjentanyttPassord } = this.state;

        return (
            <div className="Login_center_div" id="LoginDiv">
                <Panel>
                    <Row>
                        <Col md={4} mdOffset={4}>
                            <form onSubmit={this.onSubmit} className="col-xs-12">
                                <TextFieldGroup name="nyttPassord" label="Nytt passord" type="password" autofocus={true}
                                    onChange={this.onChange} value={nyttPassord} error={errors.nyttPassord} />
                                <TextFieldGroup name="gjentanyttPassord" label="Gjenta nytt passord" type="password"
                                    onChange={this.onChange} value={gjentanyttPassord} error={errors.gjentanyttPassord} />
                                <div className="form-group col-xs-10">
                                    <button className="btn btn-success pull-right"> Send </button>
                                    <Link to={home} className="btn btn-link pull-right"> Avbryt </Link>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Panel>
            </div>
        );
    }
}

export default ResetPassword