import React, { PropTypes } from "react"
import classnames from "classnames"
import "../../css/common/textArea.css"

const TextArea = ({ label, name, placeHolder, value, rows, cols, id, error, onChange, onBlur }) =>
{
    return (
        <div className={classnames('form-group', { 'has-error': error })}>
            <label className="control-label" htmlFor={name}>{label}</label>
            <textarea className="form-control"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                placeholder={placeHolder}
                rows={rows}
                cols={cols}
                id={id}
            />
            {error && <span className="help-block">{error}</span>}
        </div>
    );
}
TextArea.defaultProps = {
    cols: '50',
    rows: '8'
}

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    placeHolder: PropTypes.string,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.string,
    rows: PropTypes.string,
    cols: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func
}

export default TextArea