import axios from 'axios';
import FileSaver from 'file-saver';
import * as api from '../constants/api';
import { successData } from '../utils/utils';
import { addFlashMessage } from './FlashMessagesAction';
import * as responseMessages from '../constants/ResponseMessages';

export function loadMeldingerForPdf(include) {
  let url = api.meldingerForPdfUrl(include);
  return dispatch => {
    return axios
      .get(url)
      .then(res => {
        let meldinger = successData(res);
        return meldinger;
      })
      .catch(err => {
        if (err)
          dispatch(
            addFlashMessage({
              type: 'error',
              text: responseMessages.PdfFailure,
            })
          );
      });
  };
}

export function genererPdf(meldingIds, filnavn) {
  const url = api.bymeldingPdfExportUrl(meldingIds);
  return dispatch => {
    return axios
      .get(url, {
        responseType: 'blob',
      })
      .then(res => {
        const file = new Blob([res.data], { type: 'application/pdf' });
        FileSaver.saveAs(file, filnavn);
        return true;
      })
      .catch(err => {
        if (err) {
          dispatch(
            addFlashMessage({
              type: 'error',
              text: responseMessages.PdfFailure,
            })
          );
        }
      });
  };
}
