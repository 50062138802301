import React, { PropTypes } from "react"
import { Modal } from "react-bootstrap"
import TextArea from "../common/TextArea"


const SendTilbakeMeldingModal = ({ showModal, modalTitle, tilbakeMeldingText, id, onShow, onChange, onSubmit, error }) =>
{
  return (
    <Modal show={showModal} onHide={onShow}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <TextArea name="tekst" label="Send en tilbakemelding til innmelder" id={id} value={tilbakeMeldingText}
            onChange={onChange} error={error} rows="15"
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onShow} className="btn btn-link">Avbryt</button>
        <button onClick={onSubmit} className="btn btn-primary">Send tilbakemelding</button>
      </Modal.Footer>
    </Modal>
  )
}

SendTilbakeMeldingModal.propTypes = {
  showModal: PropTypes.bool,
  tilbakeMeldingText: PropTypes.string,
  modalTitle: PropTypes.string,
  id: PropTypes.string,
  onSubmit: PropTypes.func,
  onShow: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.string
}
export default SendTilbakeMeldingModal