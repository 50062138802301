import React, { PropTypes } from 'react';
//import LinkSetSubtitle from '../common/LinkSetSubtitle';
import AnchorLink from '../common/AnchorLink'
import * as routes from '../../constants/clientRoutes';

const ProcesscodeschemeListRow = ({ processcodescheme }) =>
{
  return (
    <tr>
      <td>
        {/*<LinkSetSubtitle to={routes.prosessKodeSkjema + '/' + processcodescheme.id}>{processcodescheme.name}</LinkSetSubtitle>*/}
        <AnchorLink to={routes.prosessKodeSkjema + '/' + processcodescheme.id} pageTitle={processcodescheme.name} pageLink={routes.prosessKodeSkjema}
          children={processcodescheme.name} linkLabel={processcodescheme.name} />
      </td>
    </tr>
  );
};

ProcesscodeschemeListRow.propTypes = {
  processcodescheme: PropTypes.object.isRequired
};

export default ProcesscodeschemeListRow;
