import React, { PropTypes } from 'react';
import { Button } from 'react-bootstrap';
import EditableField from '../common/EditableField';
//import TextFieldGroupDisabled from "../common/TextFieldGroupDisabled"
import Confirm from "react-confirm-bootstrap"
import * as ResponseMessages from "../../constants/ResponseMessages"
import * as validationErrors from "../../constants/ValidationErrors"


import '../../css/Entrepreneur.css';

const PriceSchemeListRow = ({ rad, onClick, onSubmit }) => {
    return (
        <tr className={rad.updatedPrice ? 'bg-success' : ''}>
            <td>{rad.prosesskode}</td>
            <td>{rad.beskrivelse}</td>
            <td>{rad.enhet}</td>

            <td>
                <center>kr</center>
            </td>

            <td>
                <div>
                    <EditableField isNumber required error={validationErrors.PrisPositivInteger} text={rad.pris.toLocaleString('nb-NO', {minimumFractionDigits: 2, maximumFractionDigits: 2})} onSubmit={(e) => onSubmit(e, rad)} />
                </div>
            </td>

            <td>
                <Confirm
                    confirmBSStyle="primary"
                    cancelText="Avbryt"
                    onConfirm={() => onClick(rad)}
                    body={`${ResponseMessages.kontraktRemoveQuestionText}"${rad.prosesskode}" ?`}
                    confirmText="Fjern"
                    title={ResponseMessages.kontraktRemovekontrakt}>
                    <Button className="danger" bsStyle="link">Fjern</Button>
                </Confirm>
            </td>
        </tr>
    );
};

PriceSchemeListRow.propTypes = {
    rad: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,

};

export default PriceSchemeListRow;