import React, { PropTypes } from 'react';
import classnames from "classnames";

const Checkbox = ({ title, id, name, value, onChange, error, checked, tooltip }) => {
    return (
        <div className={classnames('', { 'has-error': error })} id={id}>
            <label>
                <input title={ tooltip } type="checkbox" name={name} value={value} checked={checked} onChange={onChange} />
                <span title={ tooltip }>{title}</span>
            </label>
            {error && <span className="help-block">{error}</span>}
        </div>
    )
}
Checkbox.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.string,
    tooltip: PropTypes.string,
}
export default Checkbox