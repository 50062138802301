import { GetBymeldingServiceHealthCheckUrl, GetAutentiseringServiceHealthCheckUrl } from "../constants/api"
import { AUTENTISERING_SERVICE_HEALTH_CHECK, BYMELDING_SERVICE_HEALTH_CHECK } from "../actions/ActionTypes";
import axios from "axios"


const AutentiseringServiceHealthCheckStatus = (status) => ({ type: AUTENTISERING_SERVICE_HEALTH_CHECK, status })
const BymeldingServiceHealthCheckStatus = (status) => ({ type: BYMELDING_SERVICE_HEALTH_CHECK, status })

export function GetAutentiseringServiceinfoTilstand()
{
    const autentiserignServiceUrl = GetAutentiseringServiceHealthCheckUrl
    return dispatch =>
    {
        return axios.get(autentiserignServiceUrl)
            .then(res =>
            {
                dispatch(AutentiseringServiceHealthCheckStatus(res.status))
            })
            .catch(() =>
            {
                dispatch(AutentiseringServiceHealthCheckStatus(500))
            })
    }
}

export function GetBymeldingServiceinfoTilstand()
{
    const bymeldingServiceUrl = GetBymeldingServiceHealthCheckUrl
    return dispatch =>
    {
        return axios.get(bymeldingServiceUrl)
            .then(res =>
            {
                dispatch(BymeldingServiceHealthCheckStatus(res.status))
            })
            .catch(() =>
            {
                dispatch(BymeldingServiceHealthCheckStatus(500))
            })
    }
}
