import React, { PropTypes } from 'react';
import { FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import AvtaleSelectDropdownList from './../AvtaleSelectDropdownList';
import _ from "lodash"
import { avtaleIsRequired } from "../../../constants/ValidationErrors"
import '../../../css/user/EditUserForm.css';
import { selskapsTypeEntreprenorerId } from '../../../constants/Globals'

class EditUserForm extends React.Component
{
    constructor(props, context)
    {
        super(props, context);

        this.cancelEdit = this.cancelEdit.bind(this);
        this.saveAndClose = this.saveAndClose.bind(this);
        this.roleHasChanged = this.roleHasChanged.bind(this);
        this.kontraktSoneHasChanged = this.kontraktSoneHasChanged.bind(this);
        this.hasavtaler = this.hasavtaler.bind(this);

        this.state = {
            rolle: this.props.user.selskapsRolle,
            avtaler: [],
            errors: {}
        }
    }
    componentWillMount()
    {
        const avtaler = this.renderAvtalerForUser()
        this.setState({ avtaler: avtaler })
    }

    cancelEdit()
    {
        this.props.onCancel();
        const avtaler = this.renderAvtalerForUser()
        
        //this.setState({ rolledId: this.props.user.selskapsRolle.rolleId })
        this.setState({ rolle: this.props.user.selskapsRolle , avtaler:avtaler })
        
    }

    isValid()
    {
        const avtaler = this.state.avtaler
        const errors = {}
        let validert = true
        if (this.hasavtaler() && avtaler.length === 0)
        {
            errors.selectedAvtaler = avtaleIsRequired
            this.setState({ errors })
            validert = false
        }
        return validert
    }
    saveAndClose(event)
    {
        event.preventDefault();
        let includeavtaler = this.hasavtaler() && this.state.avtaler && this.state.avtaler.length;
        if (this.isValid())
        {
            this.props.onSave({
                ...this.props.user,
                selskapsRolle: this.state.rolle,
                avtaler: includeavtaler > 0 ? this.state.avtaler : []
            });
            this.setState({ errors: {} })
        }
    }

    roleHasChanged(event)
    {
        this.setState({ rolle: this.props.roles.find(x => x.rolleId === event.target.value) });
    }

    hasavtaler()
    {
        return this.props.selskapsType === selskapsTypeEntreprenorerId;
    }
    renderAvtalerForUser()
    {
        const avtaler = this.props.user.avtaler
        return _.map(avtaler, i =>
        {
            return Object.assign({}, { id: i.avtaleId, navn: i.navn })
        })
    }
    renderAvtalerForselskap()
    {
        const avtaler = this.props.avtaler
        return _.map(avtaler, i =>
        {
            return Object.assign({}, { id: i.id, navn: i.navn })
        })
    }
    kontraktSoneHasChanged(values)
    {
        this.setState({ avtaler: values });
    }

    render()
    {
        const { user, roles } = { ...this.props };
        const { errors, avtaler } = this.state
        const avtalerForSelskap = this.renderAvtalerForselskap()
        const selectedAvtaler = avtaler
        const roleSelect = (<FormGroup controlId="roleSelect">
            <ControlLabel>Rolle</ControlLabel>
            <FormControl componentClass="select" placeholder="Velg Rolle"
                onChange={this.roleHasChanged}
                value={this.state.rolle.rolleId}>
                {roles.map((role) =>
                {
                    return (<option key={role.rolleId} value={role.rolleId}>{role.navn}</option>);
                })}
            </FormControl>
        </FormGroup>);

        return (
            <form key={user.id} onSubmit={this.saveAndClose} className="editUserForm">
                <FormGroup>
                    <ControlLabel>
                        E-post
                    </ControlLabel>
                    <FormControl.Static>
                        <a href={"mailto:" + user.epost}>{user.epost}</a>
                    </FormControl.Static>
                </FormGroup>
                {roleSelect}
                {this.hasavtaler() && <AvtaleSelectDropdownList selectedValues={selectedAvtaler}
                    avtaler={avtalerForSelskap}
                    onChange={this.kontraktSoneHasChanged}
                    error={errors.selectedAvtaler}
                />}
                <div className="pull-left formButtons">
                    <Button bsStyle="link" onClick={this.cancelEdit}>Avbryt
                    </Button>
                    <Button bsStyle="primary" type="submit" id="editUserSubmit">
                        Lagre
                    </Button>
                </div>
            </form>
        )
    }
}

EditUserForm.propTypes = {
    user: PropTypes.object.isRequired,
    roles: PropTypes.array,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    avtaler: PropTypes.array,
    selskapsType: PropTypes.number.isRequired
};

export default EditUserForm;