import React, { PropTypes } from 'react';
import { Link } from 'react-router';

const LinkSetSubtitle = ({ to, pageTitle, pageLink, children, className }) =>
{
    return (
        <Link className={className} to={{ pathname: to, state: { subTitle: children, pageTitle: pageTitle, pageLink: pageLink } }}>{pageTitle}</Link>
    );
};

LinkSetSubtitle.propTypes = {
    to: PropTypes.string.isRequired,
    pageLink: PropTypes.string,
    children: PropTypes.node.isRequired,
    pageTitle: PropTypes.string,
    className: PropTypes.string,
};

export default LinkSetSubtitle;