import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory, Router } from 'react-router';
import 'bootstrap/dist/css/bootstrap.css';
import './css/bootstrap-theme-bym.css';
import axios from 'axios';
import { getRoutes } from './routes';
import store from './store/store';
import './css/index.css';
import { GetAuthenticationServiceInfo } from './actions/LoginActions';
import { UserIsServicedeskAdmin, UserIsServicedesk, UserIsBymAdmin } from './utils/cookieHandler';
import { loadEntrepreneurs, loadSaksbehandlere } from './actions/selskaperActions';

axios.defaults.headers['X-Bymelding-Client'] = 'BymeldingClient_1.0:1.0';
store.dispatch(GetAuthenticationServiceInfo()).then(() => {
    store.dispatch(loadEntrepreneurs());
    if (UserIsServicedeskAdmin() || UserIsServicedesk() || UserIsBymAdmin()) {
        store.dispatch(loadSaksbehandlere());
    }
});

const app = document.getElementById('app');
render(
    <Provider store={store}>
        <Router history={browserHistory}>{getRoutes(store)}</Router>
    </Provider>,
    app
);
