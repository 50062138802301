import React, { PropTypes } from "react"
import DatePicker from '../common/NorwegianDatepicker'

import './FilterDatepicker.css'

const FilterDatepicker = ({name,style,value,id,placeholder,onChange, showClearButton, ...rest}) =>
{
    return (
        <DatePicker 
            id={id} 
            dateFormat='DD.MM.YYYY' 
            value={value} 
            name={name} 
            placeholder={placeholder}
            onChange={onChange} 
            style={style}
            showClearButton={showClearButton}
            className="filter-datepicker"
            {...rest}
        />
    )
}
FilterDatepicker.defaultProps={
    showClearButton:false
}
FilterDatepicker.propTypes={
    value:PropTypes.string,
    style:PropTypes.object,
    id:PropTypes.string,
    name:PropTypes.string,
    placeholder:PropTypes.string,
    onChange:PropTypes.func.isRequired,
    showClearButton:PropTypes.bool,
}
export default FilterDatepicker