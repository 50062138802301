import React, { Component, PropTypes } from "react"
import { Modal, Button, Tabs, Tab } from "react-bootstrap"
import DropdownList from "../common/DropDownList"
import Datepicker from "../common/Datepicker"
import { Years, Months, Days, getDay, getMonth, getYear } from "../../utils/dateTimeHelpers"
import { toNorwegianDatetime, AddOnMonthToDatetime } from "../../utils/datepickerHelper"
import { selskapHasNoContracts } from "../../constants/ValidationErrors"
import { validateInput } from "../../validations/RapportValidator"
import * as ExcelHandler from "../../utils/ExcelHandler"
import "../../css/rapport.css"
import Spinner from "react-spinkit";
import * as cookieHandler from "../../utils/cookieHandler"

class RapportModalForVinter extends Component {
    state = {
        showModal: false,
        selectedTab: 1,
        FraDato: '',
        TilDato: '',
        selectedSelskap: '0',
        selectedKontrakt: '0',
        selectedMonth: getMonth(),
        selectedYear: getYear(),
        selectedDay: getDay(),
        defualtDay: 1, //converting moment timezone to iso reducers day by one, so day become firstday.
        errors: {},
        generererRapport: false
    }
    static propTypes = {
        selskapId: PropTypes.string,
        selskaper: PropTypes.array,
        kontrakter: PropTypes.array,
        loadCompanySpecificContracts: PropTypes.func,
        createRapport: PropTypes.func.isRequired,
        buttonText: PropTypes.string.isRequired,
        headerText: PropTypes.string.isRequired,
        filename: PropTypes.string,
        fileFormat: PropTypes.string,
        showModal: PropTypes.bool,
        showDays: PropTypes.bool,
        dateRange: PropTypes.bool,
        useOld: PropTypes.bool,
    }


    cleanUpState = () => {
        this.setState({
            selectedMonth: getMonth(), selectedYear: getYear(), selectedDay: getDay(), FraDato: '', TilDato: '',
            selectedSelskap: '0', selectedKontrakt: '0', errors: {}, generererRapport: false
        })
    }
    openModal = () => {
        this.setState({ showModal: true })
    }
    closeModal = () => {
        this.setState({ showModal: false })
        this.cleanUpState()
    }
    removeError = (felt, value) => {
        const errors = this.state.errors
        if (value && value !== "0") {
            delete errors[felt]
            this.setState({ errors })
        }
    }
    onSelectSelskap = (e) => {
        const target = e.target
        const value = target.value
        this.setState({ selectedSelskap: value })
        if (value !== "0") this.props.loadCompanySpecificContracts(value).then(res => {
            this.checkSelskapHasKontrakts(res)
        })
        this.removeError("selectedSelskap", value)
    }
    onSelectKontrakt = (e) => {
        const target = e.target
        const value = target.value
        this.setState({ selectedKontrakt: value })
        this.removeError("selectedKontrakt", value)
    }
    onSelecDropDownList = (e, key) => {
        const target = e.target
        const value = target.value
        this.setState({ [key]: value })
        this.removeError(key, value)
    }
    onChangeFraDato = (value, formattedValue) => {
        this.setState({ FraDato: value })
    }
    onChangeTilDato = (value, formattedValue) => {
        this.setState({ TilDato: value })
    }
    onTabsSelect = (key) => {
        this.setState({ selectedTab: key })
        this.cleanUpState()
    }
    checkSelskapHasKontrakts = (kontrakter) => {
        const { selectedSelskap, errors } = this.state
        if (selectedSelskap !== "0" && kontrakter.length === 0) errors.selectedSelskap = selskapHasNoContracts
        else errors.selectedSelskap = ""
        this.setState({ errors })
    }
    IsValid = () => {
        if (!this.props.selskaper || this.props.selskaper.length === 0) delete this.state.selectedSelskap
        const { isValid, errors } = validateInput(this.state)
        if (!isValid) {
            this.setState({ errors })
        }
        return isValid
    }

    generateEndMonthDate = (state) => {
        const year = parseInt(state.selectedYear, 10)
        const month = parseInt(state.selectedMonth, 10) - 1
        const day = parseInt(state.defualtDay, 10)
        const nextMonth = AddOnMonthToDatetime(year, month, day)
        return nextMonth
    }
    generateDateFra = (state) => {
        const year = parseInt(state.selectedYear, 10)
        const month = parseInt(state.selectedMonth, 10) - 1
        let day = 0
        if (state.selectedTab === 1) {
            day = parseInt(state.selectedDay, 10)
            return toNorwegianDatetime(year, month, day).toISOString()
        }
        else if (state.selectedTab === 2) {
            day = parseInt(state.defualtDay, 10)
            return toNorwegianDatetime(year, month, day).toISOString()
        }
        else if (state.selectedTab === 3) {
            return this.state.FraDato
        }
    }
    generateDateTo = (state) => {
        const year = parseInt(state.selectedYear, 10)
        const month = parseInt(state.selectedMonth, 10) - 1
        let day = 0
        if (state.selectedTab === 1) {
            day = parseInt(state.selectedDay, 10)
            return toNorwegianDatetime(year, month, day).add(1, 'days').toISOString()
        }
        else if (state.selectedTab === 2) {
            return this.generateEndMonthDate(state).toISOString()
        }
        else if (state.selectedTab === 3) {
            return this.state.TilDato
        }

    }
    convertDateToNorwegianDate = (value) => {
        let date = toNorwegianDatetime(value);
        const isoString = date.toISOString();
        return isoString
    }

    onsubimtRapport = () => {
        console.log("payload")
        if (this.IsValid()) {
            const { selectedMonth, selectedYear, selectedKontrakt, selectedSelskap } = this.state
            const fromDate = this.generateDateFra(this.state)
            const toDate = this.generateDateTo(this.state)
            // console.log(fromDate)
            // console.log(toDate)
            const payload = Object.assign({}, { selskapId: selectedSelskap, kontraktId: selectedKontrakt, FraDato: fromDate, TilDato: toDate, month: selectedMonth, year: selectedYear })

            this.generateRapport(payload)
        }
    }
    generateRapport = (payload) => {
        const { fileFormat, createRapport, filename, selskapId, useOld } = this.props
        if (selskapId) payload['selskapId'] = selskapId
        const extension = fileFormat ? fileFormat : 'csv'
        const filnavnForRapport = ExcelHandler.createFileName(filename, payload.month, payload.year, extension)
        this.setState({ generererRapport: true })
        createRapport(payload, useOld).then(res => {
            const motattFilnavn = ExcelHandler.filnavnFraResponse(res);
            (extension === 'csv') ? ExcelHandler.downloadCSVRapport(res, filnavnForRapport) : ExcelHandler.downloadExcelRapport(res, motattFilnavn ? motattFilnavn : filnavnForRapport)
            if (res) {
                this.cleanUpState()
                this.closeModal()
            }
            else this.cleanUpState()
        })
    }
    render() {
        const { showModal, errors, selectedMonth, selectedYear, selectedDay, FraDato, TilDato, selectedKontrakt, selectedSelskap, generererRapport } = this.state
        const { buttonText, headerText, selskaper, kontrakter } = this.props
        const monthList = Months()
        const yearsList = Years()
        const days = Days(selectedYear, selectedMonth)
        return (
            <div>
                {<button className="btn btn-primary" id="rapport-modal-button" onClick={this.openModal}>{buttonText}</button>}

                <Modal show={showModal && generererRapport} onHide={this.closeModal}>
                    <Modal.Body>
                        <Modal.Title> {"Lager rapport..."} </Modal.Title>
                        <center>
                            <Spinner spinnerName="circle" noFadeIn={true} style={{ width: '75px', height: '75px' }} />
                        </center>
                    </Modal.Body>
                </Modal>

                <Modal show={showModal && !generererRapport} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title> {headerText} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="rapportForm col-xs-12">
                            <Tabs defaultActiveKey={1} id="vinterrapportTabs" onSelect={this.onTabsSelect} >
                                <Tab eventKey={1} title="Dagsrapport">
                                    <div className="form-group">
                                        <br /> <span>Velg hvilken dag du skal lage rapport for.</span>
                                    </div>
                                    <div className="col-xs-3">
                                        <DropdownList label="År" Items={yearsList} selected={selectedYear} title="Velg år"
                                            name="selectedYear" onChange={(e) => this.onSelecDropDownList(e, "selectedYear")} id="Dropdownlist" error={errors.selectedYear} />
                                    </div>
                                    <div className="col-xs-3">
                                        <DropdownList label="Måned" Items={monthList} selected={selectedMonth} title="Velg måned"
                                            name="selectedMonth" onChange={(e) => this.onSelecDropDownList(e, "selectedMonth")} id="Dropdownlist" error={errors.selectedMonth} />
                                    </div>
                                    <div className="col-xs-3">
                                        <DropdownList label="Dag" Items={days} selected={selectedDay} title="Velg day"
                                            name="selectedDay" onChange={(e) => this.onSelecDropDownList(e, "selectedDay")} id="Dropdownlist" error={errors.selectedDay} />
                                    </div>
                                    {(selskaper && selskaper.length > 0) && <div className="col-xs-5">
                                        <DropdownList label="Entreprenører" Items={selskaper} selected={selectedSelskap} title="Velg entreprenør"
                                            name="selectedSelskap" onChange={this.onSelectSelskap} id="Dropdownlist entrepreneur" error={errors.selectedSelskap} />
                                    </div>
                                    }
                                    {(kontrakter && kontrakter.length > 0) && <div className="col-xs-5">
                                        <DropdownList label="Kontrakter" Items={kontrakter} selected={selectedKontrakt} title="Velg kontrakt"
                                            name="selectedKontrakt" onChange={this.onSelectKontrakt} id="Dropdownlist kontrakter" error={errors.selectedKontrakt} />
                                    </div>
                                    }
                                </Tab>
                                <Tab eventKey={2} title="Månedsrapport">
                                    <div className="form-group">
                                        <br /> <span>Velg hvilken måned du skal lage rapport for.</span>
                                    </div>
                                    <div className="col-xs-3">
                                        <DropdownList label="År" Items={yearsList} selected={selectedYear} title="Velg år"
                                            name="selectedYear" onChange={(e) => this.onSelecDropDownList(e, "selectedYear")} id="Dropdownlist" error={errors.selectedYear} />
                                    </div>
                                    <div className="col-xs-3">
                                        <DropdownList label="Måned" Items={monthList} selected={selectedMonth} title="Velg måned"
                                            name="selectedMonth" onChange={(e) => this.onSelecDropDownList(e, "selectedMonth")} id="Dropdownlist" error={errors.selectedMonth} />
                                    </div>
                                    <div className="clearfix"></div>
                                    {(selskaper && selskaper.length > 0) && <div className="col-xs-5">
                                        <DropdownList label="Entreprenører" Items={selskaper} selected={selectedSelskap} title="Velg entreprenør"
                                            name="selectedSelskap" onChange={this.onSelectSelskap} id="Dropdownlist entrepreneur" error={errors.selectedSelskap} />
                                    </div>
                                    }
                                    {(kontrakter && kontrakter.length > 0) && <div className="col-xs-5">
                                        <DropdownList label="Kontrakter" Items={kontrakter} selected={selectedKontrakt} title="Velg kontrakt"
                                            name="selectedKontrakt" onChange={this.onSelectKontrakt} id="Dropdownlist kontrakter" error={errors.selectedKontrakt} />
                                    </div>
                                    }
                                </Tab>
                                <Tab eventKey={3} title="Perioderapport">
                                    <div className="form-group">
                                        <br /><span>Velg hvilken datorange du skal lage rapport for</span>
                                    </div>
                                    <div className="col-xs-4">
                                        <Datepicker name="FraDato" label="FraDato" value={FraDato} onChange={this.onChangeFraDato} error={errors.FraDato} />
                                    </div>
                                    <div className="col-xs-4">
                                        <Datepicker name="TilDato" label="TilDato" value={TilDato} onChange={this.onChangeTilDato} error={errors.TilDato} />
                                    </div>
                                    <div className="clearfix"></div>

                                    {(cookieHandler.UserIsBymAdmin() && selskaper && selskaper.length > 0) && <div className="col-xs-5">
                                        <DropdownList label="Entreprenører" Items={selskaper} selected={selectedSelskap} title="Velg entreprenør"
                                            name="selectedSelskap" onChange={this.onSelectSelskap} id="Dropdownlist entrepreneur" error={errors.selectedSelskap} />
                                    </div>
                                    }
                                    {(kontrakter && kontrakter.length > 0) && <div className="col-xs-5">
                                        <DropdownList label="Kontrakter" Items={kontrakter} selected={selectedKontrakt} title="Velg kontrakt"
                                            name="selectedKontrakt" onChange={this.onSelectKontrakt} id="Dropdownlist kontrakter" error={errors.selectedKontrakt} />
                                    </div>
                                    }
                                </Tab>
                            </Tabs>
                        </div>
                        <div className="clearfix"></div>
                        <br />
                        <br />
                        <Modal.Footer>
                            <Button className="pull-left" bsStyle="link" onClick={this.closeModal} id="buttonLink">Avbryt</Button>
                            <Button className="pull-left" bsStyle="primary" bsSize="large" onClick={this.onsubimtRapport}>Lag rapport</Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal >
            </div >
        )
    }
}
export default RapportModalForVinter