import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import axios from 'axios';
import { Glyphicon } from 'react-bootstrap';
import Gallery from './Gallery';

import _ from 'lodash';
import { dokumentasjonUrl } from '../../constants/api';

const documentFormats = ['application/pdf'];
class DokumentasjonLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      documents: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.loadImages();
  }

  componentDidUpdate(prevProps) {
    const { numberOfImgs } = this.props;
    if (prevProps.numberOfImgs !== numberOfImgs) {
      this.loadImages();
    }
  }

  loadImages() {
    const { meldingId } = this.props;

    this.setState({ loading: true });
    axios
      .get(dokumentasjonUrl(meldingId))
      .then(res => {
        const imgs = [];
        const docs = [];
        res.data.result.map(d => {
          if (documentFormats.includes(d.contentType)) {
            docs.push({
              downloadLink: `data:application/pdf;base64, ${d.base64Dokumentasjon}`,
              ...d,
            });
          } else {
            imgs.push({
              src: `data:image/png;base64, ${d.base64Dokumentasjon}`,
              alt: d.filNavn,
              id: d.dokumentasjonId,
              dateTimeTaken: d.dateTimeTaken,
              orientationDegrees: d.orientationDegrees,
            });
          }
        });
        this.setState({
          images: imgs,
          documents: docs,
          loading: false,
        });
      })

      .catch(error => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { images, documents, loading } = this.state;
    return (
      <div>
        {images.length > 0 && <h5>Bilder</h5>}
        <Gallery images={images} id="dokumentasjon-utfoert-arbeid" />
        {documents.length > 0 && (
          <div>
            <h5>Dokumenter</h5>
            <ul id="dokumentasjon-utfoert-arbeid-dokumenter">
              {documents.map(d => (
                <li className="utfoert-arbeid-dokument">
                  <div
                    className="utfoert-arbeid-dokumenter-dokumentinfo"
                    title={d.filNavn}
                  >
                    <Glyphicon
                      glyph="file"
                      className="utfoert-arbeid-dokumenter-dokumentikon"
                    />
                    <span
                      title={d.filNavn}
                      className="utfoert-arbeid-dokumenter-dokumentnavn"
                    >
                      {d.filNavn}
                    </span>
                  </div>
                  <a
                    className="btn btn-primary utfoert-arbeid-dokumenter-download"
                    target="_"
                    href={d.downloadLink}
                    download={d.filNavn}
                  >
                    <Glyphicon
                      glyph="download"
                      className="utfoert-arbeid-dokumenter-download"
                    />
                    <span>Last ned</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {loading && (
          <div>
            <Spinner className="three-bounce" />
          </div>
        )}
      </div>
    );
  }
}

DokumentasjonLoader.propTypes = {
  meldingId: PropTypes.string.isRequired,
  numberOfImgs: PropTypes.number.isRequired,
};

export default DokumentasjonLoader;
