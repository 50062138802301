import _ from 'lodash';
import ReactDOM from 'react-dom';
import React, { PropTypes, Component } from 'react';
import { InputGroup, Button, Glyphicon, ControlLabel, FormGroup, FormControl } from 'react-bootstrap';
import classnames from 'classnames';
import './DropDownListEditable.css';

class DropDownListEditableNoRootClose extends Component {
    static propTypes = {
        id: PropTypes.string,
        title: PropTypes.string,
        label: PropTypes.string,
        error: PropTypes.string,
        Items: PropTypes.array.isRequired,
        selected: PropTypes.string,
        onSubmit: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onClose: PropTypes.func,
        withoutButtons: PropTypes.bool,
    };

    static defaultProps = {
        withoutButtons: false,
    };

    state = {
        label: this.props.label,
        title: this.props.title,
        id: this.props.id,
        Items: this.props.Items,
        error: this.props.error,
        selected: this.props.selected,
        editing: false,
    };

    componentWillReceiveProps(props) {
        if (this.state.selected !== props.selected) this.setState({ selected: props.selected });
    }

    onChange = e => {
        this.setState({ selected: e.target.value, editing: true });
        if (this.props.onChange) this.props.onChange(e);
    };

    clickToEdit = () => {
        if (!this.state.editing) {
            this.setState({ error: '' });
        }
    };

    cancel = () => {
        if (this.props.onClose) this.props.onClose();
        if (this.state.editing) {
            this.setState({
                editing: false,
                selected: this.props.selected,
                error: '',
            });
        }
    };

    submit = () => {
        this.setState({ error: '', editing: false });
        if (this.props.onSubmit) this.props.onSubmit(this.state.selected);
    };

    focus = () => {
        ReactDOM.findDOMNode(this.input).focus();
        this.setState({ editing: true, error: '', ignoreCancelOnce: true });
    };

    renderOption = (item, index) => {
        if (!item.deaktivert) {
            return (
                <option key={index} value={item.id}>
                    {item.navn}
                </option>
            );
        }
        return (
            <option disabled title={item.deaktivertBeskrivelse ?? "Deaktivert"} key={index} value={item.id} style={{ color: '#CCCCCC' }}>
                {item.navn}
            </option>
        );
    };

    render() {
        const { label, title, id, Items, error: errorProps, withoutButtons } = this.props;
        const { editing, selected, error: errorState } = this.state;

        const error = editing ? errorState : errorProps;
        return (
            <FormGroup controlId={id} className="dropdown-list-editable" validationState={error ? 'error' : null}>
                {label && <ControlLabel>{label}</ControlLabel>}
                <InputGroup>
                    <FormControl
                        id={id}
                        className={classnames({
                            normal: !editing || withoutButtons,
                            editing: editing && !withoutButtons,
                        })}
                        componentClass="select"
                        value={selected || ''}
                        onChange={this.onChange}
                        ref={r => (this.input = r)}
                        onClick={this.clickToEdit}
                    >
                        {title && (
                            <option key="0" value="0">
                                {title}
                            </option>
                        )}

                        {_.map(Items, this.renderOption)}
                    </FormControl>
                    <InputGroup.Addon>
                        <Glyphicon glyph="pencil" />
                    </InputGroup.Addon>
                    {!withoutButtons && (
                        <InputGroup.Button>
                            <Button id="cancel" bsSize="small" onClick={this.cancel} ref={r => (this.buttonCancel = r)}>
                                <Glyphicon glyph="remove" className="button-cancel" />
                            </Button>
                            <Button
                                id="save"
                                bsSize="small"
                                onClick={this.submit}
                                type="submit"
                                ref={r => (this.buttonSubmit = r)}
                            >
                                <Glyphicon glyph="ok" className="button-save" />
                            </Button>
                        </InputGroup.Button>
                    )}
                </InputGroup>
                {error && <span className="help-block">{error}</span>}
            </FormGroup>
        );
    }
}

export default DropDownListEditableNoRootClose;
