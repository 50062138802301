import React, { PropTypes, Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import _ from 'lodash';
import Checkbox from './Checkbox';
import Input from './TextFieldGroup';
import './DropdownListSearchableMultiOptions.css';

class DropdownListSearchableMultiOptionsWithSubOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            toggle: false,
        };
    }

    onChangeSearchText = e => {
        this.setState({ searchText: e.target.value });
    };
    searchedItems(text) {
        if (text.length > 0) {
            return _.filter(this.props.Items, item => {
                if (item.navn.toLowerCase().includes(text.toLowerCase())) return item;
            });
        }
        return this.props.Items;
    }

    onToggle = () => {
        this.setState({ toggle: !this.state.toggle });
    };

    clearSearchbox = () => {
        this.setState({ searchText: '' });
    };

    render() {
        const { title, shortTitle, id, onChange, onSubChange, selectedItems, selectedSubItems, name, subName, clearDropdownFilters, selectAll, pullRight } = this.props;
        const { searchText, toggle } = this.state;
        const items = this.searchedItems(searchText);
        const size = _.size(selectedItems);
        return (
            // eslint-disable-next-line react/jsx-filename-extension
            <Dropdown className="multi-filter-dropdown" id={id} name={name} open={toggle} onToggle={this.onToggle} pullRight={pullRight}>
                <Dropdown.Toggle>
                    <span className="multi-filter-dropdown-longname">
                        {title} {size > 0 && <span className="filter-count-circle">{size}</span>}
                    </span>
                    <span className="multi-filter-dropdown-shortname">{shortTitle ? shortTitle : title}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="multi-filter-dropdown-list" id={id}>
                    <Input autofocus onChange={this.onChangeSearchText} value={searchText} />

                    {searchText === '' && <span className="glyphicon glyphicon-search" />}

                    {searchText !== '' && (
                        <span id="searchCleanBtn" onClick={this.clearSearchbox} role="button">
                            <span className="glyphicon glyphicon-remove-sign" />
                        </span>
                    )}

                    {size > 0 ? (
                        <button className="btn-link" onClick={(e) => clearDropdownFilters(name)}>
                            Nullstill filtre
                        </button>
                    ) : (
                        <button className="btn-link" onClick={e => selectAll()}>
                            Velg alle
                        </button>
                    )}
                    {_.map(items, (item, index) => {
                        const isChecked = _.includes(selectedItems, item.id.toString());
                        return (
                            <li key={index}>
                                <Checkbox
                                    className="menuItem"
                                    id={isChecked ? 'selectedItemBgColor' : '-'}
                                    name="filterCheckBoxes"
                                    title={item.navn}
                                    key={item.id}
                                    onChange={e => onChange(e, item.id, name)}
                                    checked={isChecked ? 'checked' : ''}
                                />
                                    <ul role="menu" style={{listStyle: 'none'}}>
                                    {isChecked && item.delbydeler && item.delbydeler.length > 0 && item.delbydeler.map((delbydel) => {
                                        const isSubChecked = _.includes(selectedSubItems, delbydel.id.toString());
                                        return (
                                            <li key={delbydel.id}>                               
                                                <Checkbox
                                                    className="menuItem"
                                                    id={isChecked ? 'selectedItemBgColor' : '-'}
                                                    name="filterCheckBoxes"
                                                    title={delbydel.navn}
                                                    key={delbydel.id}
                                                    onChange={e => onSubChange(e, delbydel.id, subName)}//  onSubChange(e, item.id, name)}
                                                    checked={isSubChecked ? 'checked' : ''}
                                                />
                                            </li>
                                    )})}
                                </ul>
                            </li>
                        );
                    })}
                    <center>
                        {' '}
                        <button onClick={this.onToggle} className="btnClose btn btn-lg btn-default">
                            Lukk
                        </button>
                    </center>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
DropdownListSearchableMultiOptionsWithSubOptions.defaultProps = {
    id: '',
};
DropdownListSearchableMultiOptionsWithSubOptions.propTypes = {
    title: PropTypes.string,
    shortTitle: PropTypes.string,
    id: PropTypes.string,
    Items: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    onSubChange: PropTypes.func,
    selectedItems: PropTypes.any,
    name: PropTypes.string.isRequired,
    subName: PropTypes.string.isRequired,
    clearDropdownFilters: PropTypes.func,
    pullRight: PropTypes.bool
};
export default DropdownListSearchableMultiOptionsWithSubOptions;
