import React, { Component, PropTypes } from 'react';
import { Panel, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import * as cookieHandler from '../../utils/cookieHandler';
import TextFieldGroup from '../../components/common/TextFieldGroup';
import { loginUser } from '../../actions/LoginActions';
import validateInput from '../../validations/LoginValidator';
import { addFlashMessage } from '../../actions/FlashMessagesAction';
import * as globals from '../../constants/Globals';
import * as routes from '../../constants/clientRoutes';
import * as ResponseMessage from '../../constants/ResponseMessages';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      epost: '',
      passord: '',
      serviceId: globals.bymelding_serviceId,
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      this.props
        .loginUser(this.state)
        .then(() => {
          if (
            cookieHandler.UserIsEntrepreneurAdmin() ||
            cookieHandler.UserIsEntrepreneur()
          ) {
            this.context.router.push(routes.entrepreneurMeldinger);
          } else if (cookieHandler.UserIsBymAdmin()) {
            this.context.router.push(routes.serviceAvdelingen);
          } else if (
            cookieHandler.UserIsServicedeskAdmin() ||
            cookieHandler.UserIsServicedesk()
          ) {
            this.context.router.push(routes.saksbehandlerMeldinger);
          } else if (cookieHandler.UserIsBymAdmin()) {
            this.context.router.push(routes.selskaper);
          } else if (cookieHandler.UserIsPublikum()) {
            this.props.addFlashMessage({
              type: 'error',
              text: ResponseMessage.UserIsNPublikumBruker,
            });
            cookieHandler.removeBymCookie();
            this.context.router.push(routes.login);
          } else {
            this.props.addFlashMessage({
              type: 'error',
              text: ResponseMessage.UserIsNotAuthorized,
            });
            cookieHandler.removeBymCookie();
            this.context.router.push(routes.login);
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data &&
            err.response.data.errorMessage
          ) {
            this.props.addFlashMessage({
              type: 'error',
              text: 'Brukernavn eller passord er ikke riktig.',
            });
          }
        })
        .catch(() =>
          this.props.addFlashMessage({
            type: 'error',
            text: ResponseMessage.AuthorizationNotAvailabe,
          })
        );
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { epost, passord, errors } = this.state;
    return (
      <div className="Login_center_div responsive" id="LoginDiv">
        <Panel>
          <Row>
            <Col md={4} mdOffset={4}>
              <h1 className="text-center col-md-11">
                <strong>Logg inn</strong>
              </h1>
              <form onSubmit={this.handleSubmit} className="col-md-12">
                <TextFieldGroup
                  error={errors.epost}
                  label="Brukernavn"
                  onChange={this.handleChange}
                  value={epost}
                  name="epost"
                  autofocus={true}
                />
                <TextFieldGroup
                  error={errors.passord}
                  label="Passord"
                  onChange={this.handleChange}
                  value={passord}
                  name="passord"
                  type="password"
                />

                <div className="form-group col-md-10">
                  <Link
                    to={routes.glemtpass}
                    className="btn btn-link pull-left"
                  >
                    {' '}
                    Glemt passord{' '}
                  </Link>
                  <button className="btn btn-success pull-right">
                    {' '}
                    Logg inn
                  </button>
                </div>
              </form>
            </Col>
          </Row>
        </Panel>
      </div>
    );
  }
}

Login.defaultProps = {
  serviceinfo: {},
};
Login.propTypes = {
  serviceinfo: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
};
Login.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    serviceinfo: state.common.serviceinfo,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loginUser, addFlashMessage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
