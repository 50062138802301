import React, { Component, PropTypes } from 'react';
import _ from 'lodash';
import SendTilbakeMelding from './SendTilbakeMeldingModal';
import { tilbakeMeildingNotValid } from '../../constants/ValidationErrors';
import {
  innmelderIsAnnonym,
  ingenMeldingerTilInnmelderen,
} from '../../constants/ResponseMessages';
import { formatDateTimeHavingKL } from '../../utils/utils';
import { bymeldingPortalUrl } from '../../constants/api';
import { findMeldingPath } from '../../utils/meldingUtils';
// import { toDateTimeString } from "../../utils/dateTimeHelpers"

class MeldingTilbakemeldinger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalTitle: '',
      tekst: '',
      errors: {},
    };
    this.onShow = this.onShow.bind(this);
    this.onChange = this.onChange.bind(this);
    this.sendtilbakeMeldingSubmit = this.sendtilbakeMeldingSubmit.bind(this);
  }

  innMelderIsAnnonym() {
    return (
      (this.props.innsenderNavn === 'Anonym innsender' ||
        this.props.innsenderNavn === 'anonympublikum@bym.no') &&
      this.props.innsenderEpost === 'anonympublikum@bym.no'
    );
  }

  componentWillMount() {}

  modalTitleVedAvslutning() {
    return 'Tilbakemelding ved avslutning av sak';
  }

  getMeldingDetaljerText() {
    const {
      mottattDato,
      meldingsType,
      adresse,
      beskrivelse,
      lopenummer,
      meldingStatusId,
      meldingsstatuser,
    } = this.props;
    const path = findMeldingPath(meldingsstatuser, meldingStatusId);
    const pathString = path.reduce((prev, current, index) => {
      const res =
        prev +
        `${
          current.navn !== 'Fritekst'
            ? `${index === 0 ? '' : ' - '}${current.navn}`
            : ''
        }`;
      return res;
    }, '');

    return `Meldingsnr.: ${lopenummer}\n${
      mottattDato ? `Mottatt: ${mottattDato}\n` : ''
    }${adresse ? `Adresse: ${adresse}\n` : ''}${
      meldingsType ? `Kategori: ${meldingsType}\n` : ''
    }${pathString ? `Status: ${pathString}\n` : ''}${
      beskrivelse ? `Beskrivelse: ${beskrivelse}\n` : ''
    }`;
  }

  modalTextVedAvslutning() {
    const { tekstmal, meldingId } = this.props;

    let text = `Hei,
Din melding har blitt oppdatert.

${this.getMeldingDetaljerText()}

${tekstmal || ''}

Se din melding her: ${bymeldingPortalUrl}/meldinger/${meldingId}

Takk for at du bidrar til å gjøre byen bedre.

Med vennlig hilsen
Bymiljøetaten, Oslo kommune
`;
    return text;
  }

  componentWillReceiveProps(props) {
    const modalTitle = this.modalTitleVedAvslutning();
    const text = this.modalTextVedAvslutning();
    if (props.popup) {
      this.setState({
        showModal: true,
        modalTitle,
        tekst: text,
      });
    } else {
      this.setState({ modalTitle: 'Tilbakemelding', tekst: '' });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onShow() {
    this.setState({ showModal: !this.state.showModal });
    this.setState({
      tekst: this.getMeldingDetaljerText(),
      modalTitle: 'Tilbakemelding',
      errors: {},
    });
    if (this.props.popup) this.props.showPopUpModal();
  }

  getMeldingTilbakemeldinger() {
    const padding = { paddingLeft: '30px' };
    return _.map(this.props.tilbakemeldinger, (i, index) => {
      return (
        <div key={index} className="meldingComments">
          <div className="kommentarList">
            <div className="row">
              <span className="img-circle glyphicon glyphicon-user" />
              &nbsp;
              <strong>{i.opprettetAvBruker?.navn ?? "Ukjent bruker"} — {i.opprettetAvBruker?.selskap?.navn == "Serviceavdelingen" ? "BYM" : (i.opprettetAvBruker?.selskap?.navn ?? "Ukjent")}</strong>
              &nbsp;&nbsp;
              <span>{formatDateTimeHavingKL(i.opprettetTid)}</span>
            </div>
            <div className="row" style={padding}>
              <p className="description"> {i.tekst}</p>
            </div>
          </div>
          <hr />
        </div>
      );
    });
  }

  isValid() {
    const errors = {};
    let validert = true;
    if (this.state.tekst === '') {
      errors.tekst = tilbakeMeildingNotValid;
      this.setState({ errors });
      validert = false;
    }
    return validert;
  }

  sendtilbakeMeldingSubmit() {
    if (this.isValid()) {
      const { meldingId } = this.props;
      this.props.addTilbakemelding(meldingId, this.state);
      this.onShow();
    }
  }

  render() {
    const { showModal, modalTitle, tekst, errors } = this.state;
    if (this.innMelderIsAnnonym()) {
      return (
        <button
          className="btn btn-default btn-lg"
          type="button"
          disabled
          title={innmelderIsAnnonym}
        >
          Send melding
        </button>
      );
    }
    const size = _.size(this.props.tilbakemeldinger);
    return (
      <div className="form-group">
        <div className="clearfix" />
        <div className="meldingerLIST">
          {size === 0 && <span>{ingenMeldingerTilInnmelderen}</span>}
          {size > 0 && this.getMeldingTilbakemeldinger()}
        </div>
        <button
          className="btn btn-default btn-lg"
          id="paddedButton"
          onClick={this.onShow}
        >
          Send melding
        </button>
        <SendTilbakeMelding
          showModal={showModal}
          modalTitle={modalTitle}
          tilbakeMeldingText={tekst}
          id="tilbakemelding"
          error={errors.tekst}
          onShow={this.onShow}
          onChange={this.onChange}
          onSubmit={this.sendtilbakeMeldingSubmit}
        />
      </div>
    );
  }
}

MeldingTilbakemeldinger.propTypes = {
  popup: PropTypes.bool,
  meldingId: PropTypes.string.isRequired,
  tekstmal: PropTypes.string.isRequired,
  tilbakemeldinger: PropTypes.array.isRequired,
  addTilbakemelding: PropTypes.func.isRequired,
  showPopUpModal: PropTypes.func.isRequired,
  lopenummer: PropTypes.string,
  meldingStatusId: PropTypes.string,
  meldingsstatuser: PropTypes.any,
  innsenderNavn: PropTypes.string,
  innsenderEpost: PropTypes.string,
  mottattDato: PropTypes.string,
  meldingsType: PropTypes.string,
  adresse: PropTypes.string,
  beskrivelse: PropTypes.string,
};
export default MeldingTilbakemeldinger;
