import React, { Component, PropTypes } from 'react';
import { Link } from "react-router";
import { bindActionCreators } from "redux"
import { connect } from "react-redux";
import { Panel, Row, Col } from 'react-bootstrap';
import TextFieldGroup from "../../components/common/TextFieldGroup";
import validateInput from "../../validations/ForgotPasswordValidator";
import { forgotPasswordRequest } from "../../actions/LoginActions";
import { addFlashMessage } from "../../actions/FlashMessagesAction";
import * as globals from "../../constants/Globals"
import AlertMessage from "../../components/common/AlertMessage";
import * as responseMessages from "../../constants/ResponseMessages"
import * as routes from "../../constants/clientRoutes"



class ForgotPasswordForm extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            id: '',
            epost: '',
            errors: {},
            isLoading: false,
            invalid: '',
            clientId: globals.bymelding_saksbehandling_clientId,
            serviceId: globals.bymelding_serviceId,
            alertType: ''
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    isValid()
    {
        const { errors, isValid } = validateInput(this.state);
        if (!isValid)
        {
            this.setState({ errors });
        }
        return isValid;
    }
    onChange(e)
    {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e)
    {
        e.preventDefault();
        if (this.isValid())
        {
            this.setState({ errors: {} });
            this.props.forgotPasswordRequest(this.state)
                .then(() =>
                {
                    this.context.router.push(routes.forgotpasswordEpostrecieved);
                })
                .catch(() => this.props.addFlashMessage({ type: 'error', text: responseMessages.epostNotExists })
                )
        }
    }
    render()
    {
        const { epost, errors, alertType } = this.state;
        return (
            <div className="Login_center_div" id="LoginDiv">
                <Panel>
                    <Row>
                        <Col md={4} mdOffset={4}>
                            <form onSubmit={this.onSubmit} className="col-xs-12">
                                <AlertMessage message={errors} type={alertType} />
                                <TextFieldGroup name="epost" label="E-post" error={errors.epost}
                                    value={epost} onChange={this.onChange} autofocus={true} />
                                <div className="form-group">
                                    <div className="col-xs-10">
                                        <button className="btn btn-success pull-right" id="btn-success">Send </button>
                                        <Link to="/" className="btn btn-link pull-right">Avbryt</Link>
                                    </div>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Panel>
            </div>
        );
    }
}

ForgotPasswordForm.propTypes = {
    serviceinfo: PropTypes.object,
    forgotPasswordRequest: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired

}
ForgotPasswordForm.contextTypes = {
    router: PropTypes.object.isRequired
}
const mapStateToProps = (state) =>
{
    return {
        serviceinfo: state.common.serviceinfo,

    }
}
function mapDispatchToProps(dispatch)
{
    return bindActionCreators({ forgotPasswordRequest, addFlashMessage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);