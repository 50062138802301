import React, { PropTypes, Component } from "react"
import { Button, Glyphicon } from 'react-bootstrap';
import _ from "lodash"
import "../../css/common/ImageUploader.css"

class ImageUploader extends Component
{
    constructor(props)
    {
        super(props)
        this.previewImageFiles = this.previewImageFiles.bind(this)
    }
    static propTypes = {
        bilder: PropTypes.array.isRequired,
        addImage: PropTypes.func.isRequired,
        removeImage: PropTypes.func.isRequired
    }

    openFileUpload = () => {
        this.FileInputElement.click()
    }

    onChangeFileUpload = (e) => {
        const files = e.target.files
        let arr = []
        if (files.length > 0) {
            _.map(files, f => {
                if (_.startsWith(f.type, "image/"))
                {
                    arr.push(f)
                }
            })
        }
        if (arr.length > 0) this.props.addImage(arr)
    }
    
    previewImageFiles(imgs)
    {
        if (imgs.length > 0)
        {
            return _.map(imgs, (i, index) =>
            {
                const src = URL.createObjectURL(i)
                return (<div key={index} className="imageDiv">
                    <img id="imageUploaderThumbnails" src={src} alt={i.name} />
                    <Glyphicon glyph="remove-sign" id="imageUploaderThumbnailsRemove" onClick={(e) => this.props.removeImage(e, i.name)} />
                </div>)
            })
        }
    }


    render()
    {
        const imgs = this.props.bilder
        const hideImages = imgs.length === 0 ? "hideImages" : ""
        const size = imgs.length
        return (<div className="NewMeldingImages">
            <strong>Bilder</strong>
            <br />
            {size < 4 && <Button bsStyle="link" id="buttonLink" onClick={this.openFileUpload}>Last opp bilder...</Button>}
            <div id="ImageContainer" className={hideImages}>
                <input type="file" name="file" id="file" multiple ref={input => this.FileInputElement = input}
                    style={{ display: 'none' }} onChange={this.onChangeFileUpload} />
                {size > 0 && this.previewImageFiles(imgs)}
            </div>
        </div>)
    }
}

export default ImageUploader