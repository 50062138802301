import React, { PropTypes } from 'react'
import AnchorLink from '../common/AnchorLink'
import * as routes from '../../constants/clientRoutes'
import FromToDate from '../common/FromToDate'

const ContractListRow = ({ contract, company, isEntrepreneurAdmin }) =>
{
    return (
        <tr>
            {!isEntrepreneurAdmin && <td><AnchorLink
                to={routes.selskaper + '/' + company.id + routes.kontrakter + "/" + contract.id}
                pageTitle={company.name} pageLink={routes.selskaper + "/" + company.id} children={contract.navn} linkLabel={contract.navn} />
            </td>
            }
            {isEntrepreneurAdmin && <td><AnchorLink
                to={routes.selskapKontrakter + '/' + company.id + routes.kontrakter + "/" + contract.id}
                pageTitle={company.navn} pageLink={routes.selskapKontrakter} children={contract.navn} linkLabel={contract.navn} />
            </td>
            }
            <td>{contract.avtale.navn}</td>
            <td><FromToDate from={contract.gyldigFraDato} to={contract.gyldigTilDato} /></td>
        </tr>
    );
};

ContractListRow.propTypes = {
    contract: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    isEntrepreneurAdmin: PropTypes.bool
};

export default ContractListRow;
