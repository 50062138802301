import React, { Component, PropTypes } from 'react'
import { Tab, Tabs } from "react-bootstrap"
import TextArea from "../../components/common/TextArea"
import Historisk from "./MeldingHistorisk"
//import { formatDateTimeHavingMonthNameAndTime } from "../../utils/utils"
//import { toDateTimeString } from "../../utils/dateTimeHelpers"
import { formatDateTimeHavingKL } from "../../utils/utils"
import { commentsIsRequired } from "../../constants/ValidationErrors"
import { ingenKommentarer } from "../../constants/ResponseMessages"
import _ from "lodash"

class MeldingComments extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            errors: {},
            tekst: '',
            toggle: false
        }
        this.onShow = this.onShow.bind(this)
        this.submittekst = this.submittekst.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
    }
    onShow()
    {
        this.setState({ toggle: !this.state.toggle })
        this.setState({ tekst: '', errors: {} })
    }
    isValid()
    {
        let errors = {}
        let validert = true
        if (this.state.tekst === "")
        {
            errors.tekst = commentsIsRequired
            this.setState({ errors })
            validert = false
        }
        return validert
    }
    submittekst(e)
    {
        e.preventDefault()
        if (this.isValid())
        {
            const meldingId = this.props.meldingId
            this.props.addKommentar(meldingId, this.state)
            this.onShow()
        }

    }
    handleChange(e)
    {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSelect(key)
    {
        if (key === 2)
        {
            const meldingId = this.props.meldingId
            this.props.getMeldingHistorisk(meldingId)
        }
    }

    renderKommentarer()
    {
        const padding = { "paddingLeft": "25px" }
        return _.map(this.props.kommentarer, (i, index) =>
        {
            return (<div key={index} className="meldingComments">
                <div className="kommentarList">
                    <div className="row">
                        <span className="img-circle glyphicon glyphicon-user"></span>&nbsp;
                            <strong>{i.opprettetAvBruker?.navn ?? "Ukjent bruker"} — {i.opprettetAvBruker?.selskap?.navn == "Serviceavdelingen" ? "BYM" : (i.opprettetAvBruker?.selskap?.navn ?? "Ukjent")}</strong>&nbsp;&nbsp;
                            <span>{formatDateTimeHavingKL(i.opprettetTid)}</span>
                    </div>
                    <div className="row" style={padding}>
                        <p className="description">{i.tekst}</p>
                    </div>
                </div>
                <hr />
            </div>)
        })
    }
    render()
    {
        const { errors, tekst, toggle } = this.state
        const { isEntreprenor } = this.props;
        const size = _.size(this.props.kommentarer)
        return (
            <div className="col-xs-12">
                <Tabs defaultActiveKey={1} id="MeldingCommentsTab" onSelect={this.handleSelect} >
                    <Tab eventKey={1} title="Kommentarer">
                        {toggle && <TextArea error={errors.tekst} label="Kommentar:" onChange={this.handleChange} value={tekst} name="tekst" id="commentsTextArea" />}
                        <br />
                        <div className="form-group">
                            {!toggle && <button className="btn btn-default btn-lg pull-left" id="paddedButton" onClick={this.onShow}> Legg til kommentar </button>}
                            {toggle && <div>
                                <button className="btn btn-link" onClick={this.onShow}> Avbryt </button>
                                <button className="btn btn-primary btn-lg" id="paddedButton" onClick={this.submittekst}> Legg til kommentar </button>
                            </div>
                            }
                        </div>
                        <div className="clearfix"></div>
                        <hr className="hr-bold" />
                        <div className="meldingerLIST">
                            {size === 0 && <span> {ingenKommentarer} </span>}
                            {size > 0 && this.renderKommentarer()}
                        </div>
                        <br />
                    </Tab>
                    <Tab eventKey={2} title="Historikk" >
                        <div>
                            <Historisk historicList={this.props.historicList} isEntreprenor={isEntreprenor} />
                        </div>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

MeldingComments.propTypes = {
    meldingId: PropTypes.string.isRequired,
    kommentarer: PropTypes.array.isRequired,
    addKommentar: PropTypes.func.isRequired,
    historicList: PropTypes.array.isRequired,
    getMeldingHistorisk: PropTypes.func.isRequired,
    isEntreprenor: PropTypes.bool,
}

export default MeldingComments