import React, { Component, PropTypes } from 'react';
import { FormGroup, InputGroup, Button, Glyphicon } from 'react-bootstrap';
import classnames from 'classnames';
import '../../css/common/EditableField.css';
import * as validationError from '../../constants/ValidationErrors';
import { isNumber } from 'lodash';
import { parseFloat } from 'core-js/core/number';
import number from 'core-js/core/number';

class EditableField extends Component {
  constructor(props) {
    super(props);
    this.click = this.click.bind(this);
    this.cancel = this.cancel.bind(this);
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onPaste = this.onPaste.bind(this);
    this.state = {
      editing: false,
      text: props.text ? props.text : '',
      error: '',
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.text !== nextProps) {
      this.setState({
        text: nextProps.text,
      });
    }
  }

  click(ev) {
    if (!this.state.editing) {
      let isNumber = this.props.isNumber;
      let val = this.state.text;
      if (isNumber) {
        val = this.state.text.toLocaleString('nb-NO', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      this.setState({ editing: true, text: val, error: '' });
    }
  }

  cancel() {
    this.setState({ editing: false, text: this.props.text, error: '' });
  }

  isValid(val) {
    let length = this.props.inputLength;
    let maxLength = this.props.maxLength;
    let isNumber = this.props.isNumber;
    let isRequired = this.props.required;
    if (isRequired && val.length === 0) {
      this.setState({ error: validationError.NumberShouldBeInteger });
      return false;
    }
    if (isNumber) {
      val = val.replace(/,/g, '.');
      val = val.replace(/\s/g, '');
    }

    if (length) {
      if (val.length < length) {
        this.setState({
          error: 'Tekstlengde må være minst ' + length.toString(),
        });
        return false;
      }
    }
    if (maxLength) {
      if (val.length !== maxLength) {
        this.setState({
          error: 'Tekstlengde må ikke være større enn ' + maxLength.toString(),
        });
        return false;
      }
    }
    if (isNumber && isNaN(val)) {
      this.setState({ error: validationError.NumberShouldBeInteger });
      return false;
    }
    if (isNumber && !isNaN(val) && val.substring(0, 1) === '-') {
      if (this.props.error) this.setState({ error: this.props.error });
      else this.setState({ error: validationError.NumberShouldBeInteger });
      return false;
    } else {
      this.setState({ error: '' });
      return true;
    }
  }
  submit() {
    let isNumber = this.props.isNumber;
    if (isNumber) {
      this.state.text = this.state.text.replace(/,/g, '.');
      this.state.text = this.state.text.replace(/\s/g, '');
    }

    const value = this.state.text;

    if (this.isValid(value)) {
      this.props.onSubmit(value);
      this.setState({ editing: false, error: '' });
    } else {
      this.cancel();
    }
  }

  onBlur = e => {
    if (!this.node.contains(e.relatedTarget)) {
      this.cancel();
    }
  };

  onPaste(e) {
    let isNumber = this.props.isNumber;
    let value = e.clipboardData.getData('Text');

    if (isNumber) {
      e.preventDefault();
      e.target.selectionStart = e.target.selectionEnd = value.length;
      value = value.toLocaleString('nb-NO', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    this.setState({ text: value });
    this.isValid(value);
  }

  handleChange(ev) {
    let isNumber = this.props.isNumber;
    let value = ev.target.value;

    if (isNumber) {
      value = value.toLocaleString('nb-NO', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    this.setState({ text: value });
    this.isValid(value);
  }

  renderEditing() {
    const { text, error } = this.state;
    return (
      <FormGroup
        id={this.props.id}
        className={classnames('editable-field-form', { 'has-error': error })}
      >
        <span onBlur={this.onBlur} ref={node => (this.node = node)}>
          <InputGroup className="editable-field " id="dotted">
            {/*<Textarea autoFocus className="form-control editable-field-textarea" value={text} onChange={this.handleChange} maxRows={1} />*/}
            <input
              className="form-control editable-field-textarea"
              id={this.props.inputId}
              autoFocus={true}
              value={text}
              onPaste={this.onPaste}
              onChange={this.handleChange}
              onFocus={e => {
                e.target.selectionStart = e.target.value.length;
              }}
            />
            <span className="input-group-btn editable-field-buttons">
              <Button bsSize="small" onClick={this.cancel} key="cancel">
                <Glyphicon
                  glyph="remove"
                  className="editable-field-button-cancel"
                />
              </Button>
              <Button
                bsSize="small"
                onClick={this.submit}
                type="submit"
                key="submit"
              >
                <Glyphicon glyph="ok" className="editable-field-button-save" />
              </Button>
            </span>
          </InputGroup>
          {error.length > 0 && (
            <div>
              <span className="help-block">{error}</span> <br />
            </div>
          )}
        </span>
      </FormGroup>
    );
  }

  renderNormal() {
    const { text, error } = this.state;
    return (
      <FormGroup
        id={this.props.id}
        className={classnames('editable-field-form', { 'has-error': error })}
        onClick={this.click}
      >
        <InputGroup className="editable-field">
          {/*<Textarea className="form-control editable-field-textarea" value={text} onChange={() => { }} maxRows={1} id="" />*/}
          <input
            className="form-control editable-field-textarea"
            id={this.props.inputId}
            value={text}
            onChange={() => {}}
          />
          <InputGroup.Addon className="editable-field-span">
            <Glyphicon glyph="pencil" />
          </InputGroup.Addon>
        </InputGroup>
        {error.length > 0 && <span className="help-block">{error}</span>}
      </FormGroup>
    );
  }

  render() {
    return this.state.editing ? this.renderEditing() : this.renderNormal();
  }
}

EditableField.propTypes = {
  nullable: PropTypes.bool,
  isNumber: PropTypes.bool,
  required: PropTypes.bool,
  inputLength: PropTypes.number,
  maxLength: PropTypes.number,
  error: PropTypes.string,
  text: PropTypes.string,
  id: PropTypes.string,
  inputId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default EditableField;
