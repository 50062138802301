import { LOAD_MELDING_HISTORIC } from "./ActionTypes"
import { getHistoriskForMeldingUrl } from "../constants/api"
import axios from "axios"
import { successData } from "../utils/utils"


function loadMeldingHistoriskSuccess(historic)
{
    return { type: LOAD_MELDING_HISTORIC, historic }
}

export function getMeldingHistorisk(meldingId)
{
    const url = getHistoriskForMeldingUrl(meldingId)
    return dispatch =>
    {
        return axios.get(url).then(res =>
        {
            const result = successData(res)
            dispatch(loadMeldingHistoriskSuccess(result.tidslinje))
        })
    }
}