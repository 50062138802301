import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Confirm from 'react-confirm-bootstrap';
import * as utils from '../../utils/utils';
import EditableRessursbrukDate from './EditableRessursbrukDate';
import EditableRessursbrukTime from './EditableRessursbrukTime';
import EditableRessursbrukNumber from './EditableRessursbrukNumber';
import EditableRessursbrukKommentar from './EditableRessursbrukKommentar';
import * as ResponseMessages from '../../constants/ResponseMessages';

import './RessursbrukList.css';

const RessursbrukRow = ({
    ressursbruk,
    isEditable,
    onSubmitEditableComment,
    onSubmitEditableDate,
    onSubmitTid,
    onSubmitEditableEnhent,
    isToggled,
    toggle,
    remove,
    hidePrice,
}) => (
    <tbody key={ressursbruk.ressursforbrukId}>
        <tr
            className={`ressursbruk-tr ${isEditable ? ' editable-enabled ' : ' editable-disabled '}`}
            key={ressursbruk.ressursforbrukId}
        >
            <td className="ressursbruk-td-tjeneste">
                <span
                    id="tjenestetable"
                    onClick={() => toggle(ressursbruk.ressursforbrukId)}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                >
                    <span
                        id="styleglyphicon"
                        className={`glyphicon ${isToggled ? 'glyphicon-chevron-down' : 'glyphicon-chevron-right'} btn`}
                    />
                </span>
            </td>
            <td
                className="table-datePicker-override ressursbruk-td-dato"
                title={`Registreringstidspunkt: kl. ${utils.formatTimeDate(ressursbruk.opprettetDato)}`}
            >
                <EditableRessursbrukDate
                    value={ressursbruk.Dato}
                    isEditable={isEditable}
                    onSubmitEditableDate={onSubmitEditableDate}
                    ressursbruk={ressursbruk}
                />
            </td>
            <td
                className="ressursbruk-td-time-edit"
                title={`Registreringstidspunkt: kl. ${utils.formatTimeDate(ressursbruk.opprettetDato)}`}
            >
                <EditableRessursbrukTime
                    value={ressursbruk.Dato}
                    isEditable={isEditable}
                    onSubmitTid={onSubmitTid}
                    ressursbruk={ressursbruk}
                />
            </td>
            <td className="ressursbruk-td-tjeneste ressursbruk-td-tjeneste-beskrivelse">
                <span>
                    {ressursbruk.prosesskodeNavn} {ressursbruk.prosesskodeBeskrivelse}{' '}
                </span>
            </td>

            <td className="ressursbruk-td-mengde">
                <EditableRessursbrukNumber
                    value={ressursbruk.prosesskodeEnhetsVerdi}
                    isEditable={isEditable}
                    onSubmitEditableEnhent={onSubmitEditableEnhent}
                    ressursbruk={ressursbruk}
                />
            </td>
            <td className="ressursbruk-td-enhet">{ressursbruk.prosesskodeEnhetNavn}</td>
            {!hidePrice && (
                <td className="ressursbruk-td-pris ressursbruk-td-kommentar">
                    kr
                    <span>
                        {ressursbruk.calculatedPrice.toLocaleString('nb-NO', {
                            minimumFractionDigits: 2,
                        })}
                    </span>
                </td>
            )}
            <td>
                {isEditable && (
                    <Confirm
                        confirmBSStyle="primary"
                        cancelText="Avbryt"
                        onConfirm={e => remove(e, ressursbruk)}
                        body={`${ResponseMessages.ressursbrukRemoveBodyText + ressursbruk.prosesskodeNavn} "${
                            ressursbruk.prosesskodeBeskrivelse
                        }" ?`}
                        confirmText="Fjern"
                        title={ResponseMessages.ressursbrukRemoveressursbruk}
                    >
                        <Button className="ressursRemoveButton danger" bsStyle="link">
                            Fjern
                        </Button>
                    </Confirm>
                )}
            </td>
        </tr>
        <tr
            key={`${ressursbruk.ressursforbrukId}_comment`}
            // className={classNames('ressursbruk-comment', {
            //     hidden: !isToggled,
            // })}
            className={`ressursbruk-tr ressursbruk-comment ${
                isEditable ? ' editable-enabled ' : ' editable-disabled '
            } ${!isToggled ? ' hidden' : ''}`}
        >
            <td colSpan={8}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="editable-ressursbruk-comment" className="editable-ressursbruk-comment">
                    <span>Kommentar</span>
                    <EditableRessursbrukKommentar
                        value={ressursbruk.Kommentar}
                        isEditable={isEditable}
                        onSubmitEditableComment={onSubmitEditableComment}
                        ressursbruk={ressursbruk}
                    />
                </label>
            </td>
        </tr>
    </tbody>
);

RessursbrukRow.propTypes = {
    ressursbruk: PropTypes.shape({
        ressursforbrukId: PropTypes.string,
        Dato: PropTypes.string,
        prosesskodeNavn: PropTypes.string,
        prosesskodeEnhetsVerdi: PropTypes.number,
        prosesskodeEnhetNavn: PropTypes.string,
        calculatedPrice: PropTypes.number,
        prosesskodeBeskrivelse: PropTypes.string,
        Kommentar: PropTypes.string,
    }),
    isEditable: PropTypes.bool,
    isToggled: PropTypes.bool,
    hidePrice: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
    onSubmitEditableComment: PropTypes.func.isRequired,
    onSubmitEditableDate: PropTypes.func.isRequired,
    onSubmitTid: PropTypes.func.isRequired,
    onSubmitEditableEnhent: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
};

RessursbrukRow.defaultProps = {
    ressursbruk: {
        ressursforbrukId: null,
        Dato: null,
        prosesskodeNavn: null,
        prosesskodeEnhetsVerdi: null,
        prosesskodeEnhetNavn: null,
        calculatedPrice: null,
        prosesskodeBeskrivelse: null,
        Kommentar: null,
    },
    hidePrice: false,
    isEditable: false,
    isToggled: false,
};

export default RessursbrukRow;
