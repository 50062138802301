import React, { Component, PropTypes } from 'react';
import Select from 'react-select';
import queryString from 'query-string';
import classNames from 'classnames';
import _ from 'lodash';

class MeldingSortering extends Component {
  constructor(props, context) {
    super(props, context);
    this.sorterMeldinger = this.sorterMeldinger.bind(this);
  }

  state = {
    sorterEtter: '',
  };

  initSortBy() {
    const sorterEtter = this.getQuery('sorterEtter')
      ? this.getQuery('sorterEtter')
      : '';
    this.setState({ sorterEtter: sorterEtter });
  }

  sorterMeldinger(p) {
    if (p !== null) {
      this.queryBuilder('sorterEtter', p.value);
    } else {
      this.queryBuilder('sorterEtter');
    }
    this.setState({ sorterEtter: p ? p.value : '' });
  }

  queryBuilder(key, value) {
    const pathname = this.context.router.location.pathname;
    const params = this.context.router.location.query;
    params[key] = value;
    const qu = queryString.stringify(params);
    const uri = pathname + '?' + qu;
    this.context.router.push(uri);
    this.updateCallBack();
  }

  getQuery(q) {
    const query = this.context.router.location.query;
    return query[q] ? query[q] : null;
  }

  updateCallBack() {
    this.props.filterFunction();
  }

  componentWillMount() {
    this.initSortBy();
  }

  render() {
    const sorteringsOptions = [
      {
        label: 'Adresse',
        value: 'adresse',
      },
      {
        label: 'Dato',
        value: 'dato',
      },
      {
        label: 'Tidsfrist',
        value: 'tidsfrist',
      },
      {
        label: 'Status',
        value: 'status',
      },
      {
        label: 'Type',
        value: 'type',
      }
    ];

    return (
      <div className="meldingSort">
        <div className="meldingSort-label">{'Sorter etter:'}</div>
        <div className="meldingSort-bySelect">
          {this.sorterMeldinger.length > 0 && (
            <Select
              name="sortSelect"
              multi={false}
              placeholder="..."
              options={sorteringsOptions}
              onChange={this.sorterMeldinger}
              value={this.state.sorterEtter}
            />
          )}
        </div>
      </div>
    );
  }
}

MeldingSortering.contextTypes = {
  router: PropTypes.object,
  
};

MeldingSortering.propTypes = {
  filterFunction: PropTypes.func.isRequired,
};

export default MeldingSortering;
