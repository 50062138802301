import { LOAD_MELDING_COMMENTS } from "./ActionTypes"
import { getKommentarerForMeldingUrl } from "../constants/api"
import axios from "axios"
import { successData } from "../utils/utils"
import { addFlashMessage } from "./FlashMessagesAction";
import { commentIsNotSent, commentIsSent } from "../constants/ResponseMessages"


// function addMeldingKommentarerSuccess(comments)
// {
//     return { type: LOAD_MELDING_COMMENTS, comments }
// }

function loadMeldingKommentarerSuccess(comments) {
    return { type: LOAD_MELDING_COMMENTS, comments }
}
export function getMeldingKommentarer(meldingId) {
    const url = getKommentarerForMeldingUrl(meldingId)
    return dispatch => {
        return axios.get(url).then(res => {
            const result = successData(res)
            dispatch(loadMeldingKommentarerSuccess(result))
        })
    }
}

export function addMeldingKommentarer(meldingId, comment) {
    const url = getKommentarerForMeldingUrl(meldingId)
    return dispatch => {
        return axios.post(url, comment)
            .then(() => {
                dispatch(getMeldingKommentarer(meldingId))
                dispatch(addFlashMessage({ type: 'success', text: commentIsSent }));
            })
            .catch(error => {
                if (error)
                    dispatch(addFlashMessage({ type: 'error', text: commentIsNotSent }));
            })
    }
}
