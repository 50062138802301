import React, { Component, PropTypes } from 'react';
import { Pagination } from 'react-bootstrap';
import '../../css/common/Pagination.css';
class PaginationComponent extends Component {
  render() {
    return (
      <Pagination
        id={this.props.id}
        /* next={<button className="btn btn-default paginationNextButton"><span>Neste</span><span className="glyphicon glyphicon-chevron-right"></span></button>}
                 prev={<button className="btn btn-default paginationPrevButton"><span className="glyphicon glyphicon-chevron-left"></span><span>Forrige</span></button>}*/
        prev={
          <span className="btn btn-default paginationPrevButton">
            <span className="glyphicon glyphicon-chevron-left"></span>
            <span className="pagination-button-label">Forrige</span>
          </span>
        }
        next={
          <span className="btn btn-default paginationNextButton">
            <span className="pagination-button-label">Neste</span>
            <span className="glyphicon glyphicon-chevron-right"></span>
          </span>
        }
        ellipsis
        boundaryLinks
        items={this.props.pages}
        maxButtons={5}
        activePage={this.props.activePage}
        onSelect={this.props.onSelect}
      ></Pagination>
    );
  }
}
PaginationComponent.propTypes = {
  activePage: PropTypes.number,
  onSelect: PropTypes.func,
  pages: PropTypes.number,
  id: PropTypes.string,
};
export default PaginationComponent;
