import React, { PropTypes } from 'react';
import { Button } from 'react-bootstrap';
import Confirm from 'react-confirm-bootstrap';
import EditableField from '../common/EditableField';
import * as ResponseMessages from '../../constants/ResponseMessages';

const ProcesscodeListRow = ({ processcode, onClick, onSubmit }) => {
  return (
    <tr>
      <td><EditableField text={processcode.navn} onSubmit={onSubmit} /></td>
      <td>{processcode.beskrivelse}</td>
      <td>{processcode.prosesskodeEnhetNavn}</td>
      <td>

        <Confirm
          confirmBSStyle="primary"
          cancelText="Avbryt"
          onConfirm={onClick}
          body={`${ResponseMessages.prosesskodeRemoveQuestionText}"${processcode.navn}" ?`}
          confirmText="Fjern"
          title={ResponseMessages.prosesskoderRemoveprosesskode}
        >
          <Button className="danger pull-right" bsStyle="link">Fjern prosesskode</Button>
        </Confirm>
      </td>
    </tr>
  );
};

ProcesscodeListRow.propTypes = {
  processcode: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ProcesscodeListRow;
