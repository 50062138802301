import React, { PropTypes } from "react"
import * as cookieHandler from "../utils/cookieHandler"
import Login from "../containers/Account/Login"
import _ from "lodash"
import { UserIsNotAuthorized } from "../constants/ResponseMessages"

//wrapped component: each route component is wrapped with allowedRoles commponent
const AuthorizationHandler = (allowedRoles) => (ComponentToRoute) =>
{
    class AuthorizedComponent extends React.Component
    {
        render()
        {
            const jwt = cookieHandler.validateToken();
            if (!jwt)
            {
                return <Login {...this.props} />
            }
            else
            {
                cookieHandler.SetAuthorizationToken();
                const roles = cookieHandler.UsersRoleAndProcessroleRoles()
                //console.log("roles", roles)
                //console.log("allowed roles", allowedRoles)
                if (_.intersection(allowedRoles, roles).length > 0)
                {
                    return <ComponentToRoute {...this.props} />
                }
                else
                {
                    return (<div className="well"><center>{UserIsNotAuthorized}</center></div>)
                }
            }
        }
    }
    AuthorizedComponent.contextTypes = {
        router: PropTypes.object
    }
    return AuthorizedComponent
}
export default AuthorizationHandler

