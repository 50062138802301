import React, { Component, PropTypes } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadEntrepreneurContracts, saveContract } from '../../actions/contractActions';
import { BronnoysundRegistreneGetInfo, updateSelskap } from "../../actions/selskaperActions"
import EntrepreneurDetailHeader from '../../components/entrepreneur/EntrepreneurDetailHeader';
import UserListContainer from '../../containers/User/UserListContainer';
import ContractList from '../../components/entrepreneur/ContractList';
import AvtalerList from '../../components/entrepreneur/EntrepreneurAvtalerList';
import NewUserModal from '../../components/user/create/NewUserModal';
import NewContractModal from '../../components/contract/NewContractModal';
import { loadUsers } from '../../actions/userActions';
import { loadProcesscodeschemes } from '../../actions/processcodeschemeActions';
import { loadAvtalerForEntrepreneurAdmin, UpdateAvtale } from '../../actions/avtalerAction';
import AddAvtalerModal from "../../components/entrepreneur/AddAvtalerModal"
import { addFlashMessage } from "../../actions/FlashMessagesAction";
import { selskapsTypeEntreprenorerId } from '../../constants/Globals'
import * as cookieHandler from "../../utils/cookieHandler"
import _ from "lodash"
import '../../css/Entrepreneur.css';
import { browserHistory } from 'react-router';

class EntrepreneurDetailsContainer extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleSaveName = this.handleSaveName.bind(this);
        this.handleSaveOrgnr = this.handleSaveOrgnr.bind(this);
        this.addContract = this.addContract.bind(this);
        this.submitAvtaler = this.submitAvtaler.bind(this)

    }
    loadAvtalerForLoggedUser(selskapId) {
        let userId = cookieHandler.UserId()
        if (selskapId && userId) {
            if (cookieHandler.UserIsEntrepreneurAdmin() || cookieHandler.UserIsBymAdmin()) {
                this.props.loadAvtalerForEntrepreneurAdmin(selskapId)
            }
        }
    }

    componentDidMount() {
        const selskapId = this.props.params.id
        this.props.loadProcesscodeschemes();
        this.props.loadEntrepreneurContracts(selskapId);
        this.loadAvtalerForLoggedUser(selskapId)
    }

    handleSaveName(input) {
        const { selskap } = this.props
        const payload = Object.assign({ id: selskap.id, navn: input, orgnr: selskap.orgnumber, epost: selskap.email, telefon: selskap.phonenumber, type: selskap.type })
        this.props.updateSelskap(payload)
        this.updateUrlState(input)
    }

    handleSaveOrgnr(input) {
        const { selskap } = this.props
        const payload = Object.assign({ id: selskap.id, navn: selskap.name, orgnr: input, epost: selskap.email, telefon: selskap.phonenumber, type: selskap.type })
        this.props.updateSelskap(payload)
    }

    updateUrlState(input) {
        const subtitle = input;
        const pathname = this.context.router.location.pathname;
        const pagetitle = this.context.router.location.pageTitle;
        this.context.router.location.state.subTitle = subtitle;
        browserHistory.push({ pathname: pathname, state: { subTitle: subtitle, pageTitle: pagetitle } })
    }

    submitAvtaler(values) {
        const selskapsId = this.props.params.id
        _.forEach(values, item => {
            const avtale = _.find(this.props.avtaler, avtale => avtale.avtaleId === item.id);
            const payload = {
                avtaleId: avtale.avtaleId,
                selskapId: selskapsId,
                navn: avtale.navn,
                avtaleKobling: avtale.avtaleKobling,
                beskrivelse: avtale.beskrivelse
            };
            this.props.UpdateAvtale(payload);
        })
    }
    addContract(contract, avtale, prosesskodeSkjema) {
        this.props.saveContract(contract, avtale, prosesskodeSkjema);
    }
    getAllAvtaler() {
        return _.sortBy(_.map(this.props.avtaler, i => {
            return Object.assign({}, { id: i.avtaleId, navn: i.beskrivelse })
        }), 'navn')
    }
    getRemainedAvtalerToAdd() {
        const allAvtaler = this.getAllAvtaler()
        const selskapsAvtaler = this.filterAvtalerForSelskap()
        if (allAvtaler && selskapsAvtaler) {
            return _.differenceBy(allAvtaler, selskapsAvtaler, "id")
        }
    }
    filterAvtalerForSelskap() {
        const selskapsId = this.props.params.id
        const avtaler = this.props.avtaler
        return _.sortBy(_.map(_.filter(avtaler, { 'selskapId': selskapsId }), i => {
            return Object.assign({}, { id: i.avtaleId, navn: i.navn, beskrivelse: i.beskrivelse })
        }), 'navn')
    }

    render() {
        if (!this.props.selskap) return false;
        const { selskap } = this.props
        const filteredAvtaler = this.filterAvtalerForSelskap()
        const size = _.size(filteredAvtaler)
        const remainedAvtaler = this.getRemainedAvtalerToAdd()
        const userIsBymadmin = cookieHandler.UserIsBymAdmin();
        return (
            <div className="mainContainer col-xs-12">
                <Row>
                    <Col md={12}>
                        <div>
                            <NewUserModal onConfirm={this.saveUser} confirmText="Legg til bruker"
                                buttonText="Legg til bruker" modalTitle="Legg til bruker"
                                selskapsType={selskap.type.id} avtaler={filteredAvtaler}
                                selskapId={selskap.id} />
                            <NewContractModal buttonText="Legg til kontrakt"
                                processcodeschemes={this.props.processcodeschemes}
                                avtaler={filteredAvtaler} selskapsId={this.props.params.id}
                                onSaveContract={this.addContract} />
                            <AddAvtalerModal avtaler={remainedAvtaler} submitAvtaler={this.submitAvtaler} />
                        </div>
                    </Col>
                </Row>
                <EntrepreneurDetailHeader selskapName={selskap.name} orgNumber={selskap.orgnumber}
                    onSubmitName={this.handleSaveName}
                    onSubmitOrgNr={this.handleSaveOrgnr} 
                    userIsBymadmin = {userIsBymadmin}
                    />
                <Tabs defaultActiveKey={1} id="entrepreneur-tabs">
                    <Tab eventKey={1} title="Brukere">
                        <UserListContainer selskapsType={selskapsTypeEntreprenorerId} selskapsId={this.props.params.id} editable={true} avtaler={filteredAvtaler} />
                    </Tab>
                    <Tab eventKey={2} title="Kontrakter">
                        <ContractList contracts={this.props.contracts} company={selskap} />
                    </Tab>
                    <Tab eventKey={3} title={`Avtaler ( ${size} )`}>
                        <AvtalerList avtaler={filteredAvtaler} selskapsId={this.props.params.id} />
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

EntrepreneurDetailsContainer.defaultProps = {
    processcodeschemes: [],
    avtaler: [],
    selected: {},
    avtalerList: []
};

EntrepreneurDetailsContainer.contextTypes = {
    router: PropTypes.object
}

EntrepreneurDetailsContainer.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired,
    contracts: PropTypes.array.isRequired,
    loadEntrepreneurContracts: PropTypes.func.isRequired,
    selskap: PropTypes.object,
    updateSelskap: PropTypes.func.isRequired,
    BronnoysundRegistreneGetInfo: PropTypes.func,
    processcodeschemes: PropTypes.array.isRequired,
    loadProcesscodeschemes: PropTypes.func.isRequired,
    avtaler: PropTypes.array.isRequired,
    loadAvtalerForEntrepreneurAdmin: PropTypes.func,
    avtalerList: PropTypes.array,
    UpdateAvtale: PropTypes.func.isRequired,
    selected: PropTypes.object.isRequired,
    saveContract: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired
};


function mapStateToProps(state, ownProps) {
    return {
        contracts: state.contracts,
        selskap: state.entreprenorer.length > 0 ? state.entreprenorer.find(selskap => selskap.id === ownProps.params.id) : null,
        loading: state.entreprenorer.length === 0,
        processcodeschemes: state.processcodeschemes,
        avtaler: state.contractZones,
        avtalerList: state.avtaler
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        loadUsers, loadEntrepreneurContracts, updateSelskap, UpdateAvtale, addFlashMessage,
        loadProcesscodeschemes, saveContract, BronnoysundRegistreneGetInfo, loadAvtalerForEntrepreneurAdmin
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EntrepreneurDetailsContainer);

