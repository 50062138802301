import React, { Component, PropTypes } from 'react';
import { commentsIsRequired } from '../../constants/ValidationErrors';
import AddCommentForm from './AddCommentForm';

class AddCommentModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.onShow = this.onShow.bind(this);
        this.submittekst = this.submittekst.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = this.initialState();
    }

    initialState() {
        return {
            errors: {},
            tekst: '',
            showModal: false,
        };
    }

    onShow() {
        this.setState({ showModal: !this.state.showModal });
        this.setState({ tekst: '', errors: {} });
        if (this.props.popup) this.props.showAddComment();
    }

    onCancel = () => {
        const { onCancelUpdateStatus } = this.props;
        if (onCancelUpdateStatus) onCancelUpdateStatus();
        this.onShow();
    };

    componentWillReceiveProps(props) {
        if (props.popup) {
            this.setState({ showModal: true });
        }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    isValid() {
        const errors = {};
        let validert = true;
        if (this.state.tekst.trim().length < 2) {
            errors.tekst = commentsIsRequired;
            this.setState({ errors });
            validert = false;
        }
        return validert;
    }

    submittekst(e) {
        e.preventDefault();
        if (this.isValid()) {
            const { meldingId } = this.props;
            this.props.addKommentar(meldingId, this.state);
            this.props.updateStatus();
            this.onShow();
        }
    }

    render() {
        const { errors, tekst, showModal } = this.state;
        return (
            <div>
                <AddCommentForm
                    showModal={showModal}
                    modalTitle={this.props.modalTitle}
                    kommentarTekst={tekst}
                    onShow={this.onShow}
                    handleChange={this.handleChange}
                    submittekst={this.submittekst}
                    error={errors.tekst}
                    id="kommentar"
                    onCancel={this.onCancel}
                />
            </div>
        );
    }
}

AddCommentModal.propTypes = {
    meldingId: PropTypes.string.isRequired,
    confirmText: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired,
    showAddComment: PropTypes.func.isRequired,
    popup: PropTypes.bool,
    addKommentar: PropTypes.func.isRequired,
    updateStatus: PropTypes.func.isRequired,
    onCancelUpdateStatus: PropTypes.func.isRequired,
};

export default AddCommentModal;
