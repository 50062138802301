import React, { PropTypes } from 'react';
import classnames from "classnames";

function hasError(error)
{
    return error && error.length > 0
}
const TextFieldGroup = ({ name, value, valueFront, label, id, error, type, onChange, onKeyPress, onBlur, placeHolder, disabled, autofocus }) =>
{

    return (
        <div className={classnames('form-group', hasError(error) ? { 'has-error': error } : '')}>
            {label && <label className="control-label" htmlFor={name}>{label}</label>}
            <div className={valueFront ? "input-group" : ""}>
                <input className="form-control"
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    onBlur={onBlur}
                    value={value}
                    type={type}
                    name={name}
                    placeholder={placeHolder}
                    id={id}
                    disabled={disabled}
                    autoFocus={autofocus}
                />
                {valueFront && <div className="input-group-btn"><button id="valueFront" className="btn btn-link">{valueFront}</button></div>}
            </div>
            {hasError(error) && <span className="help-block">{error}</span>}
        </div>
    );
}

TextFieldGroup.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    placeHolder: PropTypes.string,
    value: PropTypes.string.isRequired,
    valueFront: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    autofocus: PropTypes.bool,
}

TextFieldGroup.defaultProps = {
    type: 'text'
}
export default TextFieldGroup;