import React, {PropTypes} from 'react';
import {Button} from 'react-bootstrap';
const UserNoEditRow = ({id, navn, epost, selskapsRolle, onDelete}) => {
    return (
        <tr>
            <td>{navn}</td>
            <td>{selskapsRolle.navn}</td>
            <td>{epost}</td>
            <td><Button bsStyle="link" className="danger" onClick={() => onDelete({id, navn, epost, selskapsRolle})}>Fjern bruker</Button></td>
        </tr>
    );
};

UserNoEditRow.propTypes = {
    id: PropTypes.string.isRequired,
    navn: PropTypes.string.isRequired,
    epost: PropTypes.string.isRequired,
    selskapsRolle: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default UserNoEditRow;
