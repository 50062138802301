import React, { Component, PropTypes } from 'react';
import { Table, Panel, Row, Glyphicon, FormGroup, Button } from 'react-bootstrap';
import _ from "lodash"
import DropDownList from "../common/DropDownList"
import Checkbox from "../common/Checkbox"
import "../../css/avtaler.css"
import { entrepreneurIsRequired } from "../../constants/ValidationErrors"
import * as routes from "../../constants/clientRoutes"
import AnchorLink from '../common/AnchorLink';

class AvtalerList extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            toggle: false,
            selectedRow: "",
            avtale: {},
            selectedEditSelskapId: '0',
            selectedEntrepreneurFilter: '0',
            checkedbox: "",
        }
        this.onPilClick = this.onPilClick.bind(this)
        this.submit = this.submit.bind(this)
        this.cancel = this.cancel.bind(this)
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this)
        this.onChangeEditEntrepreneur = this.onChangeEditEntrepreneur.bind(this)
        this.onChangeFilterEntrepreneur = this.onChangeFilterEntrepreneur.bind(this)
    }
    componentWillMount()
    {
        this.props.loadAvtaler()
        //this.props.loadContracts()
        this.props.loadEntrepreneurs()
    }
    isValid()
    {
        const { selectedEditSelskapId } = this.state
        const error = {}
        const validated = true
        if (selectedEditSelskapId === "0")
        {
            error.selectedEditSelskapId = entrepreneurIsRequired
        }
        return validated
    }
    cancel(e)
    {
        this.setState({ toggle: false, selectedRow: "", selectedEditSelskapId: "0", avtaleId: "0" })
    }

    onChangeCheckbox(e)
    {
        const target = e.target
        const checked = target.checked
        if (checked)
            this.setState({ checkedbox: "checked", selectedEntrepreneurFilter: '0' })
        else
            this.setState({ checkedbox: "" })

    }
    onChangeEditEntrepreneur(e)
    {
        const target = e.target
        const value = target.value
        this.setState({ selectedEditSelskapId: value })
    }
    onChangeFilterEntrepreneur(e)
    {
        const target = e.target
        const value = target.value
        this.setState({ selectedEntrepreneurFilter: value })
        // if (value !== "0")
        // {
        //     this.props.loadEntrepreneurContracts(value)
        // }
    }
    onPilClick(e, row, avtale)
    {
        this.setState({ toggle: !this.state.toggle, selectedRow: row, avtale: avtale, selectedEditSelskapId: avtale.selskapId })
    }
    submit(e)
    {
        e.preventDefault()
        const avtale = this.state.avtale
        const selskapId = this.state.selectedEditSelskapId
        let payload = Object.assign({}, {
            avtaleId: avtale.avtaleId, selskapId: selskapId, navn: avtale.navn, avtaleKobling: avtale.avtaleKobling,
            beskrivelse: avtale.beskrivelse, gyldigKontraktId: avtale.gyldigKontraktId
        })
        if (selskapId === "0") delete payload.selskapId
        this.props.UpdateAvtale(payload)
        this.setState({ toggle: false, selectedEditSelskapId: '0' })
    }

    renderFilters()
    {
        const entrepreneurs = this.renderEntrepreneursDropDownList()
        return (
            <Table id="filterRow" className="form-inline">
                <tbody>
                    <tr>
                        <td className="avtalerDropdownlist" style={{ borderRight: '1px solid lightgray' }}>
                            <DropDownList title="Velg en entreprenør" Items={entrepreneurs} id="avtaleFilter" label="Filtrer:" selected={this.state.selectedEntrepreneurFilter}
                                onChange={this.onChangeFilterEntrepreneur} />
                        </td>
                        <td className="avtaleFilterCheckbox">
                            <Checkbox checked={this.state.checkedbox} title="Avtaler uten selskap" onChange={this.onChangeCheckbox} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        )
    }
    renderEditAvtale(beskrivelse)
    {
        const entrepreneurs = this.renderEntrepreneursDropDownList()
        const { selectedEditSelskapId } = this.state
        return (<div className="col-xs-12">
            <br />
            <span>{beskrivelse}</span>
            <br /><br />
            <DropDownList label="Entreprenør" title="Velg en entreprenør" id="Dropdownlist" Items={entrepreneurs} selected={selectedEditSelskapId} onChange={this.onChangeEditEntrepreneur} />
            <FormGroup>
                <Button bsStyle="link" value="Avbryt" onClick={this.cancel} >Avbryt</Button>
                <Button bsStyle="primary" value="Lagre" onClick={this.submit}> Lagre</Button>
            </FormGroup>
        </div>
        )
    }
    filterAvtaler()
    {
        const filter = this.state.selectedEntrepreneurFilter
        const avtaleWithoutSelskap = this.state.checkedbox
        if (avtaleWithoutSelskap !== "" && filter !== "0")
        {
            return _.filter(this.props.avtaler, { selskapId: null })
        }
        else if (filter !== "0")
        {
            return _.filter(this.props.avtaler, { selskapId: filter })

        }
        else if (avtaleWithoutSelskap !== "")
        {
            return _.filter(this.props.avtaler, i => { return i.selskapId === null })
        }
        return _.map(this.props.avtaler)

    }
    createLink(link, pagetitle, pagetTitleLink, childname, linkLable)
    {
        return (<AnchorLink className="btn btn-link" to={link} pageTitle={pagetitle} pageLink={pagetTitleLink} children={childname} linkLabel={linkLable} />)

    }
    renderAvtaler()
    {
        const { toggle, selectedRow } = this.state
        const avtaler = this.filterAvtaler()
        return _.map(avtaler, (i, index) =>
        {
            // const contractName = this.mapAvtalToKontraktName(i.gyldigKontraktId)
            // const selskapName = this.mapAvtalToSelskapName(i.selskapId)
            const contractName = i.gyldigKontraktNavn
            const selskapName = i.selskapNavn
            const selskapLink = routes.selskaper + "/" + i.selskapId
            const contractLink = routes.selskaper + "/" + i.selskapId + routes.kontrakter + "/" + i.gyldigKontraktId
            const linktoEntrepreneur = selskapName !== "-" ? this.createLink(selskapLink, selskapName, routes.selskaper, selskapName, selskapName) : selskapName
            const linktoContract = contractName !== "-" ? this.createLink(contractLink, selskapName, selskapLink, contractName, contractName) : contractName
            let row = index.toString()
            return (<tr key={row}><td>
                <div className="col-xs-4"><span onClick={(e) => this.onPilClick(e, row, i)}><Glyphicon glyph={toggle && selectedRow === row ? "chevron-down" : "chevron-right"} />{i.navn}</span></div>
                <div className="col-xs-4">{linktoEntrepreneur}</div>
                <div className="col-xs-4">{linktoContract}</div>
                {toggle && row === selectedRow && this.renderEditAvtale(i.beskrivelse)}
            </td>
            </tr>
            )
        })
    }
    renderEntrepreneursDropDownList()
    {
        return _.map(this.props.entrepreneurs, i =>
        {
            return Object.assign({}, { id: i.id, navn: i.name })
        })
    }
    render()
    {
        return (
            <Panel className="avtalerPanel col-xs-12">
                <Row>
                    {this.renderFilters()}
                    <div className="clearfix"></div>
                    <Table className="avtalerList table-striped">
                        <thead>
                            <tr>
                                <td>
                                    <div className="col-xs-4">Avtaler</div>
                                    <div className="col-xs-4">Entreprenør</div>
                                    <div className="col-xs-4">Kontrakt</div>
                                </td>
                            </tr>
                        </thead>
                        <tbody>

                            {this.renderAvtaler()}
                        </tbody>
                    </Table>
                </Row>
            </Panel>
        );
    }
}

AvtalerList.propTypes = {
    avtaler: PropTypes.array.isRequired,
    //contracts: PropTypes.array.isRequired,
    entrepreneurs: PropTypes.array.isRequired,
    loadAvtaler: PropTypes.func.isRequired,
    //loadContracts: PropTypes.func.isRequired,
    //loadContractByAvtaleId: PropTypes.func.isRequired,
    //loadEntrepreneurs: PropTypes.func.isRequired,
    //loadEntrepreneurContracts: PropTypes.func.isRequired,
    UpdateAvtale: PropTypes.func.isRequired
};


export default AvtalerList;