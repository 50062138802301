import axios from 'axios';
import { sortBy } from 'lodash';
import * as types from './ActionTypes';
import * as api from '../constants/api';
import { successData } from '../utils/utils';
import { addFlashMessage } from './FlashMessagesAction';
import * as responseMessages from '../constants/ResponseMessages';

// function addResoucesAction(resource)
// {
//     return { type: types.ADD_RESSURS_SUCCESS, resource }
// }
// function updateResourceListAction(resource)
// {
//     return { type: types.UPDATE_RESSURS_LIST_SUCCESS, resource }
// }
// function deleteResourceListAction(ressursforbrukId)
// {
//     return { type: types.DELETE_RESSURS_LIST_SUCCESS, ressursforbrukId }
// }
function loadProcessCodeSchemaPricesForKontrakt(prices) {
    return { type: types.LOAD_PRICES_FOR_CONTRACT, prices };
}

function loadResourceAction(resources) {
    return { type: types.LOAD_RESSURS_LIST_SUCCESS, resources };
}
function loadProcessCodeUnitsAction(processcodeunit) {
    return { type: types.LOAD_PROCESSCODE_UNIT, processcodeunit };
}

function loadResourceSkiftAction(skift) {
    return { type: types.LOAD_RESSURS_SKIFTLIST_SUCCESS, skift };
}

export function addResource(meldingId, resource) {
    let url = api.addMeldingRessursbrukByIdUrl(meldingId);
    return dispatch => {
        return axios
            .post(url, resource)
            .then(() => {
                dispatch(
                    addFlashMessage({
                        type: 'success',
                        text: responseMessages.ressursBrukCreated,
                    })
                );
                dispatch(getResources(meldingId));
            })
            .catch(err => {
                dispatch(
                    addFlashMessage({
                        type: 'error',
                        text:
                            err.response &&
                            err.response === 400 &&
                            err.response.data &&
                            err.response.data.errorMessage
                                ? err.response.data.errorMessage
                                : responseMessages.ressursBrukNotCreated,
                    })
                );
                return Promise.reject();
            });
    };
}
export function addGlobalResources(kontraktId, ressource, filter) {
    const url = api.GlobalRessursbrukUrl(kontraktId);
    return dispatch => {
        return axios
            .post(url, ressource)
            .then(() => {
                dispatch(
                    addFlashMessage({
                        type: 'success',
                        text: responseMessages.ressursBrukCreated,
                    })
                );
                dispatch(getGlobalResources(kontraktId, filter));
            })
            .catch(err => {
                dispatch(
                    addFlashMessage({
                        type: 'error',
                        text:
                            err.response &&
                            err.response.status === 400 &&
                            err.response.data &&
                            err.response.data.errorMessage
                                ? err.response.data.errorMessage
                                : responseMessages.ressursBrukNotCreated,
                    })
                );
                return Promise.reject();
            });
    };
}

export function getProcesscodeUnits() {
    const url = api.prosesskodeEnheterUrl;
    return dispatch => {
        return axios.get(url).then(res => {
            let data = sortBy(successData(res), 'navn');
            dispatch(loadProcessCodeUnitsAction(data));
        });
    };
}
export function getResources(meldingId) {
    const url = api.getRessursbrukListUrl(meldingId);
    return dispatch => {
        return axios.get(url).then(res => {
            let data = successData(res);
            dispatch(loadResourceAction(data));
        });
    };
}

export function getGlobalResources(kontraktId, filter) {
    const url = filter
        ? api.GlobalFilteredRessursbrukUrl(kontraktId, filter)
        : api.GlobalRessursbrukUrl(kontraktId);
    return dispatch => {
        return axios.get(url).then(res => {
            let data = successData(res);
            dispatch(loadResourceAction(data));
        });
    };
}
export function updateResourceList(meldingId, ressursbrukId, resource) {
    let url = api.updateMeldingRessursbrukByIdUrl(meldingId, ressursbrukId);
    return dispatch => {
        return axios
            .put(url, resource)
            .then(() => {
                dispatch(getResources(meldingId));
                dispatch(
                    addFlashMessage({
                        type: 'success',
                        text: responseMessages.ressursBrukUpdated,
                    })
                );
            })
            .catch(() => {
                dispatch(
                    addFlashMessage({
                        type: 'error',
                        text: responseMessages.ressursBrukNotUpdated,
                    })
                );
            });
    };
}
export function updateGlobalResourceList(kontraktId, ressursbrukId, ressurs, filter) {
    let url = api.UpdateGlobalRessursbrukUrl(kontraktId, ressursbrukId);
    return dispatch => {
        return axios
            .put(url, ressurs)
            .then(() => {
                dispatch(getGlobalResources(kontraktId, filter));
                dispatch(
                    addFlashMessage({
                        type: 'success',
                        text: responseMessages.ressursBrukUpdated,
                    })
                );
            })
            .catch(() => {
                dispatch(
                    addFlashMessage({
                        type: 'error',
                        text: responseMessages.ressursBrukNotUpdated,
                    })
                );
            });
    };
}
export function deleteResourceList(resourceId, meldingId) {
    const url = api.deleteMeldingResourcebrukUrl(resourceId);
    return dispatch => {
        return axios
            .delete(url)
            .then(() => {
                dispatch(getResources(meldingId));
                dispatch(
                    addFlashMessage({
                        type: 'success',
                        text: responseMessages.ressursBrukDeleted,
                    })
                );
            })
            .catch(() => {
                dispatch(
                    addFlashMessage({
                        type: 'error',
                        text: responseMessages.ressursBrukNotDeleted,
                    })
                );
            });
    };
}
export function deleteGlobalResourceList(resourceId, kontraktId, filter) {
    const url = api.deleteMeldingResourcebrukUrl(resourceId);
    return dispatch => {
        return axios
            .delete(url)
            .then(() => {
                dispatch(getGlobalResources(kontraktId, filter));
                dispatch(
                    addFlashMessage({
                        type: 'success',
                        text: responseMessages.ressursBrukDeleted,
                    })
                );
            })
            .catch(() => {
                dispatch(
                    addFlashMessage({
                        type: 'error',
                        text: responseMessages.ressursBrukNotDeleted,
                    })
                );
            });
    };
}

export function loadSkiftList() {
    let url = api.skiftUrl;
    return dispatch => {
        return axios.get(url).then(response => {
            const result = successData(response);
            dispatch(loadResourceSkiftAction(result));
        });
    };
}

export function getProcessCodeSchemaPrices(kontraktId) {
    const url = api.processcodeSkjemaPrisUrl(kontraktId);
    return dispatch => {
      dispatch(loadProcessCodeSchemaPricesForKontrakt([]));
        return axios.get(url).then(res => {
            const data = successData(res);
            dispatch(loadProcessCodeSchemaPricesForKontrakt(data));
        });
    };
}

export function getKontraktForAvtale(avtaleId) {
    const url = api.getkontraktByAvtaleIdUrl(avtaleId);
    return dispatch => {
        return axios.get(url).then(res => {
            const data = successData(res);
            return data.kontraktId;
        });
    };
}
