import * as types from './ActionTypes';
import axios from 'axios';
import { bulkoppdateringerUrl } from '../constants/api';
import { loadBulkUpdates } from './BulkUpdateAction';

export function loadPriceChangesSuccess(pricechanges) {
  return { type: types.LOAD_PRICECHANGES_SUCCESS, pricechanges };
}

export function createPriceChangeSuccess(pricechange) {
  return { type: types.CREATE_PRICECHANGE_SUCCESS, pricechange };
}

export function updatePriceChangeSuccess(pricechange) {
  return { type: types.UPDATE_PRICECHANGE_SUCCESS, pricechange };
}

export function deletePriceChangeSuccess(pricechange) {
  return { type: types.DELETE_PRICECHANGE_SUCCESS, pricechange };
}

export function loadPriceChanges(BulkId) {
  //console.log(contractId);
  var url = bulkoppdateringerUrl + '/' + BulkId + '/prisendringer';
  return function(dispatch) {
    var priserendringer = [];

    axios.defaults.withCredentials = false;
    axios
      .get(url)
      .then(function(response) {
        priserendringer = response.data.result;
        dispatch(loadPriceChangesSuccess(priserendringer));
      })
      .catch(function(error) {
        console.log(error);
        throw error;
      });
  };
}

export function createPriceChange(
  pris,
  prosesskodeId,
  bulkOppdateringId,
  kontraktId
) {
  return dispatch => {
    var url_prisendringer =
      bulkoppdateringerUrl + '/' + bulkOppdateringId + '/prisendringer';
    var postobjekt = { prosesskodeId: prosesskodeId, pris: pris };

    return axios
      .post(url_prisendringer, postobjekt)
      .then(response => {
        if (response.data) {
          dispatch(loadBulkUpdates(kontraktId));
        } else {
          console.log(response);
        }
      })
      .catch(function(error) {
        throw error;
      });
  };
}

export function updatePriceChange(
  pris,
  prisChangeObj,
  bulkOppdateringId,
  kontraktId
) {
  return function(dispatch) {
    var url_prisendringer =
      bulkoppdateringerUrl +
      '/' +
      bulkOppdateringId +
      '/prisendringer/' +
      prisChangeObj.id;

    var p = { pris: pris };

    //Use the web service
    //axios.defaults.withCredentials = false;
    axios
      .put(url_prisendringer, p)
      .then(() => {
        dispatch(loadBulkUpdates(kontraktId));
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  };
}

export function deaktiverPriceChange(
  prisId,
  bulkOppdateringId,
  this_prisendring_obj,
  kontraktId
) {
  return dispatch => {
    var url_prisendringer =
      bulkoppdateringerUrl +
      '/' +
      bulkOppdateringId +
      '/prisendringer/' +
      prisId;
    return axios
      .delete(url_prisendringer)
      .then(response => {
        if (response.data) {
          dispatch(loadBulkUpdates(kontraktId));
          //dispatch(deletePriceChangeSuccess(this_prisendring_obj));
        } else {
          console.log(response);
        }
      })
      .catch(function(error) {
        // console.log(error);
        // if (error.response) {
        //   // The request was made, but the server responded with a status code
        //   // that falls out of the range of 2xx
        //   console.log(error.response);
        //   console.log(error.response.data);
        //   console.log(error.response.data.errorMessage); //TODO response.data.errorMessage skal være === "", dette må vel sjekkes også for response.data
        //                 //err.response.data.errorMessage
        //   console.log(error.response.status);
        //   console.log(error.response.headers);
        // } else {
        //   // Something happened in setting up the request that triggered an Error
        //   console.log('Error', error.message);
        // }
        throw error;
      });
  };
}
