import React, { Component, PropTypes } from 'react'
import Datepicker from 'react-bootstrap-date-picker'
import { daylabels, monthlabels } from "../../constants/settings"
import { toNorwegianDate, fromNorwegianDate } from '../../utils/datepickerHelper'

/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iI]gnored" }]*/
// NOTE: Supports only controlled-mode (not uncrolled using defaultValue and no onChanged)
class NorwegianDatepicker extends Component
{
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string,

        // tilOgMed => Til og med dato 30. Juni should return value 1. August
        tilOgMed: PropTypes.bool
    }
    static defaultProps = {
        tilOgMed: false
    }

    onChange = (value, formattedValue) =>
    {
        const { onChange, tilOgMed } = this.props;
        let date = toNorwegianDate(value);
        // tilOgMed? Add one day
        if (tilOgMed) date = date.add(1, 'days');
        const isoString = date.toISOString();
        onChange(isoString, formattedValue);
    }
 
    render()
    {
        // Override value and onChange
        const { value, onChange: onChangeIgnored, tilOgMed, ...rest } = this.props
        let isoString = value
        if (value !== null && value !== "")
        {
            let date = fromNorwegianDate(value)
            // tilOgMed? Substract one day
            if (tilOgMed) date = date.add(-1, 'days')
            isoString = date.format();
        }

        return (
            <Datepicker
                value={isoString}
                onChange={this.onChange}
                dayLabels={daylabels}
                monthLabels={monthlabels}
                weekStartsOnMonday={true}
                ref={r => this.datepicker = r}
                {...rest} />
        );
    }

}

export default NorwegianDatepicker;