import { LOAD_MELDING_TILBAKEMELDINGER } from "./ActionTypes"
import { getTilbakemeldingerForMeldingUrl, postTilbakemeldingerForMeldingUrl } from "../constants/api"
import axios from "axios"
import { successData } from "../utils/utils"
import { addFlashMessage } from "./FlashMessagesAction";
import { getMeldingHistorisk } from "./HistoricAction"
import { tilbakemeldingIsSent, tilbakemeldingIsNotSent } from "../constants/ResponseMessages"


// function addMeldingTilbakemeldingerSuccess(feedback) {
//     return { type: ADD_MELDING_TILBAKEMELDINGER, feedback }
// }
function loadMeldingTilbakemeldingerSuccess(feedbacks)
{
    return { type: LOAD_MELDING_TILBAKEMELDINGER, feedbacks }
}
export function getMeldingTilbakeMeldinger(meldingId)
{
    const url = getTilbakemeldingerForMeldingUrl(meldingId)
    return dispatch =>
    {
        return axios.get(url).then(res =>
        {
            const result = successData(res)
            dispatch(loadMeldingTilbakemeldingerSuccess(result))
        })
    }
}

export function addMeldingTilbakeMelding(meldingId, feedback)
{
    const url = postTilbakemeldingerForMeldingUrl(meldingId)
    return dispatch =>
    {
        return axios.post(url, feedback)
            .then(() =>
            {
                //const result=successData(res)
                dispatch(getMeldingTilbakeMeldinger(meldingId))
                dispatch(getMeldingHistorisk(meldingId))
                dispatch(addFlashMessage({ type: 'success', text: tilbakemeldingIsSent }));
            })
            .catch(error =>
            {
                if (error)
                    dispatch(addFlashMessage({ type: 'error', text: tilbakemeldingIsNotSent }));
            })
    }
}
