import React, { PropTypes } from 'react';

const FilterBannerDisplay = ({ filtername, text, value, removeFilter }) => {
    return (
        <button className="filterRemoveButtons" id="filterRemoveButtons" onClick={e => removeFilter(filtername, value)}>
            <div>
                <span className="pull-left">{text}</span>
                <span className="glyphicon glyphicon-remove-sign" id="glyphicon-remove-sign" />
            </div>
        </button>
    );
};

FilterBannerDisplay.propTypes = {
    filtername: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.string,
    removeFilter: PropTypes.func,
};

export default FilterBannerDisplay;
