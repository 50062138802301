import React, { PropTypes, Component } from 'react'
import { Modal, Button } from "react-bootstrap"
import { searchNameIsRequired, removeTextError } from "../../constants/ValidationErrors"
import TextFieldGroup from "../common/TextFieldGroup"
import Checkbox from "../common/Checkbox"
import { UserId } from "../../utils/cookieHandler"

class SavedMeldingSearchModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: false,
            searchName: '',
            standard: false,
            errors: {}
        }
        this.toggleNewMeldingModal = this.toggleNewMeldingModal.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this)
        this.submit = this.submit.bind(this)
    }
    static propTypes = {
        urlQuery: PropTypes.string,
        addSavedSearch: PropTypes.func.isRequired,
    }
    cleanUp = () => {
        this.setState({ openModal: false, searchName: '', errors: {}, standard: false })
    }
    toggleNewMeldingModal = () => {
        this.setState({ openModal: !this.state.openModal })
        this.setState({ searchName: '', errors: {}, standard: false })
    }

    onChangeInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        removeTextError(this.state.errors, e.target.name, e.target.value)
    }
    onChangeCheckbox(e) {
        let checked = e.target.checked
        this.setState({ standard: checked })

    }
    IsValid = () => {
        const { searchName } = this.state
        let isValid = true
        if (searchName === "") {
            const errors = { searchName: searchNameIsRequired }
            this.setState({ errors })
            isValid = false
        }
        return isValid
    }
    submit() {
        const { searchName, standard } = this.state
        const { urlQuery } = this.props
        let brukerId = UserId()
        if (!this.IsValid()) return;
        const payload = { brukerId: brukerId, filter: urlQuery, navn: searchName, standard: standard }
        this.props.addSavedSearch(payload).then(() => {
            this.cleanUp()
        })

    }
    render() {
        const { openModal, searchName, errors } = this.state
        const { urlQuery } = this.props
        return (
            <div>
                <Button className="btn btn-default" id="FilterLagreBtn" onClick={this.toggleNewMeldingModal} disabled={urlQuery.length > 7 ? false : true} >
                    <strong>Lagre filter</strong>
                </Button>
                <Modal className="saveMeldingSearchModal" show={openModal} onHide={this.toggleNewMeldingModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>Lagre filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="saveMeldingSearchForm">
                            <TextFieldGroup label="Navn" value={searchName} name="searchName" onChange={this.onChangeInput}
                                id="searchName" error={errors.searchName}
                            />
                            <Checkbox title="Standard" onChange={this.onChangeCheckbox} />
                            <div className="clearfix"></div>
                            <br />
                            <div>
                                <Button bsStyle="link" onClick={this.toggleNewMeldingModal} id="buttonLink">Avbryt</Button>
                                <Button bsStyle="primary" bsSize="large" onClick={this.submit}>Lagre</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )

    }
}

SavedMeldingSearchModal.contextTypes = {
    router: PropTypes.object.isRequired
}
export default SavedMeldingSearchModal