import { PropTypes } from 'react';
import { connect } from 'react-redux';
import { saveUser, removeUser, loadUsers } from '../../actions/userActions';
//import { loadAvtaler } from '../../actions/avtalerAction'
import { addFlashMessage } from "../../actions/FlashMessagesAction";
import { bindActionCreators } from 'redux';

import UserList from '../../components/user/UserList';

const MapStateToProps = (state) =>
{
    return {
        users: state.users.userList.users,
        loading: state.users.userList.loading,
        error: state.users.userList.error,
        //avtaler: state.contractZones ? state.contractZones : []
    }
};

function MapDispatchToProps(dispatch, ownProps)
{
    //noinspection JSUnusedGlobalSymbols
    return {
        loadUsers: () => { dispatch(loadUsers(ownProps.selskapsType, ownProps.selskapsId)) },
        onDelete: (user) => { dispatch(removeUser(ownProps.selskapsType, ownProps.selskapsId, user)) },
        onEdit: (user) => { dispatch(saveUser(ownProps.selskapsType, ownProps.selskapsId, user)) },
        addFlashMessage: bindActionCreators(addFlashMessage, dispatch),
        //loadAvtaler: bindActionCreators(ownProps.loadAvtaler, dispatch),
    };
}

const UserListContainer = connect(MapStateToProps, MapDispatchToProps)(UserList);

UserListContainer.DefaultProps = {
    editable: false,
};
UserListContainer.propTypes = {
    editable: PropTypes.bool,
    selskapsId: PropTypes.string.isRequired,
    selskapsType: PropTypes.number.isRequired,
    avtaler: PropTypes.array,
    feilrettingskoder: PropTypes.array,
    //loadAvtaler: PropTypes.func
};

export default UserListContainer;